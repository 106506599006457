import React from "react";
import { DraggableProvidedDragHandleProps } from "react-beautiful-dnd";
import { Button } from "../Buttons/Button";
import menuIcon from "../../assets/images/menu-05-primary.svg";
import trashIcon from "../../assets/images/trash-03-red.svg";
import editIcon from "../../assets/images/pencil-02.svg";
import paperClip from "../../assets/images/paperclip.svg";

interface Props {
  isDragElement: boolean;
  dragHandleProps?: DraggableProvidedDragHandleProps | null | undefined;
  item: {
    name: string;
    startTime: string;
    endTime: string;
    duration: {
      id: number;
      name: string;
      value?: number | undefined;
    } | null;
    index: number;
    id: string;
    agendaItemId: string;
  };
  setSubBlocks: React.Dispatch<
    React.SetStateAction<
      {
        id: string;
        name: string;
        startTime: string;
        endTime: string;
        duration: {
          id: number;
          name: string;
          value?: number | undefined;
        } | null;
        index: number;
        agendaItemId: string;
      }[]
    >
  >;
  setEditSubBlock?: React.Dispatch<
    React.SetStateAction<{
      id: string;
      name: string;
      startTime: string;
      endTime: string;
      duration: {
        id: number;
        name: string;
        value?: number | undefined;
      } | null;
      index: number;
      agendaItemId: string;
    } | null>
  >;
  withActions?: boolean;
}

export default function SubBlockItem({
  isDragElement,
  dragHandleProps,
  item,
  setSubBlocks,
  setEditSubBlock,
  withActions,
}: Props) {
  return (
    <div className="subBlockItem">
      <header className="subBlockItem-header">
        <div className="flex items-center gap-16">
          {isDragElement ? (
            <div
              {...dragHandleProps}
              className="agendaItem-info-dragBtn hoverLight"
            >
              <Button
                size="md"
                buttonType="small"
                hierarchy="tertiaryGray"
                buttonImage={menuIcon}
                pointerEventsNone
                // disabled
                imgProperties={20}
              />
            </div>
          ) : null}
          <p className="color-primary-600 text-sm font-semibold">
            Item {item.index + 1}
          </p>
        </div>
        {withActions ? (
          <div className="agendaItem-info-actions">
            <Button
              size="md"
              buttonType="small"
              hierarchy="tertiaryGray"
              imgProperties={20}
              buttonImage={trashIcon}
              onClick={() => {
                setSubBlocks((prevState) =>
                  prevState.filter((subBlock) => subBlock.id !== item.id)
                );
              }}
            />
            <Button
              size="md"
              buttonType="small"
              hierarchy="tertiaryGray"
              buttonImage={editIcon}
              imgProperties={20}
              disabled={!setEditSubBlock}
              onClick={() => {
                setEditSubBlock && setEditSubBlock(item);
              }}
            />
          </div>
        ) : null}
      </header>
      <main className="subBlockItem-main">
        <div className="subBlockItem-main-info">
          <h3 className="subBlockItem-main-info-title">{item.name}</h3>
          <p className="subBlockItem-main-info-time">
            {item.startTime} - {item.endTime}
          </p>
        </div>
        <div className="subBlockItem-main-docs">
          <img src={paperClip} alt="" width={20} height={20} />
          <p className="color-gray-950 text-sm font-semibold">0</p>
        </div>
      </main>
    </div>
  );
}
