import React from "react";
import AgendaItem from "../Agenda/AgendaItem";
import { AgendaBlockType } from "../../types/agendaBlockType";

interface Props {
  agenda: {
    id: string;
    icon: string;
    name: string;
    description: string;
    duration: {
      id: number;
      name: string;
      value?: number | undefined;
    };
    startTime: string;
    endTime: string;
    type: AgendaBlockType;
    index: number;
    mealBlockId?: number;
    onClick?: () => Promise<void>;
  }[];
  withoutLabel?: boolean;
  setShowAgendaModal?: React.Dispatch<React.SetStateAction<boolean>>;
  setCurrentItem?: React.Dispatch<
    React.SetStateAction<
      | {
          id: string;
          icon: string;
          name: string;
          description: string;
          duration: {
            id: number;
            name: string;
            value?: number | undefined;
          };
          startTime: string;
          endTime: string;
          type: AgendaBlockType;
          index: number;
        }
      | undefined
    >
  >;
  setAgenda?: React.Dispatch<
    React.SetStateAction<
      {
        id: string;
        icon: string;
        name: string;
        description: string;
        duration: {
          id: number;
          name: string;
          value?: number | undefined;
        };
        startTime: string;
        endTime: string;
        type: AgendaBlockType;
        index: number;
      }[]
    >
  >;
  withActions?: boolean;
  onDelete?: (agendaName: string, agendaId: string) => void;
  setSubBlocks?: React.Dispatch<
    React.SetStateAction<
      {
        id: string;
        name: string;
        startTime: string;
        endTime: string;
        duration: {
          id: number;
          name: string;
          value?: number | undefined;
        } | null;
        index: number;
        agendaItemId: string;
      }[]
    >
  >;
  subBlocks?: {
    id: string;
    name: string;
    startTime: string;
    endTime: string;
    duration: {
      id: number;
      name: string;
      value?: number | undefined;
    } | null;
    index: number;
    agendaItemId: string;
  }[];
}

export default function ReviewAgendaSection({
  agenda,
  withoutLabel,
  setShowAgendaModal,
  setAgenda,
  setCurrentItem,
  withActions,
  onDelete,
  setSubBlocks,
  subBlocks
}: Props) {
  return (
    <>
      {!withoutLabel ? (
        <div className="reviewAgenda">
          <h3 className="reviewAgenda-title">Agenda</h3>
          {agenda
            .sort((it1, it2) => it1.index - it2.index)
            .map((item) => (
              <AgendaItem
                {...item}
                height="max"
                key={item.id}
                setShowAgendaModal={setShowAgendaModal}
                setAgenda={setAgenda}
                setCurrentItem={setCurrentItem}
                isReviewPageWithActions={
                  withActions && item.type !== AgendaBlockType.Meal
                }
                onDelete={onDelete}
                agenda={agenda}
                subBlocks={subBlocks}
                setSubBlocks={setSubBlocks}
              />
            ))}
        </div>
      ) : (
        <div className="flex flex-column gap-20 fullWidth">
          {agenda
            .sort((it1, it2) => it1.index - it2.index)
            .map((item) => (
              <AgendaItem
                {...item}
                height="max"
                key={item.id}
                setShowAgendaModal={setShowAgendaModal}
                setAgenda={setAgenda}
                setCurrentItem={setCurrentItem}
                isReviewPageWithActions={
                  withActions && item.type !== AgendaBlockType.Meal
                }
                onDelete={onDelete}
                agenda={agenda}
                subBlocks={subBlocks}
                setSubBlocks={setSubBlocks}
              />
            ))}
        </div>
      )}
    </>
  );
}
