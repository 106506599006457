import React, { useState } from "react";
import { Button } from "../Buttons/Button";
import RSVPDropdown from "../Dropdown/RSVPDropdown";
import { AttendeeRSVP } from "../../types/attendeeRSVP";
import arrowDown from "../../assets/images/chevron-down-white.svg";
import arrowDownGrey from "../../assets/images/chevron-down.svg";
import arrowDownError from "../../assets/images/chevron-down-error.svg";

interface Props {
  selectedRSVP: AttendeeRSVP | undefined;
  setSelectedRSVP: React.Dispatch<
    React.SetStateAction<AttendeeRSVP | undefined>
  >;
  onRSVPSelect: (item: AttendeeRSVP) => Promise<void>;
}

export default function RSVP({
  selectedRSVP,
  setSelectedRSVP,
  onRSVPSelect,
}: Props) {
  const [isRSVPDropdownOpen, setIsRSVPDropdownOpen] = useState(false);

  return (
    <div style={{ position: "relative" }}>
      <Button
        size="md"
        hierarchy={
          !selectedRSVP
            ? "newDesign-primary"
            : selectedRSVP === AttendeeRSVP.Yes
            ? "newDesign-primary"
            : selectedRSVP === AttendeeRSVP.No
            ? "errorSecondaryGray"
            : "newDesign-gray-rounded"
        }
        buttonType="regular"
        newDesignPaddingNone
        onClick={() => setIsRSVPDropdownOpen((prev) => !prev)}
      >
        <div className="flex gap-8 items-center">
          <p
            className={`
              text-sm 
              font-semibold 
              ${
                selectedRSVP === AttendeeRSVP.Yes
                  ? "color-white"
                  : selectedRSVP === AttendeeRSVP.No
                  ? "color-error-700"
                  : ""
              }`}
          >{`RSVP${selectedRSVP ? ":" : ""} ${
            selectedRSVP ? selectedRSVP : ""
          }`}</p>
          <img
            src={
              selectedRSVP === AttendeeRSVP.Yes
                ? arrowDown
                : selectedRSVP === AttendeeRSVP.No
                ? arrowDownError
                : arrowDownGrey
            }
            alt=""
          />
        </div>
      </Button>
      <div style={{ position: "absolute", width: "250px", top: "110%" }}>
        {isRSVPDropdownOpen ? (
          <RSVPDropdown
            selectedItem={selectedRSVP}
            setSelectedItem={setSelectedRSVP}
            content={[AttendeeRSVP.Maybe, AttendeeRSVP.Yes, AttendeeRSVP.No]}
            onSelect={onRSVPSelect}
            setIsRSVPDropdownOpen={setIsRSVPDropdownOpen}
          />
        ) : null}
      </div>
    </div>
  );
}
