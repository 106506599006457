import React, { useEffect } from "react";
import checkIcon from "../../assets/images/check.svg";
import { AttendeeRSVP } from "../../types/attendeeRSVP";
import successCircle from "../../assets/images/check-circle-grey.svg";
import helpCircle from "../../assets/images/help-circle-grey.svg";
import xCircle from "../../assets/images/x-circle-grey.svg";

interface Props {
  selectedItem: AttendeeRSVP | undefined;
  setSelectedItem: React.Dispatch<
    React.SetStateAction<AttendeeRSVP | undefined>
  >;
  content: AttendeeRSVP[];
  positionBottom?: string | null;
  positionTop?: string | null;
  positionLeft?: string | null;
  positionRight?: string | null;
  onSelect: (item: AttendeeRSVP) => Promise<void>;
  setIsRSVPDropdownOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function RSVPDropdown({
  selectedItem,
  setSelectedItem,
  content,
  positionBottom = null,
  positionTop = null,
  positionLeft = null,
  positionRight = null,
  onSelect,
  setIsRSVPDropdownOpen,
}: Props) {
  const id = "rsvpDropdown";

  useEffect(() => {
    const formElement = document.getElementById(id);

    formElement?.focus();
  }, []);

  return (
    <form
      className={`
        timePicker 
        heightMaxContent
      `}
      style={{
        bottom: `${positionBottom}`,
        top: `${positionTop}`,
        left: `${positionLeft}`,
        right: `${positionRight}`,
      }}
      autoFocus
      tabIndex={0}
      id={id}
      onBlur={() => {
        setIsRSVPDropdownOpen(false);
      }}
    >
      <p className="text-md font-regular color-gray-600 px-8">Are you going?</p>
      <ul className="timePicker-timeContainer">
        {content.map((item) => {
          return (
            <div
              className={`timePicker-timeContainer-time gap-8 items-center ${
                selectedItem === item ? "timePicker-time-selected" : ""
              }`}
              key={item}
            >
              <img
                src={
                  item === AttendeeRSVP.Yes
                    ? successCircle
                    : item === AttendeeRSVP.No
                    ? xCircle
                    : helpCircle
                }
                alt=""
                width={20}
                height={20}
              />
              <div
                className="timePicker-timeContainer-info"
                onClick={async () => {
                  setSelectedItem(item);
                  await onSelect(item);
                }}
              >
                <li className={`timePicker-time `}>
                  {`${item.slice(0, 1).toUpperCase()}${item.slice(1)}`}
                </li>
              </div>
              {selectedItem === item ? (
                <img src={checkIcon} alt="selected icon" />
              ) : null}
            </div>
          );
        })}
      </ul>
    </form>
  );
}
