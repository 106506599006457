import React, { useEffect, useState } from "react";
import logo from "../../assets/images/logo-lg-color.svg";
import lockIcon from "../../assets/images/lock-01.svg";
import checkIcon from "../../assets/images/checkIconGray.svg";
import { Button } from "../../components/Buttons/Button";
import FeaturedIcon from "../../components/FeaturedIcons/FeaturedIcon";
import leftArrow from "../../assets/images/arrow-left.svg";
import { FormInput } from "../../components/Inputs/FormInput";
import bgIcon from "../../assets/images/contentAccountCreatedBg.svg";
import { supabase } from "../../utilities/supabaseClient";
import { checkIsPasswordHasSpecialCharacter } from "../../utilities/passwordHasSpecialCharacter";
import SuccessfullyReseted from "./SuccessfullyReseted";
import checkSuccessIcon from "../../assets/images/Check-icon-success.svg";
import { useNavigate } from "react-router-dom";
import { updatePassword } from "../../httpQueries/http";

export default function SetNewPassword({ delayed }: { delayed?: boolean }) {
  const [newPassword, setNewPassword] = useState("");
  const [passwordReseted, setPasswordReseted] = useState(false);
  let params = new URLSearchParams(window.location.hash);
  const token = localStorage.getItem("token");
  const navigate = useNavigate();

  const onReset = async () => {
    const session = supabase().auth.getSession();
    if (!session) {
      localStorage.clear();
      localStorage.clear();
      navigate("/login");
    }
    if (
      newPassword.trim().length >= 8 &&
      checkIsPasswordHasSpecialCharacter(newPassword)
    ) {
      const response = await updatePassword({
        token,
        body: { password: newPassword },
      });

      if (!response.error) {
        setPasswordReseted(true);
      }
    }
  };

  useEffect(() => {
    if (params.get("error")) {
      localStorage.clear();
      localStorage.clear();
      navigate("/login");
    }
  }, []);

  useEffect(() => {
    const timer = setTimeout(
      async () => {
        const session = await supabase().auth.getSession();

        if (!session) {
          localStorage.clear();
          localStorage.clear();
          navigate("/login");
          return null;
        }
      },
      delayed ? 3000 : 1000
    );

    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      {passwordReseted ? (
        <SuccessfullyReseted />
      ) : (
        <div className="accountCreated">
          <header className="accountCreated-header">
            <img src={logo} alt="" className="accountCreated-header-logo" />
          </header>
          <section className="accountCreated-content">
            <img src={bgIcon} alt="" className="accountCreated-content-image" />
            <div className="accountCreated-content-content">
              <div className="flex flex-column gap-24 items-center">
                <FeaturedIcon icon={lockIcon} size="lg" type="gray" />
                <div className="flex flex-column items-center justifyCenter">
                  <h3 className="accountCreated-content-content-title">
                    Set new password
                  </h3>
                  <p className="accountCreated-content-content-description">
                    Your new password must be different to previously used
                    passwords.
                  </p>
                </div>
              </div>
              <div className="flex flex-column gap-24 fullWidth">
                <div className="flex flex-column gap-20 fullWidth">
                  <FormInput
                    type="Password"
                    label={newPassword.length ? "Password" : ""}
                    placeholder="••••••••"
                    fullWidth
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                  />
                  <div className="flex flex-column gap-12">
                    <div className="flex gap-8">
                      <img
                        src={
                          newPassword.trim().length >= 8
                            ? checkSuccessIcon
                            : checkIcon
                        }
                        alt=""
                      />
                      <p>Must be at least 8 characters</p>
                    </div>
                    <div className="flex gap-8">
                      <img
                        src={
                          checkIsPasswordHasSpecialCharacter(newPassword)
                            ? checkSuccessIcon
                            : checkIcon
                        }
                        alt=""
                      />
                      <p>Must contain one special character</p>
                    </div>
                  </div>
                </div>
                <Button
                  buttonType="regular"
                  hierarchy="primary"
                  size="lg"
                  contentCenter
                  asyncOnClick={onReset}
                >
                  Reset password
                </Button>
              </div>
              <Button
                buttonType="regular"
                hierarchy="linkGray"
                size="md"
                onClick={() => navigate("/login")}
              >
                <div className="flex gap-8 items-center">
                  <img src={leftArrow} alt="" />
                  <p>Back to log in</p>
                </div>
              </Button>
            </div>
          </section>
        </div>
      )}
    </>
  );
}
