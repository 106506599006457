import { useState, useEffect } from "react";
import { createPortal } from "react-dom";
import DatePickerComponent from "./DatePickerLarge";
import calendarIcon from "../../assets/images/calendar.svg";
import { Button } from "../Buttons/Button";

interface Props {
  type: "double" | "small" | "smallWithTodayFeat";
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  firstDate: moment.Moment | null;
  setFirstDate: React.Dispatch<React.SetStateAction<moment.Moment | null>>;
  secondDate?: moment.Moment | null;
  setSecondDate?: React.Dispatch<React.SetStateAction<moment.Moment | null>>;
  selectedDayOnlyFromToday: boolean;
  paddinNone: boolean;
}

export default function DatePickerModal({
  type,
  show,
  setShow,
  firstDate,
  setFirstDate,
  secondDate,
  setSecondDate,
  selectedDayOnlyFromToday,
  paddinNone,
}: Props) {
  useEffect(() => {
    if (document) {
      document.body.style.overflow = show ? "hidden" : "auto";
    }
  }, [show]);

  return (
    <div style={{ width: "100%" }}>
      <Button
        buttonType="regular"
        size="md"
        hierarchy={"newDesign-gray"}
        onClick={() => {
          setShow((prevState) => !prevState);
        }}
        newDesignPaddingNone={paddinNone}
        fullWidth
      >
        <div className="flex flex-column gap-10 items-start">
          {firstDate !== null ? (
            <p className="text-sm font-medium">Date of event</p>
          ) : null}
          <div
            className={`openButton ${
              firstDate ? "" : "openButton-color-gray openButton-width-content"
            } text-sm`}
          >
            <img
              src={calendarIcon}
              alt="calendar icon"
              className="calendarIcon"
            />
            <p className="text-lg font-medium">
              {firstDate === null
                ? "Date of event"
                : `${firstDate?.format("ll")}`}
            </p>
          </div>
        </div>
      </Button>
      {createPortal(
        <div className="modal" style={{ display: show ? "block" : "none" }}>
          {type === "double" ? (
            <DatePickerComponent
              firstDate={firstDate}
              setFirstDate={setFirstDate}
              isModal
              type="double"
              secondDate={secondDate}
              setSecondDate={setSecondDate}
              setIsOpen={setShow}
              selectedDayOnlyFromToday={selectedDayOnlyFromToday}
            />
          ) : null}

          {type === "small" ? (
            <DatePickerComponent
              firstDate={firstDate}
              setFirstDate={setFirstDate}
              isModal
              type="small"
              secondDate={secondDate}
              setSecondDate={setSecondDate}
              setIsOpen={setShow}
              selectedDayOnlyFromToday={selectedDayOnlyFromToday}
            />
          ) : null}

          {type === "smallWithTodayFeat" ? (
            <DatePickerComponent
              firstDate={firstDate}
              setFirstDate={setFirstDate}
              isModal
              type="smallWithTodayFeat"
              secondDate={secondDate}
              setSecondDate={setSecondDate}
              setIsOpen={setShow}
              selectedDayOnlyFromToday={selectedDayOnlyFromToday}
            />
          ) : null}
        </div>,
        document.getElementById("modal") as HTMLElement
      )}
    </div>
  );
}
