import React, { useState } from "react";
import clockIcon from "../../assets/images/clock.svg";
import { DropdownMenu } from "./DropdownMenu";
import { durations, smallDurations } from "../../utilities/agendaDurationsArray";

interface Props {
  isError: boolean,
  selectedDuration: {
    id: number;
    name: string;
    value?: number | undefined;
  } | null,
  setSelectedDuration: React.Dispatch<React.SetStateAction<{
    id: number;
    name: string;
    value?: number | undefined;
  } | null>>,
  isSmallTimeLaps?: boolean,
  disabled?: boolean,
  onSelect?: (prop: any) => void;
  maxAvailableTimeLap?: number,
}

export default function DurationsDropdown({
  isError,
  selectedDuration, 
  setSelectedDuration,
  isSmallTimeLaps,
  disabled,
  onSelect,
  maxAvailableTimeLap
}: Props) {
  const [showSelectDuration, setShowSelectDuration] = useState(false);

  const getAvailableDurations = () => {
    let selectedDurations: {
      id: number;
      name: string;
      value?: number | undefined;
    }[];

    if (isSmallTimeLaps) {
      selectedDurations = smallDurations
    } else {
      selectedDurations = durations;
    }

    if (maxAvailableTimeLap) {
      return selectedDurations.filter(duration => duration.value! <= maxAvailableTimeLap);
    }

    return selectedDurations;
  }

  return (
    <div className="positionRelative">
      <div
        className={`formNew ${
          isError ? "inputNew-error" : ""
        } ${disabled ? 'pointerEventsNone' : ''} text-sm ${selectedDuration?.name ? "" : "py-24 px-12"}`}
        style={{ cursor: "pointer" }}
        onClick={() => setShowSelectDuration((prevState) => !prevState)}
      >
        <div className="flex flex-column gap-10">
          {selectedDuration?.name ? (
            <p className="text-sm font-medium">Duration</p>
          ) : null}
          <div className="flex items-center gap-10">
            <img src={clockIcon} alt="clock icon" className="calendarIcon" />
            <p
              className={
                selectedDuration === null
                  ? "text-lg font-medium color-gray-600"
                  : "text-lg font-medium color-gray-900"
              }
            >
              {selectedDuration === null
                ? "Duration*"
                : `${selectedDuration.name}`}
            </p>
          </div>
        </div>
      </div>
      {showSelectDuration ? (
        <DropdownMenu
          content={getAvailableDurations()}
          selectedItem={selectedDuration}
          setSelectedItem={setSelectedDuration}
          heightSize="sm"
          size="lg"
          positionBottom={'-180px'}
          setShow={() => setShowSelectDuration((prevState) => !prevState)}
          reactToFocus
          onSelect={onSelect}
        />
      ) : null}
    </div>
  );
}
