import React, { useEffect } from "react";
import closeIcon from "../../assets/images/x-close-black.svg";
import { Button } from "../Buttons/Button";

interface Props {
  setIsOpen: React.Dispatch<
    React.SetStateAction<{
      name: string;
      description: string;
      firstButtonName: string;
      secondButtonName: string;
      onFirstButtonClick: () => void;
      onSecondButtonClick: () => void;
    } | null>
  >;
  name: string;
  description: string;
  onFirstButton: () => void;
  onSecondButton: () => void;
  firstButtonName: string;
  secondButtonName: string;
}

export default function ModalNotificationNew({
  setIsOpen,
  name,
  description,
  onFirstButton,
  onSecondButton,
  firstButtonName,
  secondButtonName,
}: Props) {
  useEffect(() => {
    window.scrollTo(0, 0);

    document.getElementById("modal")?.classList.add("modal-main-container");
    document.body.style.overflow = "hidden";

    return () => {
      document.body.style.overflow = "auto";
      document
        .getElementById("modal")
        ?.classList.remove("modal-main-container");
    };
  }, []);

  return (
    <div className="modalNotification">
      <div className="modalNotification-container modalNotification-container-inviteAttendees modalNotificationNew">
        <div className="modalNotification-header modalNotification-header-error gap-24">
          <div className="modalNotification-header-actions">
            <div className="modalNotification-header-info">
              <h3>{name}</h3>
            </div>
            <img
              src={closeIcon}
              alt="close icon"
              className="modalNotification-header-actions-close"
              onClick={() => setIsOpen(null)}
            />
          </div>
          <div className="modalNotification-header-info">
            <p>{description}</p>
          </div>
        </div>
        <div className="modalNotification-actions flex-row flex-nowrap">
          <Button
            buttonType="regular"
            size="lg"
            hierarchy="linkColor"
            fullWidth
            paddingNone
            onClick={onFirstButton}
          >
            {firstButtonName}
          </Button>
          <Button
            buttonType="regular"
            size="lg"
            hierarchy="error"
            contentCenter
            onClick={onSecondButton}
          >
            {secondButtonName}
          </Button>
        </div>
      </div>
    </div>
  );
}
