import { Button } from "../../components/Buttons/Button";
import plusIcon from "../../assets/images/plus.svg";
import userPlusIcon from "../../assets/images/user-plus-01.svg";
import { CustomIcon } from "../../components/CustomIcons/CustomIcon";

export default function NoEventsFound({
  setCreateNewUserIsOpen,
}: {
  setCreateNewUserIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  return (
    <div className="noEventsFound" style={{ overflow: "visible" }}>
      <div
        className="noEventsFound-circles"
        style={{ top: "-2px", left: "-24px" }}
      >
        <div className="eventDetails_circles">
          <div className="eventDetails-circle eventDetails-circle-fourth">
            <div className="eventDetails-circle eventDetails-circle-third">
              <div className="eventDetails-circle eventDetails-circle-second">
                <div className="eventDetails-circle eventDetails-circle-first"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="noEventsFound-content">
        <CustomIcon icon={userPlusIcon} title={""} subtitle={""} />
        <div className="noEventsFound-content-info">
          <h3 className="noEventsFound-content-info-title">
            No contacts created
          </h3>
          <p className="noEventsFound-content-info-description">
            You have no contacts yet, create your first contact so you can start
            inviting them to your events.
          </p>
        </div>
      </div>
      <Button
        buttonType="regular"
        hierarchy="primary"
        size="md"
        onClick={() => setCreateNewUserIsOpen(true)}
      >
        <div className="eventsPage-header-addNewButton">
          <img src={plusIcon} alt="" />
          <p className="eventsPage-header-addNewButton-text">New contact</p>
        </div>
      </Button>
    </div>
  );
}
