import React from "react";
import CheckBox from "../CheckBox/CheckBox";
import dotsIcon from "../../assets/images/dots-vertical.svg";
import { Button } from "../Buttons/Button";
import FeaturedIcon from "../FeaturedIcons/FeaturedIcon";
import searchIcon from "../../assets/images/search-lg-gray.svg";
import plusIcon from "../../assets/images/plus.svg";

interface Props {
  content: {
    id: number;
    name: string;
    supportText?: string;
    accountId?: number;
    createdAt?: string;
    phone?: string;
    updatedAt?: string;
    address?: string | null;
    businessName?: string | null;
    title?: string | null;
  }[];
  positionBottom?: string | null;
  positionTop?: string | null;
  positionLeft?: string | null;
  positionRight?: string | null;
  setShow?: React.Dispatch<React.SetStateAction<boolean>>;
  label: string;
  selectedItems: {
    id: number;
    name: string;
    supportText?: string | undefined;
    accountId?: number;
    createdAt?: string;
    phone?: string;
    updatedAt?: string;
    address?: string | null;
    businessName?: string | null;
    title?: string | null;
  }[];
  setSelectedItems: React.Dispatch<
    React.SetStateAction<
      {
        id: number;
        name: string;
        supportText?: string | undefined;
        accountId?: number;
        createdAt?: string;
        phone?: string;
        updatedAt?: string;
        address?: string | null;
        businessName?: string | null;
        title?: string | null;
      }[]
    >
  >;
  inputValue: string;
  setInputValue: React.Dispatch<React.SetStateAction<string>>;
  heightSize?: "lg";
  paddingNone?: boolean;
  setShowAddNewAttendee: React.Dispatch<
    React.SetStateAction<{
      id: number;
      name: string;
      supportText?: string | undefined;
      value?: number | undefined;
    } | null>
  >;
}

export default function DropdownSelect({
  content,
  positionBottom = null,
  positionTop = null,
  positionLeft = null,
  positionRight = null,
  setShow,
  label,
  selectedItems,
  setSelectedItems,
  inputValue,
  setInputValue,
  heightSize,
  paddingNone,
  setShowAddNewAttendee,
}: Props) {
  return (
    <div
      style={{
        bottom: `${positionBottom}`,
        top: `${positionTop}`,
        left: `${positionLeft}`,
        right: `${positionRight}`,
        padding: paddingNone ? "0" : "0 18px",
      }}
      className={`dropDownSelect ${
        heightSize === "lg"
          ? selectedItems.length
            ? "dropDownSelect-heightLg dropDownSelect-heightLg-hasSelected"
            : "dropDownSelect-heightLg"
          : ""
      }`}
      onBlur={() => {
        setShow && setShow(false);
      }}
      autoFocus={true}
    >
      <p
        className="dropDownSelect-label"
        style={{ padding: paddingNone ? "0 18px" : "0" }}
      >
        {label}
      </p>
      {content.length ? (
        <ul
          className="dropDownSelect-content"
          onBlur={() => {
            setShow && setShow(false);
          }}
          autoFocus={true}
        >
          {content.map((item) => (
            <div
              key={item.id}
              className={`dropDownSelect-content-item ${
                selectedItems.includes(item) ? "addAttendees-selected" : ""
              }`}
              onClick={() => {
                if (selectedItems.map((item) => item.id).includes(item.id)) {
                  setSelectedItems((prevState) =>
                    prevState.filter((it) => it.id !== item.id)
                  );
                } else {
                  setSelectedItems((prevState) => [...prevState, item]);
                }
              }}
            >
              <div className="dropDownSelect-content-item-info">
                <CheckBox
                  isSelected={selectedItems
                    .map((item) => item.id)
                    .includes(item.id)}
                  item={item}
                  onClick={(e) => {
                    e?.preventDefault();
                    e?.stopPropagation();
                    if (
                      selectedItems.map((item) => item.id).includes(item.id)
                    ) {
                      setSelectedItems((prevState) =>
                        prevState.filter((it) => it.id !== item.id)
                      );
                    } else {
                      setSelectedItems((prevState) => [...prevState, item]);
                    }
                  }}
                />
                <div className="dropDownSelect-content-item-info-text">
                  <li className="dropDownSelect-content-item-info-text-title">
                    {item.name}
                  </li>
                  <p className="dropDownSelect-content-item-info-text-description">
                    {item.supportText}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </ul>
      ) : null}
      {!content.length ? (
        <div className="modalInvite-noResult-container">
          <div className="modalInvite-noResult-content">
            <FeaturedIcon icon={searchIcon} size="lg" type="gray" />
            <div className="modalInvite-noResult-content-info">
              <p className="modalInvite-noResult-content-info-title">
                No results found
              </p>
              {inputValue.length ? (
                <p className="modalInvite-noResult-content-info-description">{`Your search “${inputValue}” did not match any users. Please try again.`}</p>
              ) : null}
            </div>
          </div>
          <div className="modalInvite-noResult-actions">
            {inputValue ? (
              <Button
                buttonType="regular"
                size="lg"
                hierarchy="secondaryGray"
                onClick={() => setInputValue("")}
              >
                Clear search
              </Button>
            ) : null}
            <Button
              buttonType="regular"
              size="lg"
              hierarchy="primary"
              onClick={() =>
                setShowAddNewAttendee &&
                setShowAddNewAttendee({ id: 2, name: "Add new" })
              }
            >
              <div className="modalInvite-noResult-actions-new">
                <img src={plusIcon} alt="" />
                <p>New attendee</p>
              </div>
            </Button>
          </div>
        </div>
      ) : null}
    </div>
  );
}
