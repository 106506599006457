import React from "react";

interface Props {
  tabs: { id: number; name: string; onSelect?: () => void }[];
  current: {
    id: number;
    name: string;
  } | null;
  setCurrent: React.Dispatch<
    React.SetStateAction<{ id: number; name: string } | null>
  >;
  newDesign?: boolean;
}

export default function CustomTabs({
  tabs,
  current,
  setCurrent,
  newDesign,
}: Props) {
  return (
    <div className={`customTabs ${newDesign ? "customTabs-newDesign" : ""}`}>
      {tabs.map((tab, i) => (
        <div
          key={tab.id}
          className={`
            customTabs-tab 
            ${current?.id === tab.id ? "customTabs-tab-selected" : ""}
            ${
              i + 1 === tabs.length
                ? newDesign
                  ? "customTabs-tab-last-newDesign"
                  : "customTabs-tab-last"
                : ""
            }
            ${i + 1 !== tabs.length ? "customTabs-tab-notLast" : ""}
            ${
              i === 0
                ? newDesign
                  ? "customTabs-tab-first-newDesign"
                  : "customTabs-tab-first"
                : ""
            }
          `}
          onClick={() => {
            setCurrent(tab);
            tab.onSelect && tab.onSelect();
          }}
        >
          {tab.name}
        </div>
      ))}
    </div>
  );
}
