import React from "react";
import { getTimeInMinutes } from "../../utilities/getTimeInMinutes";

export default function DaySchedule({
  events,
}: {
  events: { name: string; startTime: string; endTime: string | undefined }[];
}) {
  function getEventHeight(startTime: string, endTime: string | undefined) {
    const minHeight = 44;
    const fullDayInMinutes = 1440;
    if (!endTime) {
      return `${minHeight}px`;
    }
    const startTimeIn24HoursFormat =
      startTime.slice(-2) === "AM"
        ? startTime.slice(0, -2)
        : `${+startTime.split(":")[0] + 12}:${startTime
            .split(":")[1]
            .slice(0, -2)}`;
    const endTimeIn24HoursFormat =
      endTime.slice(-2) === "AM"
        ? endTime.slice(0, -2)
        : `${+endTime.split(":")[0] + 12}:${endTime
            .split(":")[1]
            .slice(0, -2)}`;
    let startTimeInMinute =
      +startTimeIn24HoursFormat.split(":")[0] * 60 +
      +startTimeIn24HoursFormat.split(":")[1].slice(-2);
    let endTimeInMinute =
      +endTimeIn24HoursFormat.split(":")[0] * 60 +
      +endTimeIn24HoursFormat.split(":")[1].slice(-2);

    if (
      +startTimeIn24HoursFormat.split(":")[0] >
      +endTimeIn24HoursFormat.split(":")[0]
    ) {
      endTimeInMinute += fullDayInMinutes;
    }

    return `${((endTimeInMinute - startTimeInMinute) / 60) * 50 - 6}px`;
  }

  return (
    <div className="flex flex-column fullWidth positionRelative">
      {events.length ? (
        <div className="daySchedule-day-events">
          {events.map((event) => (
            <div
              className="daySchedule-day-event"
              style={{
                height: getEventHeight(event.startTime, event.endTime),
                marginTop: `${
                  ((getTimeInMinutes(event.startTime) -
                    getTimeInMinutes("8:00 AM")) /
                    60) *
                  50
                }px`,
              }}
              key={event.name}
            >
              <div className="flex flex-column gap-2">
                <div className="daySchedule-day-event-circle"></div>
                <h3 className="daySchedule-day-event-name">{event.name}</h3>
              </div>
              <p className="daySchedule-day-event-description">
                {event.startTime} {event.endTime ? "-" : ""} {event.endTime}
              </p>
            </div>
          ))}
        </div>
      ) : null}
      <div className="daySchedule-day"></div>
      <div className="daySchedule-day"></div>
      <div className="daySchedule-day"></div>
      <div className="daySchedule-day"></div>
      <div className="daySchedule-day"></div>
      <div className="daySchedule-day"></div>
      <div className="daySchedule-day"></div>
      <div className="daySchedule-day"></div>
      <div className="daySchedule-day"></div>
      <div className="daySchedule-day"></div>
      <div className="daySchedule-day"></div>
      <div className="daySchedule-day"></div>
      <div className="daySchedule-day"></div>
      <div className="daySchedule-day"></div>
      <div className="daySchedule-day"></div>
      <div className="daySchedule-day"></div>
      <div className="daySchedule-day"></div>
      <div className="daySchedule-day"></div>
      <div className="daySchedule-day"></div>
      <div className="daySchedule-day"></div>
      <div className="daySchedule-day"></div>
      <div className="daySchedule-day"></div>
      <div className="daySchedule-day"></div>
      <div className="daySchedule-day"></div>
    </div>
  );
}
