import React from "react";
import logo from "../../../assets/images/logo-xl-white.svg";

export default function BillingsDetails() {
  return (
    <div className="myDetails">
      <div className="myDetails-content fullWidth">
        <div className="settingsBilling">
          <div className="flex items-center spaceBetween fullWidht">
            <p className="settingsBilling-title sm font-bold">
              You are in a beta trial
            </p>
            <img src={logo} alt="gatherwise logo" width={142} height={32} />
          </div>
          <p className="settingsBilling-description">
            Thanks for being a part of Gatherwise, we will update you once our
            pricing and plans get released!
          </p>
        </div>
      </div>
    </div>
  );
}
