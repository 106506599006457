import React, { useEffect } from "react";
import closeIcon from "../../assets/images/x-close-gray-lg.svg";
import checkCircle from "../../assets/images/check-circle-success.svg";
import clockPlus from "../../assets/images/clock-plus-warning.svg";

interface Props {
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isError: boolean;
  title: string;
  description: string;
}

export default function Notification({
  setIsOpen,
  isError,
  title,
  description,
}: Props) {
  let timer: string | number | NodeJS.Timeout | undefined;
  useEffect(() => {
    timer = setTimeout(() => setIsOpen(false), 5000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div
      className="modalNotification-container modalNotification-container-success flex items-center"
      style={{ zIndex: 10000 }}
    >
      <div
        className={`modalNotification-header modalNotification-header-success flex items-center`}
      >
        <div
          className={`modalNotification-header-actions modalNotification-header-actions-success ${
            isError
              ? "modalNotification-header-actions-error"
              : "modalNotification-header-actions-success"
          }`}
        >
          <div
            className={`${
              isError
                ? "modalNotification-header-actions-icon-container-error"
                : "modalNotification-header-actions-icon-container-success"
            }`}
          >
            <div
              className={`modalNotification-header-actions-icon ${
                isError
                  ? "modalNotification-header-actions-icon-error"
                  : "modalNotification-header-actions-icon-success"
              }`}
            >
              <img src={isError ? clockPlus : checkCircle} alt="trash icon" />
            </div>
          </div>
        </div>
        <div
          className="modalNotification-header-info modalNotification-header-info-success"
          style={{ marginTop: "0" }}
        >
          <h3>{title}</h3>
          <p>{description}</p>
        </div>
      </div>
      <img
        src={closeIcon}
        alt="close icon"
        className="modalNotification-header-actions-close"
        onClick={() => setIsOpen(false)}
      />
    </div>
  );
}
