import React from "react";

interface Props {
  tabs: {
    id: number;
    name: string;
    onSelect?: () => void;
    withNotification?: number;
  }[];
  current: {
    id: number;
    name: string;
    onSelect?: () => void;
    withNotification?: number;
  } | null;
  setCurrent: React.Dispatch<
    React.SetStateAction<{
      id: number;
      name: string;
      onSelect?: () => void;
      withNotification?: number;
    } | null>
  >;
  type: "Buttons" | "Underline" | "withIllumination";
  width?: string;
  height?: string;
}

export default function HorizontalTabs({
  current,
  setCurrent,
  tabs,
  type,
  width,
  height,
}: Props) {
  return (
    <div
      className={`horizontalTabs ${
        type !== "Buttons" ? "horizontalTabs-underline" : ""
      }`}
      style={{ width }}
    >
      {type === "Underline" ? (
        <div className="horizontalTabs horizontalTabs-underline">
          {tabs.map((tab) => (
            <p
              key={tab.id}
              className={`
                    horizontalTabs-tab 
                    ${
                      type === "Underline" ? "horizontalTabs-tab-underline" : ""
                    }
                    ${
                      current?.id === tab.id && current.name === tab.name
                        ? type === "Underline"
                          ? "horizontalTabs-tab-underline-selected"
                          : "horizontalTabs-tab-selected"
                        : ""
                    }
                  `}
              onClick={() => {
                tab.onSelect && tab.onSelect();
                setCurrent(tab);
              }}
              style={{ height }}
            >
              {tab.name}

              {tab.withNotification ? (
                <p className="horizontalTabs-tab-notification">
                  {tab.withNotification}
                </p>
              ) : null}
            </p>
          ))}
        </div>
      ) : type === "Buttons" ? (
        <>
          {tabs.map((tab) => (
            <p
              key={tab.id}
              className={`
                        horizontalTabs-tab
                        ${
                          current?.id === tab.id && current.name === tab.name
                            ? "horizontalTabs-tab-selected"
                            : ""
                        }
                      `}
              onClick={() => setCurrent(tab)}
            >
              {tab.name}
            </p>
          ))}
        </>
      ) : (
        <div className="horizontalTabs horizontalTabs-withIllumination horizontalTabs-underline">
          {tabs.map((tab, i) => (
            <p
              key={tab.id}
              className={`
                        horizontalTabs-tab
                        horizontalTabs-tab-underline
                        horizontalTabs-tab-underline-withIllumination
                        ${
                          current?.id === tab.id && current.name === tab.name
                            ? "horizontalTabs-tab-underline-selected-withIllumination horizontalTabs-tab-underline-selected"
                            : "horizontalTabs-tab-underline-withIllumination"
                        }
                        ${
                          current?.id === tab.id &&
                          current.name === tab.name &&
                          i === 0
                            ? "horizontalTabs-tab-underline-selected-withIllumination-first"
                            : ""
                        }
                        ${
                          current?.id === tab.id &&
                          current.name === tab.name &&
                          i === tabs.length - 1
                            ? "horizontalTabs-tab-underline-selected-withIllumination-last"
                            : ""
                        }
                      `}
              onClick={() => setCurrent(tab)}
            >
              {tab.name}
            </p>
          ))}
        </div>
      )}
    </div>
  );
}
