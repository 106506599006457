import { createClient } from '@supabase/supabase-js';

// export async function supabase() {
//   let sbUrl = '';
//   let sbKey = '';

//   await fetchSbConfig().then(data => {
//     if ( data ) {
//       sbUrl = data.sbUrl;
//       sbKey = data.sbPublicKey;
//       localStorage.setItem('sbUrl', data.sbUrl);
//       localStorage.setItem('sbKey', data.sbPublicKey);
//     }
//   });
  
//   const client = createClient(sbUrl, sbKey);
//   return client;
// }
const supabaseClient = createClient(process.env.REACT_APP_SB_URL!, process.env.REACT_APP_SB_KEY!);

export const supabase = () => supabaseClient;