export function getTimeFromMinutes(timeStamp: string) {
  const dateInUserTimezone = new Date(timeStamp).toTimeString() || '';
  const timeSplited = dateInUserTimezone.split(' ')[0].split(':').length ? dateInUserTimezone.split(' ')[0].split(':') : '';
  const timeInCurrentDeviceTimezone = `${timeSplited[0]}:${timeSplited[1].split(':')[0]}`;
  
  const hours = +timeInCurrentDeviceTimezone.split(':')[0] > 12 ? +timeInCurrentDeviceTimezone.split(':')[0] - 12 : +timeInCurrentDeviceTimezone.split(':')[0];
  const timeMinutes = +timeInCurrentDeviceTimezone.split(':')[1];
  const partOfDay = +timeInCurrentDeviceTimezone.split(':')[0] >= 12 ? 'PM' : 'AM'
   
  return `${hours === 0 ? 12 : hours}:${timeMinutes === 0 ? '00' : timeMinutes} ${partOfDay}`;
}

export function getTimeFromMinutesForEdit(timeStamp: string) {
  const timeSplited = timeStamp.split(' ')[0].split(':').length ? timeStamp.split(' ')[0].split(':') : '';
  const timeInCurrentDeviceTimezone = `${timeSplited[0]}:${timeSplited[1].split(':')[0]}`;
  
  const hours = +timeInCurrentDeviceTimezone.split(':')[0] > 12 ? +timeInCurrentDeviceTimezone.split(':')[0] - 12 : +timeInCurrentDeviceTimezone.split(':')[0];
  const timeMinutes = +timeInCurrentDeviceTimezone.split(':')[1];
  const partOfDay = +timeInCurrentDeviceTimezone.split(':')[0] >= 12 ? 'PM' : 'AM'
   
  return `${hours === 0 ? 12 : hours}:${timeMinutes === 0 ? '00' : timeMinutes} ${partOfDay}`;
}