import React from "react";
import { Button } from "../../components/Buttons/Button";
import plusIcon from "../../assets/images/plus.svg";
import { useNavigate } from "react-router-dom";
import folderIcon from "../../assets/images/folderIcon.svg";

export default function NoEventsFound() {
  const navigate = useNavigate();

  return (
    <div className="noEventsFound" style={{ minHeight: "430px" }}>
      <div className="noEventsFound-circles">
        <div className="eventDetails_circles">
          <div className="eventDetails-circle eventDetails-circle-fourth">
            <div className="eventDetails-circle eventDetails-circle-third">
              <div className="eventDetails-circle eventDetails-circle-second">
                <div className="eventDetails-circle eventDetails-circle-first"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="noEventsFound-content">
        <div className="noEventsFound-icon-wrap">
          <div className="noEventsFound-icon">
            <img src={folderIcon} alt="" className="noEventsFound-icon-img" />
          </div>
        </div>
        <div className="noEventsFound-content-info">
          <h3 className="noEventsFound-content-info-title">No events found</h3>
          <p className="noEventsFound-content-info-description">
            No events found in the selected dates. Please try a different date
            range
          </p>
        </div>
      </div>
      <Button
        buttonType="regular"
        hierarchy="primary"
        size="md"
        onClick={() => navigate("/")}
      >
        <div className="eventsPage-header-addNewButton">
          <img src={plusIcon} alt="" />
          <p className="eventsPage-header-addNewButton-text">Create event</p>
        </div>
      </Button>
    </div>
  );
}
