import React from "react";

export default function CustomBadge({
  text,
  status,
  img,
}: {
  text: string;
  status: "default" | "success" | "error" | "warning";
  img: string;
}) {
  return (
    <div
      className={`
        flex
        items-center
        gap-4
        userInviteTable-body-item-status-info
        ${
          status === "error"
            ? "userInviteTable-body-item-status-info-error"
            : ""
        }
        ${
          status === "success"
            ? "userInviteTable-body-item-status-info-success"
            : ""
        }
        ${
          status === "warning"
            ? "userInviteTable-body-item-status-info-warning"
            : ""
        }
      `}
    >
      <img src={img} alt="" />
      <p>{text}</p>
    </div>
  );
}
