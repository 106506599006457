import { Button } from "../Buttons/Button";
import ProgressLine from "./ProgressLine";

interface Props {
  progress: number;
  onClose: () => void;
  hierarchy: "primary" | "default" | "dark";
}

export default function ProgressBar({ progress, onClose, hierarchy }: Props) {
  return (
    <div
      className={`
        progressBar
        ${hierarchy === "primary" ? "progressBar-primary" : ""}
        ${hierarchy === "default" ? "progressBar-default" : ""}
        ${hierarchy === "dark" ? "progressBar-dark" : ""}
      `}
    >
      <div className="progressBar-info">
        <h3
          className={`
            progressBar-info-title
            ${
              hierarchy === "primary" || hierarchy === "dark"
                ? "progressBar-info-title-primary"
                : ""
            }
            ${hierarchy === "default" ? "progressBar-info-title-default" : ""}
          `}
        >
          Used space
        </h3>
        <p
          className={`
            progressBar-info-helpText
            ${
              hierarchy === "primary" ? "progressBar-info-helpText-primary" : ""
            }
            ${
              hierarchy === "default" ? "progressBar-info-helpText-default" : ""
            }
            ${hierarchy === "dark" ? "progressBar-info-helpText-dark" : ""}
          `}
        >
          Your team has used 80% of your available space. Need more?
        </p>
        <div className="progressBar-close">
          <Button
            size="sm"
            buttonType="close"
            hierarchy={
              hierarchy === "default" ? "closeGray" : "closePrimaryBlackTheme"
            }
            onClick={onClose}
          />
        </div>
      </div>
      <ProgressLine hierarchy={hierarchy} progress={progress} />
      <div className="progressBar-buttons-container">
        <button
          className={`
            progressBar-button
            ${
              hierarchy === "primary"
                ? "progressBar-button-dismiss-primary"
                : ""
            }
            ${
              hierarchy === "default"
                ? "progressBar-button-dismiss-default"
                : ""
            }
            ${hierarchy === "dark" ? "progressBar-button-dismiss-dark" : ""}
          `}
        >
          Dismiss
        </button>
        <button
          className={`
            progressBar-button
            ${
              hierarchy === "primary" || hierarchy === "dark"
                ? "progressBar-button-upgrade-primary"
                : ""
            }
            ${
              hierarchy === "default"
                ? "progressBar-button-upgrade-default"
                : ""
            }
          `}
        >
          Upgrade plan
        </button>
      </div>
    </div>
  );
}
