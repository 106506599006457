import { PickersDayProps } from "@mui/x-date-pickers";
import { formatDate } from "../../utilities/formatDate";
import moment from "moment";
import { logoColor } from "../../types/logoColor";

export const CustomDay = (
  props: PickersDayProps<moment.Moment> & { daysWithEvents?: string[] } & {
    pickedDate: moment.Moment | null;
  } & {
    events?: {
      slug: string;
      name: string;
      startTime: string;
      color: logoColor | null;
    }[];
  } & {
    setPickedDate: React.Dispatch<React.SetStateAction<moment.Moment | null>>;
  } & { selectedMonth: number } & { selectedDayOnlyFromToday: boolean } & {
    selectSecondDateFrom?: moment.Moment;
  }
) => {
  const {
    day,
    daysWithEvents,
    pickedDate,
    setPickedDate,
    selectedMonth,
    selectedDayOnlyFromToday,
    selectSecondDateFrom,
    events,
  } = props;
  let mainStyles = "day text-sm font-regular";
  let eventStyles = "day-withEvent-circle";
  const isDayWithEvent =
    daysWithEvents?.includes(day.format("YYYY-MM-DD")) || events?.length;
  const dayIsToday = day.format("YYYY-MM-DD") === formatDate(new Date());
  if (dayIsToday) {
    mainStyles += " day-today";
  }

  if (
    day.month() !== selectedMonth ||
    (selectedDayOnlyFromToday && day < moment() && !dayIsToday) ||
    (selectSecondDateFrom && day < selectSecondDateFrom)
  ) {
    mainStyles += " day-disabled";
    eventStyles += " day-withEvent-circle-disabled";
  } else {
    mainStyles += " day-inCurrentMonth";
  }

  if (day.format("ll") === pickedDate?.format("ll")) {
    mainStyles += " day-picked";
    eventStyles += " day-withEvent-circle-picked";
  }

  if (isDayWithEvent) {
    mainStyles += " day-withEvent";
  }

  return (
    <button className={mainStyles} onClick={() => setPickedDate(day)}>
      {day.date()}
      {isDayWithEvent ? <div className={eventStyles}></div> : null}
    </button>
  );
};
