import React from "react";
import FeaturedIcon from "../../components/FeaturedIcons/FeaturedIcon";
import { Button } from "../../components/Buttons/Button";
import leftArrow from "../../assets/images/arrow-left.svg";
import logo from "../../assets/images/logo-lg-color.svg";
import checkIcon from "../../assets/images/check-circle-black.svg";
import bgIcon from "../../assets/images/contentAccountCreatedBg.svg";
import { useNavigate } from "react-router-dom";

export default function SuccessfullyReseted() {
  const navigate = useNavigate();

  return (
    <div className="accountCreated">
      <header className="accountCreated-header">
        <img src={logo} alt="" className="accountCreated-header-logo" />
      </header>
      <section className="accountCreated-content">
        <img src={bgIcon} alt="" className="accountCreated-content-image" />
        <div className="accountCreated-content-content">
          <div className="flex flex-column gap-24 items-center">
            <FeaturedIcon icon={checkIcon} size="lg" type="gray" />
            <div className="flex flex-column items-center">
              <h3 className="accountCreated-content-content-title">
                Password reset
              </h3>
              <div className="flex gap-4 items-center">
                <p className="accountCreated-content-content-description">
                  Your password has been successfully reset. Click below to log
                  in magically.
                </p>
              </div>
            </div>
          </div>
          <Button
            buttonType="regular"
            hierarchy="primary"
            size="lg"
            fullWidth
            contentCenter
            onClick={() => navigate("/")}
          >
            Continue
          </Button>
          <Button
            buttonType="regular"
            hierarchy="linkGray"
            size="md"
            onClick={() => navigate("/login")}
          >
            <div className="flex gap-8 items-center">
              <img src={leftArrow} alt="" />
              <p>Back to Log in</p>
            </div>
          </Button>
        </div>
      </section>
    </div>
  );
}
