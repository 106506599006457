export const timeZones = [
    { "id": 1, "name": "Abidjan", "timezone": "Africa/Abidjan" },
    { "id": 2, "name": "Accra", "timezone": "Africa/Accra" },
    { "id": 3, "name": "Addis Ababa", "timezone": "Africa/Addis_Ababa" },
    { "id": 4, "name": "Algiers", "timezone": "Africa/Algiers" },
    { "id": 5, "name": "Asmara", "timezone": "Africa/Asmara" },
    { "id": 6, "name": "Asmera", "timezone": "Africa/Asmera" },
    { "id": 7, "name": "Bamako", "timezone": "Africa/Bamako" },
    { "id": 8, "name": "Bangui", "timezone": "Africa/Bangui" },
    { "id": 9, "name": "Banjul", "timezone": "Africa/Banjul" },
    { "id": 10, "name": "Bissau", "timezone": "Africa/Bissau" },
    { "id": 11, "name": "Blantyre", "timezone": "Africa/Blantyre" },
    { "id": 12, "name": "Brazzaville", "timezone": "Africa/Brazzaville" },
    { "id": 13, "name": "Bujumbura", "timezone": "Africa/Bujumbura" },
    { "id": 14, "name": "Cairo", "timezone": "Africa/Cairo" },
    { "id": 15, "name": "Casablanca", "timezone": "Africa/Casablanca" },
    { "id": 16, "name": "Ceuta", "timezone": "Africa/Ceuta" },
    { "id": 17, "name": "Conakry", "timezone": "Africa/Conakry" },
    { "id": 18, "name": "Dakar", "timezone": "Africa/Dakar" },
    { "id": 19, "name": "Dar es Salaam", "timezone": "Africa/Dar_es_Salaam" },
    { "id": 20, "name": "Djibouti", "timezone": "Africa/Djibouti" },
    { "id": 21, "name": "Douala", "timezone": "Africa/Douala" },
    { "id": 22, "name": "El Aaiun", "timezone": "Africa/El_Aaiun" },
    { "id": 23, "name": "Freetown", "timezone": "Africa/Freetown" },
    { "id": 24, "name": "Gaborone", "timezone": "Africa/Gaborone" },
    { "id": 25, "name": "Harare", "timezone": "Africa/Harare" },
    { "id": 26, "name": "Johannesburg", "timezone": "Africa/Johannesburg" },
    { "id": 27, "name": "Juba", "timezone": "Africa/Juba" },
    { "id": 28, "name": "Kampala", "timezone": "Africa/Kampala" },
    { "id": 29, "name": "Khartoum", "timezone": "Africa/Khartoum" },
    { "id": 30, "name": "Kigali", "timezone": "Africa/Kigali" },
    { "id": 31, "name": "Kinshasa", "timezone": "Africa/Kinshasa" },
    { "id": 32, "name": "Lagos", "timezone": "Africa/Lagos" },
    { "id": 33, "name": "Libreville", "timezone": "Africa/Libreville" },
    { "id": 34, "name": "Lome", "timezone": "Africa/Lome" },
    { "id": 35, "name": "Luanda", "timezone": "Africa/Luanda" },
    { "id": 36, "name": "Lubumbashi", "timezone": "Africa/Lubumbashi" },
    { "id": 37, "name": "Lusaka", "timezone": "Africa/Lusaka" },
    { "id": 38, "name": "Malabo", "timezone": "Africa/Malabo" },
    { "id": 39, "name": "Maputo", "timezone": "Africa/Maputo" },
    { "id": 40, "name": "Maseru", "timezone": "Africa/Maseru" },
    { "id": 41, "name": "Mbabane", "timezone": "Africa/Mbabane" },
    { "id": 42, "name": "Mogadishu", "timezone": "Africa/Mogadishu" },
    { "id": 43, "name": "Monrovia", "timezone": "Africa/Monrovia" },
    { "id": 44, "name": "Nairobi", "timezone": "Africa/Nairobi" },
    { "id": 45, "name": "Ndjamena", "timezone": "Africa/Ndjamena" },
    { "id": 46, "name": "Niamey", "timezone": "Africa/Niamey" },
    { "id": 47, "name": "Nouakchott", "timezone": "Africa/Nouakchott" },
    { "id": 48, "name": "Ouagadougou", "timezone": "Africa/Ouagadougou" },
    { "id": 49, "name": "Porto-Novo", "timezone": "Africa/Porto-Novo" },
    { "id": 50, "name": "Sao Tome", "timezone": "Africa/Sao_Tome" },
    { "id": 51, "name": "Timbuktu", "timezone": "Africa/Timbuktu" },
    { "id": 52, "name": "Tripoli", "timezone": "Africa/Tripoli" },
    { "id": 53, "name": "Tunis", "timezone": "Africa/Tunis" },
    { "id": 54, "name": "Windhoek", "timezone": "Africa/Windhoek" },
    { "id": 55, "name": "Adak", "timezone": "America/Adak" },
    { "id": 56, "name": "Anchorage", "timezone": "America/Anchorage" },
    { "id": 57, "name": "Anguilla", "timezone": "America/Anguilla" },
    { "id": 58, "name": "Antigua", "timezone": "America/Antigua" },
    { "id": 59, "name": "Araguaina", "timezone": "America/Araguaina" },
    { "id": 60, "name": "Buenos Aires", "timezone": "America/Argentina/Buenos_Aires" },
    { "id": 61, "name": "Catamarca", "timezone": "America/Argentina/Catamarca" },
    { "id": 62, "name": "ComodRivadavia", "timezone": "America/Argentina/ComodRivadavia" },
    { "id": 63, "name": "Cordoba", "timezone": "America/Argentina/Cordoba" },
    { "id": 64, "name": "Jujuy", "timezone": "America/Argentina/Jujuy" },
    { "id": 65, "name": "La Rioja", "timezone": "America/Argentina/La_Rioja" },
    { "id": 66, "name": "Mendoza", "timezone": "America/Argentina/Mendoza" },
    { "id": 67, "name": "Rio Gallegos", "timezone": "America/Argentina/Rio_Gallegos" },
    { "id": 68, "name": "Salta", "timezone": "America/Argentina/Salta" },
    { "id": 69, "name": "San Juan", "timezone": "America/Argentina/San_Juan" },
    { "id": 70, "name": "San Luis", "timezone": "America/Argentina/San_Luis" },
    { "id": 71, "name": "Tucuman", "timezone": "America/Argentina/Tucuman" },
    { "id": 72, "name": "Ushuaia", "timezone": "America/Argentina/Ushuaia" },
    { "id": 73, "name": "Aruba", "timezone": "America/Aruba" },
    { "id": 74, "name": "Asuncion", "timezone": "America/Asuncion" },
    { "id": 75, "name": "Atikokan", "timezone": "America/Atikokan" },
    { "id": 76, "name": "Atka", "timezone": "America/Atka" },
    { "id": 77, "name": "Bahia", "timezone": "America/Bahia" },
    { "id": 78, "name": "Bahia Banderas", "timezone": "America/Bahia_Banderas" },
    { "id": 79, "name": "Barbados", "timezone": "America/Barbados" },
    { "id": 80, "name": "Belem", "timezone": "America/Belem" },
    { "id": 81, "name": "Belize", "timezone": "America/Belize" },
    { "id": 82, "name": "Blanc-Sablon", "timezone": "America/Blanc-Sablon" },
    { "id": 83, "name": "Boa Vista", "timezone": "America/Boa_Vista" },
    { "id": 84, "name": "Bogota", "timezone": "America/Bogota" },
    { "id": 85, "name": "Boise", "timezone": "America/Boise" },
    { "id": 86, "name": "Buenos Aires", "timezone": "America/Buenos_Aires" },
    { "id": 87, "name": "Cambridge Bay", "timezone": "America/Cambridge_Bay" },
    { "id": 88, "name": "Campo Grande", "timezone": "America/Campo_Grande" },
    { "id": 89, "name": "Cancun", "timezone": "America/Cancun" },
    { "id": 90, "name": "Caracas", "timezone": "America/Caracas" },
    { "id": 91, "name": "Catamarca", "timezone": "America/Catamarca" },
    { "id": 92, "name": "Cayenne", "timezone": "America/Cayenne" },
    { "id": 93, "name": "Cayman", "timezone": "America/Cayman" },
    { "id": 94, "name": "Chicago", "timezone": "America/Chicago" },
    { "id": 95, "name": "Chihuahua", "timezone": "America/Chihuahua" },
    { "id": 96, "name": "Coral Harbour", "timezone": "America/Coral_Harbour" },
    { "id": 97, "name": "Cordoba", "timezone": "America/Cordoba" },
    { "id": 98, "name": "Costa Rica", "timezone": "America/Costa_Rica" },
    { "id": 99, "name": "Creston", "timezone": "America/Creston" },
    { "id": 100, "name": "Cuiaba", "timezone": "America/Cuiaba" },
    { "id": 101, "name": "Curacao", "timezone": "America/Curacao" },
    { "id": 102, "name": "Danmarkshavn", "timezone": "America/Danmarkshavn" },
    { "id": 103, "name": "Dawson", "timezone": "America/Dawson" },
    { "id": 104, "name": "Dawson Creek", "timezone": "America/Dawson_Creek" },
    { "id": 105, "name": "Denver", "timezone": "America/Denver" },
    { "id": 106, "name": "Detroit", "timezone": "America/Detroit" },
    { "id": 107, "name": "Dominica", "timezone": "America/Dominica" },
    { "id": 108, "name": "Edmonton", "timezone": "America/Edmonton" },
    { "id": 109, "name": "Eirunepe", "timezone": "America/Eirunepe" },
    { "id": 110, "name": "El Salvador", "timezone": "America/El_Salvador" },
    { "id": 111, "name": "Ensenada", "timezone": "America/Ensenada" },
    { "id": 112, "name": "Fort Nelson", "timezone": "America/Fort_Nelson" },
    { "id": 113, "name": "Fort Wayne", "timezone": "America/Fort_Wayne" },
    { "id": 114, "name": "Fortaleza", "timezone": "America/Fortaleza" },
    { "id": 115, "name": "Glace Bay", "timezone": "America/Glace_Bay" },
    { "id": 116, "name": "Godthab", "timezone": "America/Godthab" },
    { "id": 117, "name": "Goose Bay", "timezone": "America/Goose_Bay" },
    { "id": 118, "name": "Grand Turk", "timezone": "America/Grand_Turk" },
    { "id": 119, "name": "Grenada", "timezone": "America/Grenada" },
    { "id": 120, "name": "Guadeloupe", "timezone": "America/Guadeloupe" },
    { "id": 121, "name": "Guatemala", "timezone": "America/Guatemala" },
    { "id": 122, "name": "Guayaquil", "timezone": "America/Guayaquil" },
    { "id": 123, "name": "Guyana", "timezone": "America/Guyana" },
    { "id": 124, "name": "Halifax", "timezone": "America/Halifax" },
    { "id": 125, "name": "Havana", "timezone": "America/Havana" },
    { "id": 126, "name": "Hermosillo", "timezone": "America/Hermosillo" },
    { "id": 127, "name": "Indianapolis", "timezone": "America/Indiana/Indianapolis" },
    { "id": 128, "name": "Knox", "timezone": "America/Indiana/Knox" },
    { "id": 129, "name": "Marengo", "timezone": "America/Indiana/Marengo" },
    { "id": 130, "name": "Petersburg", "timezone": "America/Indiana/Petersburg" },
    { "id": 131, "name": "Tell City", "timezone": "America/Indiana/Tell_City" },
    { "id": 132, "name": "Vevay", "timezone": "America/Indiana/Vevay" },
    { "id": 133, "name": "Vincennes", "timezone": "America/Indiana/Vincennes" },
    { "id": 134, "name": "Winamac", "timezone": "America/Indiana/Winamac" },
    { "id": 135, "name": "Indianapolis", "timezone": "America/Indianapolis" },
    { "id": 136, "name": "Inuvik", "timezone": "America/Inuvik" },
    { "id": 137, "name": "Iqaluit", "timezone": "America/Iqaluit" },
    { "id": 138, "name": "Jamaica", "timezone": "America/Jamaica" },
    { "id": 139, "name": "Jujuy", "timezone": "America/Jujuy" },
    { "id": 140, "name": "Juneau", "timezone": "America/Juneau" },
    { "id": 141, "name": "Louisville", "timezone": "America/Kentucky/Louisville" },
    { "id": 142, "name": "Monticello", "timezone": "America/Kentucky/Monticello" },
    { "id": 143, "name": "Knox_IN", "timezone": "America/Knox_IN" },
    { "id": 144, "name": "Kralendijk", "timezone": "America/Kralendijk" },
    { "id": 145, "name": "La Paz", "timezone": "America/La_Paz" },
    { "id": 146, "name": "Lima", "timezone": "America/Lima" },
    { "id": 147, "name": "Los Angeles", "timezone": "America/Los_Angeles" },
    { "id": 148, "name": "Louisville", "timezone": "America/Louisville" },
    { "id": 149, "name": "Lower Princes", "timezone": "America/Lower_Princes" },
    { "id": 150, "name": "Maceio", "timezone": "America/Maceio" },
    { "id": 151, "name": "Managua", "timezone": "America/Managua" },
    { "id": 152, "name": "Manaus", "timezone": "America/Manaus" },
    { "id": 153, "name": "Marigot", "timezone": "America/Marigot" },
    { "id": 154, "name": "Martinique", "timezone": "America/Martinique" },
    { "id": 155, "name": "Matamoros", "timezone": "America/Matamoros" },
    { "id": 156, "name": "Mazatlan", "timezone": "America/Mazatlan" },
    { "id": 157, "name": "Mendoza", "timezone": "America/Mendoza" },
    { "id": 158, "name": "Menominee", "timezone": "America/Menominee" },
    { "id": 159, "name": "Merida", "timezone": "America/Merida" },
    { "id": 160, "name": "Metlakatla", "timezone": "America/Metlakatla" },
    { "id": 161, "name": "Mexico City", "timezone": "America/Mexico_City" },
    { "id": 162, "name": "Miquelon", "timezone": "America/Miquelon" },
    { "id": 163, "name": "Moncton", "timezone": "America/Moncton" },
    { "id": 164, "name": "Monterrey", "timezone": "America/Monterrey" },
    { "id": 165, "name": "Montevideo", "timezone": "America/Montevideo" },
    { "id": 166, "name": "Montreal", "timezone": "America/Montreal" },
    { "id": 167, "name": "Montserrat", "timezone": "America/Montserrat" },
    { "id": 168, "name": "Nassau", "timezone": "America/Nassau" },
    { "id": 169, "name": "New York", "timezone": "America/New_York" },
    { "id": 170, "name": "Nipigon", "timezone": "America/Nipigon" },
    { "id": 171, "name": "Nome", "timezone": "America/Nome" },
    { "id": 172, "name": "Noronha", "timezone": "America/Noronha" },
    { "id": 173, "name": "Beulah", "timezone": "America/North_Dakota/Beulah" },
    { "id": 174, "name": "Center", "timezone": "America/North_Dakota/Center" },
    { "id": 175, "name": "New Salem", "timezone": "America/North_Dakota/New_Salem" },
    { "id": 176, "name": "Nuuk", "timezone": "America/Nuuk" },
    { "id": 177, "name": "Ojinaga", "timezone": "America/Ojinaga" },
    { "id": 178, "name": "Panama", "timezone": "America/Panama" },
    { "id": 179, "name": "Pangnirtung", "timezone": "America/Pangnirtung" },
    { "id": 180, "name": "Paramaribo", "timezone": "America/Paramaribo" },
    { "id": 181, "name": "Phoenix", "timezone": "America/Phoenix" },
    { "id": 182, "name": "Port of Spain", "timezone": "America/Port_of_Spain" },
    { "id": 183, "name": "Port-au-Prince", "timezone": "America/Port-au-Prince" },
    { "id": 184, "name": "Porto Acre", "timezone": "America/Porto_Acre" },
    { "id": 185, "name": "Porto Velho", "timezone": "America/Porto_Velho" },
    { "id": 186, "name": "Puerto Rico", "timezone": "America/Puerto_Rico" },
    { "id": 187, "name": "Punta Arenas", "timezone": "America/Punta_Arenas" },
    { "id": 188, "name": "Rainy River", "timezone": "America/Rainy_River" },
    { "id": 189, "name": "Rankin Inlet", "timezone": "America/Rankin_Inlet" },
    { "id": 190, "name": "Recife", "timezone": "America/Recife" },
    { "id": 191, "name": "Regina", "timezone": "America/Regina" },
    { "id": 192, "name": "Resolute", "timezone": "America/Resolute" },
    { "id": 193, "name": "Rio Branco", "timezone": "America/Rio_Branco" },
    { "id": 194, "name": "Rosario", "timezone": "America/Rosario" },
    { "id": 195, "name": "Santa Isabel", "timezone": "America/Santa_Isabel" },
    { "id": 196, "name": "Santarem", "timezone": "America/Santarem" },
    { "id": 197, "name": "Santiago", "timezone": "America/Santiago" },
    { "id": 198, "name": "Santo Domingo", "timezone": "America/Santo_Domingo" },
    { "id": 199, "name": "Sao Paulo", "timezone": "America/Sao_Paulo" },
    { "id": 200, "name": "Scoresbysund", "timezone": "America/Scoresbysund" },
    { "id": 201, "name": "Shiprock", "timezone": "America/Shiprock" },
    { "id": 202, "name": "Sitka", "timezone": "America/Sitka" },
    { "id": 203, "name": "St Barthelemy", "timezone": "America/St_Barthelemy" },
    { "id": 204, "name": "St Johns", "timezone": "America/St_Johns" },
    { "id": 205, "name": "St Kitts", "timezone": "America/St_Kitts" },
    { "id": 206, "name": "St Lucia", "timezone": "America/St_Lucia" },
    { "id": 207, "name": "St Thomas", "timezone": "America/St_Thomas" },
    { "id": 208, "name": "St Vincent", "timezone": "America/St_Vincent" },
    { "id": 209, "name": "Swift Current", "timezone": "America/Swift_Current" },
    { "id": 210, "name": "Tegucigalpa", "timezone": "America/Tegucigalpa" },
    { "id": 211, "name": "Thule", "timezone": "America/Thule" },
    { "id": 212, "name": "Thunder Bay", "timezone": "America/Thunder_Bay" },
    { "id": 213, "name": "Tijuana", "timezone": "America/Tijuana" },
    { "id": 214, "name": "Toronto", "timezone": "America/Toronto" },
    { "id": 215, "name": "Tortola", "timezone": "America/Tortola" },
    { "id": 216, "name": "Vancouver", "timezone": "America/Vancouver" },
    { "id": 217, "name": "Virgin", "timezone": "America/Virgin" },
    { "id": 218, "name": "Whitehorse", "timezone": "America/Whitehorse" },
    { "id": 219, "name": "Winnipeg", "timezone": "America/Winnipeg" },
    { "id": 220, "name": "Yakutat", "timezone": "America/Yakutat" },
    { "id": 221, "name": "Yellowknife", "timezone": "America/Yellowknife" },
    { "id": 222, "name": "Casey", "timezone": "Antarctica/Casey" },
    { "id": 223, "name": "Davis", "timezone": "Antarctica/Davis" },
    { "id": 224, "name": "DumontDUrville", "timezone": "Antarctica/DumontDUrville" },
    { "id": 225, "name": "Macquarie", "timezone": "Antarctica/Macquarie" },
    { "id": 226, "name": "Mawson", "timezone": "Antarctica/Mawson" },
    { "id": 227, "name": "McMurdo", "timezone": "Antarctica/McMurdo" },
    { "id": 228, "name": "Palmer", "timezone": "Antarctica/Palmer" },
    { "id": 229, "name": "Rothera", "timezone": "Antarctica/Rothera" },
    { "id": 230, "name": "South Pole", "timezone": "Antarctica/South_Pole" },
    { "id": 231, "name": "Syowa", "timezone": "Antarctica/Syowa" },
    { "id": 232, "name": "Troll", "timezone": "Antarctica/Troll" },
    { "id": 233, "name": "Vostok", "timezone": "Antarctica/Vostok" },
    { "id": 234, "name": "Longyearbyen", "timezone": "Arctic/Longyearbyen" },
    { "id": 235, "name": "Aden", "timezone": "Asia/Aden" },
    { "id": 236, "name": "Almaty", "timezone": "Asia/Almaty" },
    { "id": 237, "name": "Amman", "timezone": "Asia/Amman" },
    { "id": 238, "name": "Anadyr", "timezone": "Asia/Anadyr" },
    { "id": 239, "name": "Aqtau", "timezone": "Asia/Aqtau" },
    { "id": 240, "name": "Aqtobe", "timezone": "Asia/Aqtobe" },
    { "id": 241, "name": "Ashgabat", "timezone": "Asia/Ashgabat" },
    { "id": 242, "name": "Ashkhabad", "timezone": "Asia/Ashkhabad" },
    { "id": 243, "name": "Atyrau", "timezone": "Asia/Atyrau" },
    { "id": 244, "name": "Baghdad", "timezone": "Asia/Baghdad" },
    { "id": 245, "name": "Bahrain", "timezone": "Asia/Bahrain" },
    { "id": 246, "name": "Baku", "timezone": "Asia/Baku" },
    { "id": 247, "name": "Bangkok", "timezone": "Asia/Bangkok" },
    { "id": 248, "name": "Barnaul", "timezone": "Asia/Barnaul" },
    { "id": 249, "name": "Beirut", "timezone": "Asia/Beirut" },
    { "id": 250, "name": "Bishkek", "timezone": "Asia/Bishkek" },
    { "id": 251, "name": "Brunei", "timezone": "Asia/Brunei" },
    { "id": 252, "name": "Calcutta", "timezone": "Asia/Calcutta" },
    { "id": 253, "name": "Chita", "timezone": "Asia/Chita" },
    { "id": 254, "name": "Choibalsan", "timezone": "Asia/Choibalsan" },
    { "id": 255, "name": "Chongqing", "timezone": "Asia/Chongqing" },
    { "id": 256, "name": "Chungking", "timezone": "Asia/Chungking" },
    { "id": 257, "name": "Colombo", "timezone": "Asia/Colombo" },
    { "id": 258, "name": "Dacca", "timezone": "Asia/Dacca" },
    { "id": 259, "name": "Damascus", "timezone": "Asia/Damascus" },
    { "id": 260, "name": "Dhaka", "timezone": "Asia/Dhaka" },
    { "id": 261, "name": "Dili", "timezone": "Asia/Dili" },
    { "id": 262, "name": "Dubai", "timezone": "Asia/Dubai" },
    { "id": 263, "name": "Dushanbe", "timezone": "Asia/Dushanbe" },
    { "id": 264, "name": "Famagusta", "timezone": "Asia/Famagusta" },
    { "id": 265, "name": "Gaza", "timezone": "Asia/Gaza" },
    { "id": 266, "name": "Harbin", "timezone": "Asia/Harbin" },
    { "id": 267, "name": "Hebron", "timezone": "Asia/Hebron" },
    { "id": 268, "name": "Ho Chi Minh", "timezone": "Asia/Ho_Chi_Minh" },
    { "id": 269, "name": "Hong Kong", "timezone": "Asia/Hong_Kong" },
    { "id": 270, "name": "Hovd", "timezone": "Asia/Hovd" },
    { "id": 271, "name": "Irkutsk", "timezone": "Asia/Irkutsk" },
    { "id": 272, "name": "Istanbul", "timezone": "Asia/Istanbul" },
    { "id": 273, "name": "Jakarta", "timezone": "Asia/Jakarta" },
    { "id": 274, "name": "Jayapura", "timezone": "Asia/Jayapura" },
    { "id": 275, "name": "Jerusalem", "timezone": "Asia/Jerusalem" },
    { "id": 276, "name": "Kabul", "timezone": "Asia/Kabul" },
    { "id": 277, "name": "Kamchatka", "timezone": "Asia/Kamchatka" },
    { "id": 278, "name": "Karachi", "timezone": "Asia/Karachi" },
    { "id": 279, "name": "Kashgar", "timezone": "Asia/Kashgar" },
    { "id": 280, "name": "Kathmandu", "timezone": "Asia/Kathmandu" },
    { "id": 281, "name": "Katmandu", "timezone": "Asia/Katmandu" },
    { "id": 282, "name": "Khandyga", "timezone": "Asia/Khandyga" },
    { "id": 283, "name": "Kolkata", "timezone": "Asia/Kolkata" },
    { "id": 284, "name": "Krasnoyarsk", "timezone": "Asia/Krasnoyarsk" },
    { "id": 285, "name": "Kuala Lumpur", "timezone": "Asia/Kuala_Lumpur" },
    { "id": 286, "name": "Kuching", "timezone": "Asia/Kuching" },
    { "id": 287, "name": "Kuwait", "timezone": "Asia/Kuwait" },
    { "id": 288, "name": "Macao", "timezone": "Asia/Macao" },
    { "id": 289, "name": "Macau", "timezone": "Asia/Macau" },
    { "id": 290, "name": "Magadan", "timezone": "Asia/Magadan" },
    { "id": 291, "name": "Makassar", "timezone": "Asia/Makassar" },
    { "id": 292, "name": "Manila", "timezone": "Asia/Manila" },
    { "id": 293, "name": "Muscat", "timezone": "Asia/Muscat" },
    { "id": 294, "name": "Nicosia", "timezone": "Asia/Nicosia" },
    { "id": 295, "name": "Novokuznetsk", "timezone": "Asia/Novokuznetsk" },
    { "id": 296, "name": "Novosibirsk", "timezone": "Asia/Novosibirsk" },
    { "id": 297, "name": "Omsk", "timezone": "Asia/Omsk" },
    { "id": 298, "name": "Oral", "timezone": "Asia/Oral" },
    { "id": 299, "name": "Phnom Penh", "timezone": "Asia/Phnom_Penh" },
    { "id": 300, "name": "Pontianak", "timezone": "Asia/Pontianak" },
    { "id": 301, "name": "Pyongyang", "timezone": "Asia/Pyongyang" },
    { "id": 302, "name": "Qatar", "timezone": "Asia/Qatar" },
    { "id": 303, "name": "Qostanay", "timezone": "Asia/Qostanay" },
    { "id": 304, "name": "Qyzylorda", "timezone": "Asia/Qyzylorda" },
    { "id": 305, "name": "Rangoon", "timezone": "Asia/Rangoon" },
    { "id": 306, "name": "Riyadh", "timezone": "Asia/Riyadh" },
    { "id": 307, "name": "Saigon", "timezone": "Asia/Saigon" },
    { "id": 308, "name": "Sakhalin", "timezone": "Asia/Sakhalin" },
    { "id": 309, "name": "Samarkand", "timezone": "Asia/Samarkand" },
    { "id": 310, "name": "Seoul", "timezone": "Asia/Seoul" },
    { "id": 311, "name": "Shanghai", "timezone": "Asia/Shanghai" },
    { "id": 312, "name": "Singapore", "timezone": "Asia/Singapore" },
    { "id": 313, "name": "Srednekolymsk", "timezone": "Asia/Srednekolymsk" },
    { "id": 314, "name": "Taipei", "timezone": "Asia/Taipei" },
    { "id": 315, "name": "Tashkent", "timezone": "Asia/Tashkent" },
    { "id": 316, "name": "Tbilisi", "timezone": "Asia/Tbilisi" },
    { "id": 317, "name": "Tehran", "timezone": "Asia/Tehran" },
    { "id": 318, "name": "Tel Aviv", "timezone": "Asia/Tel_Aviv" },
    { "id": 319, "name": "Thimbu", "timezone": "Asia/Thimbu" },
    { "id": 320, "name": "Thimphu", "timezone": "Asia/Thimphu" },
    { "id": 321, "name": "Tokyo", "timezone": "Asia/Tokyo" },
    { "id": 322, "name": "Tomsk", "timezone": "Asia/Tomsk" },
    { "id": 323, "name": "Ujung Pandang", "timezone": "Asia/Ujung_Pandang" },
    { "id": 324, "name": "Ulaanbaatar", "timezone": "Asia/Ulaanbaatar" },
    { "id": 325, "name": "Ulan Bator", "timezone": "Asia/Ulan_Bator" },
    { "id": 326, "name": "Urumqi", "timezone": "Asia/Urumqi" },
    { "id": 327, "name": "Ust-Nera", "timezone": "Asia/Ust-Nera" },
    { "id": 328, "name": "Vientiane", "timezone": "Asia/Vientiane" },
    { "id": 329, "name": "Vladivostok", "timezone": "Asia/Vladivostok" },
    { "id": 330, "name": "Yakutsk", "timezone": "Asia/Yakutsk" },
    { "id": 331, "name": "Yangon", "timezone": "Asia/Yangon" },
    { "id": 332, "name": "Yekaterinburg", "timezone": "Asia/Yekaterinburg" },
    { "id": 333, "name": "Yerevan", "timezone": "Asia/Yerevan" },
    { "id": 334, "name": "Azores", "timezone": "Atlantic/Azores" },
    { "id": 335, "name": "Bermuda", "timezone": "Atlantic/Bermuda" },
    { "id": 336, "name": "Canary", "timezone": "Atlantic/Canary" },
    { "id": 337, "name": "Cape Verde", "timezone": "Atlantic/Cape_Verde" },
    { "id": 338, "name": "Faeroe", "timezone": "Atlantic/Faeroe" },
    { "id": 339, "name": "Faroe", "timezone": "Atlantic/Faroe" },
    { "id": 340, "name": "Jan Mayen", "timezone": "Atlantic/Jan_Mayen" },
    { "id": 341, "name": "Madeira", "timezone": "Atlantic/Madeira" },
    { "id": 342, "name": "Reykjavik", "timezone": "Atlantic/Reykjavik" },
    { "id": 343, "name": "South Georgia", "timezone": "Atlantic/South_Georgia" },
    { "id": 344, "name": "St Helena", "timezone": "Atlantic/St_Helena" },
    { "id": 345, "name": "Stanley", "timezone": "Atlantic/Stanley" },
    { "id": 346, "name": "ACT", "timezone": "Australia/ACT" },
    { "id": 347, "name": "Adelaide", "timezone": "Australia/Adelaide" },
    { "id": 348, "name": "Brisbane", "timezone": "Australia/Brisbane" },
    { "id": 349, "name": "Broken Hill", "timezone": "Australia/Broken_Hill" },
    { "id": 350, "name": "Canberra", "timezone": "Australia/Canberra" },
    { "id": 351, "name": "Currie", "timezone": "Australia/Currie" },
    { "id": 352, "name": "Darwin", "timezone": "Australia/Darwin" },
    { "id": 353, "name": "Eucla", "timezone": "Australia/Eucla" },
    { "id": 354, "name": "Hobart", "timezone": "Australia/Hobart" },
    { "id": 355, "name": "LHI", "timezone": "Australia/LHI" },
    { "id": 356, "name": "Lindeman", "timezone": "Australia/Lindeman" },
    { "id": 357, "name": "Lord Howe", "timezone": "Australia/Lord_Howe" },
    { "id": 358, "name": "Melbourne", "timezone": "Australia/Melbourne" },
    { "id": 359, "name": "North", "timezone": "Australia/North" },
    { "id": 360, "name": "NSW", "timezone": "Australia/NSW" },
    { "id": 361, "name": "Perth", "timezone": "Australia/Perth" },
    { "id": 362, "name": "Queensland", "timezone": "Australia/Queensland" },
    { "id": 363, "name": "South", "timezone": "Australia/South" },
    { "id": 364, "name": "Sydney", "timezone": "Australia/Sydney" },
    { "id": 365, "name": "Tasmania", "timezone": "Australia/Tasmania" },
    { "id": 366, "name": "Victoria", "timezone": "Australia/Victoria" },
    { "id": 367, "name": "West", "timezone": "Australia/West" },
    { "id": 368, "name": "Yancowinna", "timezone": "Australia/Yancowinna" },
    { "id": 369, "name": "Acre", "timezone": "Brazil/Acre" },
    { "id": 370, "name": "DeNoronha", "timezone": "Brazil/DeNoronha" },
    { "id": 371, "name": "East", "timezone": "Brazil/East" },
    { "id": 372, "name": "West", "timezone": "Brazil/West" },
    { "id": 373, "name": "Atlantic", "timezone": "Canada/Atlantic" },
    { "id": 374, "name": "Central", "timezone": "Canada/Central" },
    { "id": 375, "name": "Eastern", "timezone": "Canada/Eastern" },
    { "id": 376, "name": "Mountain", "timezone": "Canada/Mountain" },
    { "id": 377, "name": "Newfoundland", "timezone": "Canada/Newfoundland" },
    { "id": 378, "name": "Pacific", "timezone": "Canada/Pacific" },
    { "id": 379, "name": "Saskatchewan", "timezone": "Canada/Saskatchewan" },
    { "id": 380, "name": "Yukon", "timezone": "Canada/Yukon" },
    { "id": 381, "name": "CET", "timezone": "CET" },
    { "id": 382, "name": "Continental", "timezone": "Chile/Continental" },
    { "id": 383, "name": "EasterIsland", "timezone": "Chile/EasterIsland" },
    { "id": 384, "name": "CST6CDT", "timezone": "CST6CDT" },
    { "id": 385, "name": "Cuba", "timezone": "Cuba" },
    { "id": 386, "name": "EET", "timezone": "EET" },
    { "id": 387, "name": "Egypt", "timezone": "Egypt" },
    { "id": 388, "name": "Eire", "timezone": "Eire" },
    { "id": 389, "name": "EST", "timezone": "EST" },
    { "id": 390, "name": "EST5EDT", "timezone": "EST5EDT" },
    { "id": 391, "name": "GMT", "timezone": "Etc/GMT" },
    { "id": 392, "name": "GMT-0", "timezone": "Etc/GMT-0" },
    { "id": 393, "name": "GMT-1", "timezone": "Etc/GMT-1" },
    { "id": 394, "name": "GMT-10", "timezone": "Etc/GMT-10" },
    { "id": 395, "name": "GMT-11", "timezone": "Etc/GMT-11" },
    { "id": 396, "name": "GMT-12", "timezone": "Etc/GMT-12" },
    { "id": 397, "name": "GMT-13", "timezone": "Etc/GMT-13" },
    { "id": 398, "name": "GMT-14", "timezone": "Etc/GMT-14" },
    { "id": 399, "name": "GMT-2", "timezone": "Etc/GMT-2" },
    { "id": 400, "name": "GMT-3", "timezone": "Etc/GMT-3" },
    { "id": 401, "name": "GMT-4", "timezone": "Etc/GMT-4" },
    { "id": 402, "name": "GMT-5", "timezone": "Etc/GMT-5" },
    { "id": 403, "name": "GMT-6", "timezone": "Etc/GMT-6" },
    { "id": 404, "name": "GMT-7", "timezone": "Etc/GMT-7" },
    { "id": 405, "name": "GMT-8", "timezone": "Etc/GMT-8" },
    { "id": 406, "name": "GMT-9", "timezone": "Etc/GMT-9" },
    { "id": 407, "name": "GMT+0", "timezone": "Etc/GMT+0" },
    { "id": 408, "name": "GMT+1", "timezone": "Etc/GMT+1" },
    { "id": 409, "name": "GMT+10", "timezone": "Etc/GMT+10" },
    { "id": 410, "name": "GMT+11", "timezone": "Etc/GMT+11" },
    { "id": 411, "name": "GMT+12", "timezone": "Etc/GMT+12" },
    { "id": 412, "name": "GMT+2", "timezone": "Etc/GMT+2" },
    { "id": 413, "name": "GMT+3", "timezone": "Etc/GMT+3" },
    { "id": 414, "name": "GMT+4", "timezone": "Etc/GMT+4" },
    { "id": 415, "name": "GMT+5", "timezone": "Etc/GMT+5" },
    { "id": 416, "name": "GMT+6", "timezone": "Etc/GMT+6" },
    { "id": 417, "name": "GMT+7", "timezone": "Etc/GMT+7" },
    { "id": 418, "name": "GMT+8", "timezone": "Etc/GMT+8" },
    { "id": 419, "name": "GMT+9", "timezone": "Etc/GMT+9" },
    { "id": 420, "name": "GMT0", "timezone": "Etc/GMT0" },
    { "id": 421, "name": "Greenwich", "timezone": "Etc/Greenwich" },
    { "id": 422, "name": "UCT", "timezone": "Etc/UCT" },
    { "id": 423, "name": "Universal", "timezone": "Etc/Universal" },
    { "id": 424, "name": "UTC", "timezone": "Etc/UTC" },
    { "id": 425, "name": "Zulu", "timezone": "Etc/Zulu" },
    { "id": 426, "name": "Amsterdam", "timezone": "Europe/Amsterdam" },
    { "id": 427, "name": "Andorra", "timezone": "Europe/Andorra" },
    { "id": 428, "name": "Astrakhan", "timezone": "Europe/Astrakhan" },
    { "id": 429, "name": "Athens", "timezone": "Europe/Athens" },
    { "id": 430, "name": "Belfast", "timezone": "Europe/Belfast" },
    { "id": 431, "name": "Belgrade", "timezone": "Europe/Belgrade" },
    { "id": 432, "name": "Berlin", "timezone": "Europe/Berlin" },
    { "id": 433, "name": "Bratislava", "timezone": "Europe/Bratislava" },
    { "id": 434, "name": "Brussels", "timezone": "Europe/Brussels" },
    { "id": 435, "name": "Bucharest", "timezone": "Europe/Bucharest" },
    { "id": 436, "name": "Budapest", "timezone": "Europe/Budapest" },
    { "id": 437, "name": "Busingen", "timezone": "Europe/Busingen" },
    { "id": 438, "name": "Chisinau", "timezone": "Europe/Chisinau" },
    { "id": 439, "name": "Copenhagen", "timezone": "Europe/Copenhagen" },
    { "id": 440, "name": "Dublin", "timezone": "Europe/Dublin" },
    { "id": 441, "name": "Gibraltar", "timezone": "Europe/Gibraltar" },
    { "id": 442, "name": "Guernsey", "timezone": "Europe/Guernsey" },
    { "id": 443, "name": "Helsinki", "timezone": "Europe/Helsinki" },
    { "id": 444, "name": "Isle of Man", "timezone": "Europe/Isle_of_Man" },
    { "id": 445, "name": "Istanbul", "timezone": "Europe/Istanbul" },
    { "id": 446, "name": "Jersey", "timezone": "Europe/Jersey" },
    { "id": 447, "name": "Kaliningrad", "timezone": "Europe/Kaliningrad" },
    { "id": 448, "name": "Kiev", "timezone": "Europe/Kiev" },
    { "id": 449, "name": "Kirov", "timezone": "Europe/Kirov" },
{ "id": 450, "name": "Kyiv", "timezone": "Europe/Kyiv" },
{ "id": 451, "name": "Lisbon", "timezone": "Europe/Lisbon" },
{ "id": 452, "name": "Ljubljana", "timezone": "Europe/Ljubljana" },
{ "id": 453, "name": "London", "timezone": "Europe/London" },
{ "id": 454, "name": "Luxembourg", "timezone": "Europe/Luxembourg" },
{ "id": 455, "name": "Madrid", "timezone": "Europe/Madrid" },
{ "id": 456, "name": "Malta", "timezone": "Europe/Malta" },
{ "id": 457, "name": "Mariehamn", "timezone": "Europe/Mariehamn" },
{ "id": 458, "name": "Minsk", "timezone": "Europe/Minsk" },
{ "id": 459, "name": "Monaco", "timezone": "Europe/Monaco" },
{ "id": 460, "name": "Moscow", "timezone": "Europe/Moscow" },
{ "id": 461, "name": "Nicosia", "timezone": "Europe/Nicosia" },
{ "id": 462, "name": "Oslo", "timezone": "Europe/Oslo" },
{ "id": 463, "name": "Paris", "timezone": "Europe/Paris" },
{ "id": 464, "name": "Podgorica", "timezone": "Europe/Podgorica" },
{ "id": 465, "name": "Prague", "timezone": "Europe/Prague" },
{ "id": 466, "name": "Riga", "timezone": "Europe/Riga" },
{ "id": 467, "name": "Rome", "timezone": "Europe/Rome" },
{ "id": 468, "name": "Samara", "timezone": "Europe/Samara" },
{ "id": 469, "name": "San Marino", "timezone": "Europe/San_Marino" },
{ "id": 470, "name": "Sarajevo", "timezone": "Europe/Sarajevo" },
{ "id": 471, "name": "Saratov", "timezone": "Europe/Saratov" },
{ "id": 472, "name": "Simferopol", "timezone": "Europe/Simferopol" },
{ "id": 473, "name": "Skopje", "timezone": "Europe/Skopje" },
{ "id": 474, "name": "Sofia", "timezone": "Europe/Sofia" },
{ "id": 475, "name": "Stockholm", "timezone": "Europe/Stockholm" },
{ "id": 476, "name": "Tallinn", "timezone": "Europe/Tallinn" },
{ "id": 477, "name": "Tirane", "timezone": "Europe/Tirane" },
{ "id": 478, "name": "Tiraspol", "timezone": "Europe/Tiraspol" },
{ "id": 479, "name": "Ulyanovsk", "timezone": "Europe/Ulyanovsk" },
{ "id": 480, "name": "Uzhgorod", "timezone": "Europe/Uzhgorod" },
{ "id": 481, "name": "Vaduz", "timezone": "Europe/Vaduz" },
{ "id": 482, "name": "Vatican", "timezone": "Europe/Vatican" },
{ "id": 483, "name": "Vienna", "timezone": "Europe/Vienna" },
{ "id": 484, "name": "Vilnius", "timezone": "Europe/Vilnius" },
{ "id": 485, "name": "Volgograd", "timezone": "Europe/Volgograd" },
{ "id": 486, "name": "Warsaw", "timezone": "Europe/Warsaw" },
{ "id": 487, "name": "Zagreb", "timezone": "Europe/Zagreb" },
{ "id": 488, "name": "Zaporozhye", "timezone": "Europe/Zaporozhye" },
{ "id": 489, "name": "Zurich", "timezone": "Europe/Zurich" },
{ "id": 490, "name": "GB", "timezone": "GB" },
{ "id": 491, "name": "GB-Eire", "timezone": "GB-Eire" },
{ "id": 492, "name": "GMT", "timezone": "GMT" },
{ "id": 493, "name": "GMT-0", "timezone": "GMT-0" },
{ "id": 494, "name": "GMT+0", "timezone": "GMT+0" },
{ "id": 495, "name": "GMT0", "timezone": "GMT0" },
{ "id": 496, "name": "Greenwich", "timezone": "Greenwich" },
{ "id": 497, "name": "Hongkong", "timezone": "Hongkong" },
{ "id": 498, "name": "HST", "timezone": "HST" },
{ "id": 499, "name": "Iceland", "timezone": "Iceland" },
{ "id": 500, "name": "Antananarivo", "timezone": "Indian/Antananarivo" },
{ "id": 501, "name": "Chagos", "timezone": "Indian/Chagos" },
{ "id": 502, "name": "Christmas", "timezone": "Indian/Christmas" },
{ "id": 503, "name": "Cocos", "timezone": "Indian/Cocos" },
{ "id": 504, "name": "Comoro", "timezone": "Indian/Comoro" },
{ "id": 505, "name": "Kerguelen", "timezone": "Indian/Kerguelen" },
{ "id": 506, "name": "Mahe", "timezone": "Indian/Mahe" },
{ "id": 507, "name": "Maldives", "timezone": "Indian/Maldives" },
{ "id": 508, "name": "Mauritius", "timezone": "Indian/Mauritius" },
{ "id": 509, "name": "Mayotte", "timezone": "Indian/Mayotte" },
{ "id": 510, "name": "Reunion", "timezone": "Indian/Reunion" },
{ "id": 511, "name": "Iran", "timezone": "Iran" },
{ "id": 512, "name": "Israel", "timezone": "Israel" },
{ "id": 513, "name": "Jamaica", "timezone": "Jamaica" },
{ "id": 514, "name": "Japan", "timezone": "Japan" },
{ "id": 515, "name": "Kwajalein", "timezone": "Kwajalein" },
{ "id": 516, "name": "Libya", "timezone": "Libya" },
{ "id": 517, "name": "MET", "timezone": "MET" },
{ "id": 518, "name": "BajaNorte", "timezone": "Mexico/BajaNorte" },
{ "id": 519, "name": "BajaSur", "timezone": "Mexico/BajaSur" },
{ "id": 520, "name": "General", "timezone": "Mexico/General" },
{ "id": 521, "name": "MST", "timezone": "MST" },
{ "id": 522, "name": "MST7MDT", "timezone": "MST7MDT" },
{ "id": 523, "name": "Navajo", "timezone": "Navajo" },
{ "id": 524, "name": "NZ", "timezone": "NZ" },
{ "id": 525, "name": "NZ-CHAT", "timezone": "NZ-CHAT" },
{ "id": 526, "name": "Apia", "timezone": "Pacific/Apia" },
{ "id": 527, "name": "Auckland", "timezone": "Pacific/Auckland" },
{ "id": 528, "name": "Bougainville", "timezone": "Pacific/Bougainville" },
{ "id": 529, "name": "Chatham", "timezone": "Pacific/Chatham" },
{ "id": 530, "name": "Chuuk", "timezone": "Pacific/Chuuk" },
{ "id": 531, "name": "Easter", "timezone": "Pacific/Easter" },
{ "id": 532, "name": "Efate", "timezone": "Pacific/Efate" },
{ "id": 533, "name": "Enderbury", "timezone": "Pacific/Enderbury" },
{ "id": 534, "name": "Fakaofo", "timezone": "Pacific/Fakaofo" },
{ "id": 535, "name": "Fiji", "timezone": "Pacific/Fiji" },
{ "id": 536, "name": "Funafuti", "timezone": "Pacific/Funafuti" },
{ "id": 537, "name": "Galapagos", "timezone": "Pacific/Galapagos" },
{ "id": 538, "name": "Gambier", "timezone": "Pacific/Gambier" },
{ "id": 539, "name": "Guadalcanal", "timezone": "Pacific/Guadalcanal" },
{ "id": 540, "name": "Guam", "timezone": "Pacific/Guam" },
{ "id": 541, "name": "Honolulu", "timezone": "Pacific/Honolulu" },
{ "id": 542, "name": "Johnston", "timezone": "Pacific/Johnston" },
{ "id": 543, "name": "Kanton", "timezone": "Pacific/Kanton" },
{ "id": 544, "name": "Kiritimati", "timezone": "Pacific/Kiritimati" },
{ "id": 545, "name": "Kosrae", "timezone": "Pacific/Kosrae" },
{ "id": 546, "name": "Kwajalein", "timezone": "Pacific/Kwajalein" },
{ "id": 547, "name": "Majuro", "timezone": "Pacific/Majuro" },
{ "id": 548, "name": "Marquesas", "timezone": "Pacific/Marquesas" },
{ "id": 549, "name": "Midway", "timezone": "Pacific/Midway" },
{ "id": 550, "name": "Nauru", "timezone": "Pacific/Nauru" },
{ "id": 551, "name": "Niue", "timezone": "Pacific/Niue" },
{ "id": 552, "name": "Norfolk", "timezone": "Pacific/Norfolk" },
{ "id": 553, "name": "Noumea", "timezone": "Pacific/Noumea" },
{ "id": 554, "name": "Pago_Pago", "timezone": "Pacific/Pago_Pago" },
{ "id": 555, "name": "Palau", "timezone": "Pacific/Palau" },
{ "id": 556, "name": "Pitcairn", "timezone": "Pacific/Pitcairn" },
{ "id": 557, "name": "Pohnpei", "timezone": "Pacific/Pohnpei" },
{ "id": 558, "name": "Ponape", "timezone": "Pacific/Ponape" },
{ "id": 559, "name": "Port_Moresby", "timezone": "Pacific/Port_Moresby" },
{ "id": 560, "name": "Rarotonga", "timezone": "Pacific/Rarotonga" },
{ "id": 561, "name": "Saipan", "timezone": "Pacific/Saipan" },
{ "id": 562, "name": "Samoa", "timezone": "Pacific/Samoa" },
{ "id": 563, "name": "Tahiti", "timezone": "Pacific/Tahiti" },
{ "id": 564, "name": "Tarawa", "timezone": "Pacific/Tarawa" },
{ "id": 565, "name": "Tongatapu", "timezone": "Pacific/Tongatapu" },
{ "id": 566, "name": "Truk", "timezone": "Pacific/Truk" },
{ "id": 567, "name": "Wake", "timezone": "Pacific/Wake" },
{ "id": 568, "name": "Wallis", "timezone": "Pacific/Wallis" },
{ "id": 569, "name": "Yap", "timezone": "Pacific/Yap" },
{ "id": 570, "name": "Poland", "timezone": "Poland" },
{ "id": 571, "name": "Portugal", "timezone": "Portugal" },
{ "id": 572, "name": "PRC", "timezone": "PRC" },
{ "id": 573, "name": "PST8PDT", "timezone": "PST8PDT" },
{ "id": 574, "name": "ROC", "timezone": "ROC" },
{ "id": 575, "name": "ROK", "timezone": "ROK" },
{ "id": 576, "name": "Singapore", "timezone": "Singapore" },
{ "id": 577, "name": "Turkey", "timezone": "Turkey" },
{ "id": 578, "name": "UCT", "timezone": "UCT" },
{ "id": 579, "name": "Universal", "timezone": "Universal" },
{ "id": 580, "name": "Alaska", "timezone": "US/Alaska" },
{ "id": 581, "name": "Aleutian", "timezone": "US/Aleutian" },
{ "id": 582, "name": "Arizona", "timezone": "US/Arizona" },
{ "id": 583, "name": "Central", "timezone": "US/Central" },
{ "id": 584, "name": "East-Indiana", "timezone": "US/East-Indiana" },
{ "id": 585, "name": "Eastern", "timezone": "US/Eastern" },
{ "id": 586, "name": "Hawaii", "timezone": "US/Hawaii" },
{ "id": 587, "name": "Indiana-Starke", "timezone": "US/Indiana-Starke" },
{ "id": 588, "name": "Michigan", "timezone": "US/Michigan" },
{ "id": 589, "name": "Mountain", "timezone": "US/Mountain" },
{ "id": 590, "name": "Pacific", "timezone": "US/Pacific" },
{ "id": 591, "name": "Samoa", "timezone": "US/Samoa" },
{ "id": 592, "name": "UTC", "timezone": "UTC" },
{ "id": 593, "name": "W-SU", "timezone": "W-SU" },
{ "id": 594, "name": "WET", "timezone": "WET" },
{ "id": 595, "name": "Zulu", "timezone": "Zulu" }
]