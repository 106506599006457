import homeIcon from "../../assets/images/home-icon.svg";
import homeGrayIcon from "../../assets/images/home-line-gray-500.svg";
import homeGrayLightIcon from "../../assets/images/home-line-gray-300.svg";
import lifeBuoyIcon from "../../assets/images/life-buoy.svg";
import lifeBuoyGrayIcon from "../../assets/images/life-buoy-gray-500.svg";
import lifeBuoyGrayLightIcon from "../../assets/images/life-buoy-gray-300.svg";
import settingsIcon from "../../assets/images/settings.svg";
import settingsGrayIcon from "../../assets/images/settings-gray-500.svg";
import settingsGrayLightIcon from "../../assets/images/settings-gray-300.svg";
import { Button } from "../Buttons/Button";
import ProgressBar from "../ProgressBar/ProgressBar";
import menuIcon from "../../assets/images/menu-01.svg";
import menuPrimary from "../../assets/images/menu-01.svg";
import { useEffect, useState } from "react";
import closeIcon from "../../assets/images/x-close-white-lg.svg";
import { useWindowSize } from "@uidotdev/usehooks";
import { useNavigate } from "react-router-dom";
import announcementIcon from "../../assets/images/announcement-01-primary.svg";
import usersIcon from "../../assets/images/users.svg";
import usersGrayIcon from "../../assets/images/users-gray-500.svg";
import usersGrayLightIcon from "../../assets/images/users-gray-300.svg";
import { useSelector } from "../../store/hooks";
import { currentUserSelector } from "../../store/currentUser";
import { supabase } from "../../utilities/supabaseClient";
import { UserImagePlaceholder } from "../userProfileImagePlaceholder/userImagePlaceholder";
import { MenuDropdownItemNew } from "../Dropdown/MenuDropdownItemNew";
import logo from "../../assets/images/path90.svg";
import userCheckIcon from "../../assets/images/user-check-02.svg";
import clickIcon from "../../assets/images/cursor-click-02.svg";
import SideBarNavigation from "./SideBarNavigation";

interface Props {
  hierarchy: "primary" | "default" | "dark";
  fullWidth?: boolean;
  menuIsOpen?: boolean;
  currentItem: string;
  hasProblem?: boolean;
}

export default function SideBarnavigationNewNew({
  hierarchy,
  fullWidth,
  menuIsOpen,
  currentItem,
  hasProblem,
}: Props) {
  const [progressBarIsOpen, setProgressBarIsOpen] = useState<boolean>(true);
  const size = useWindowSize();
  const [sizeIsDesktop, setSizeIsDesktop] = useState(true);
  const [isOpenMobileMenu, setIsOpenMobileMenu] = useState(false);
  const [startPosition, setStartPosition] = useState<number>(0);
  const navigate = useNavigate();
  const user = useSelector(currentUserSelector);
  const [accountIsOpen, setAccountIsOpen] = useState(false);

  function getColorHierarchy() {
    switch (hierarchy) {
      case "primary":
        return "navigationNew_primary";
      case "default":
        return "navigationNew_default";
      case "dark":
        return "navigationNew_dark";
    }
  }

  useEffect(() => {
    setSizeIsDesktop(size.width! >= 768);
  }, [size.width]);

  return (
    <div
      className={`navigationNew ${sizeIsDesktop ? "" : "border-gray-200"}`}
      onTouchStart={
        isOpenMobileMenu
          ? (e) => setStartPosition(e.changedTouches[0]?.clientX)
          : () => {}
      }
      onTouchEnd={
        isOpenMobileMenu
          ? (e) =>
              e.changedTouches[0].clientX < startPosition
                ? setIsOpenMobileMenu(false)
                : null
          : () => {}
      }
    >
      <div className="fullWidth">
        {!sizeIsDesktop && !menuIsOpen ? (
          <div className="navigationNew_header">
            <Button
              buttonType="small"
              hierarchy="tertiaryGray"
              size="md"
              buttonImage={hierarchy === "default" ? menuIcon : menuPrimary}
              onClick={() => setIsOpenMobileMenu((prevState) => !prevState)}
              paddingNone
            />
            <div className="menuDropdownNew menuDropdownNew-logo">
              <img src={logo} alt="" />
            </div>
            <div
              className="menuDropdownNew"
              onClick={() => setAccountIsOpen((prevState) => !prevState)}
            >
              <UserImagePlaceholder
                name={user.fullName.toLocaleUpperCase()}
                verified={false}
              />
            </div>
            <div
              className={`navigationNew-account ${
                accountIsOpen ? "navigationNew-account-isOpen" : ""
              }`}
            >
              <div
                className="flex gap-4 items-center"
                style={{
                  paddingLeft: "8px",
                }}
              >
                <UserImagePlaceholder
                  name={user.fullName.toLocaleUpperCase()}
                  verified={false}
                />
                <div>
                  <h3
                    className={`
                        navigationNew_userInfo-name
                        color-gray-900
                      `}
                  >
                    {user.fullName}
                  </h3>
                  <p
                    className={`
                        navigationNew_userInfo-email
                      `}
                  >
                    {user.email}
                  </p>
                </div>
              </div>
              <p
                className="text-md font-medium color-gray-900 menuDropdownNew w-fit h-auto"
                onClick={async () => {
                  const { error } = await supabase().auth.signOut();
                  localStorage.clear();
                  setAccountIsOpen(false);
                  navigate("/login");
                }}
              >
                Log out
              </p>
            </div>

            {isOpenMobileMenu && !sizeIsDesktop ? (
              <div className="topSideNavigation-nav-items-container-mobile">
                <SideBarNavigation
                  setMenuIsOpen={setIsOpenMobileMenu}
                  hierarchy={"default"}
                  fullWidth
                  menuIsOpen
                  currentItem={currentItem}
                  hoverGray
                />
                <div className="topSideNavigation-nav-items-container-mobile-close">
                  <img
                    src={closeIcon}
                    alt=""
                    className="topSideNavigation-nav-items-container-mobile-close-icon"
                    onClick={() => setIsOpenMobileMenu(false)}
                  />
                </div>
              </div>
            ) : null}
          </div>
        ) : (
          <div className="navigationNew_header">
            <div className="menuDropdownNew menuDropdownNew-logo">
              <img src={logo} alt="" />
            </div>
            {/* {
                sizeIsDesktop
                  ? <img src={hierarchy === 'default' ? logoColor : logo} alt="gatherwise logo" className='navigationNew_header-logo' />
                  : null 
              }
              {!sizeIsDesktop
                ? <div className='flex flex-row gap-24 spaceBetween'>
                    <img src={hierarchy === 'default' ? logoColor : logo} alt="gatherwise logo" className='navigationNew_header-logo' />
                    {!sizeIsDesktop && !menuIsOpen
                        ? <Button 
                            buttonType='small'
                            hierarchy='tertiaryGray'
                            size='md'
                            buttonImage={hierarchy === 'default' ? menuIcon : menuPrimary}
                            onClick={() => setIsOpenMobileMenu(prevState => !prevState)}
                          />
                        : null
                    }
                  </div>
                : null
              } */}
            {isOpenMobileMenu && !sizeIsDesktop ? (
              <div className="topSidenavigationNew-nav-items-container-mobile">
                <SideBarnavigationNewNew
                  hierarchy={"default"}
                  fullWidth
                  menuIsOpen
                  currentItem={currentItem}
                />
                <div className="topSidenavigationNew-nav-items-container-mobile-close">
                  <img
                    src={closeIcon}
                    alt=""
                    className="topSidenavigationNew-nav-items-container-mobile-close-icon"
                    onClick={() => setIsOpenMobileMenu(false)}
                  />
                </div>
              </div>
            ) : null}
          </div>
        )}
        {sizeIsDesktop || menuIsOpen ? (
          <div className="menuDropdown_buttons">
            <MenuDropdownItemNew
              hierarchy={hierarchy}
              icon={
                hierarchy === "primary"
                  ? homeIcon
                  : hierarchy === "default"
                  ? homeGrayIcon
                  : homeGrayLightIcon
              }
              label="Home"
              onClick={() => navigate("/")}
              selected={currentItem === "Home"}
            />
            <MenuDropdownItemNew
              hierarchy={hierarchy}
              icon={
                hierarchy === "primary"
                  ? announcementIcon
                  : hierarchy === "default"
                  ? announcementIcon
                  : announcementIcon
              }
              label="Events"
              hasDropdown
              dropdownContent={[
                {
                  name: "Events I'm Organizing",
                  isSelected: currentItem === "EventsOrganizer",
                  onClick: () => {
                    !sizeIsDesktop && setIsOpenMobileMenu(false);
                    navigate("/events?filter=organizer");
                  },
                  icon: userCheckIcon,
                },
                {
                  name: "Events I'm Attending",
                  isSelected: currentItem === "EventsAttendee",
                  onClick: () => {
                    !sizeIsDesktop && setIsOpenMobileMenu(false);
                    navigate("/events?filter=attendee");
                  },
                  icon: clickIcon,
                },
              ]}
              onClick={() => {}}
              selected={
                currentItem === "EventsOrganizer" ||
                currentItem === "EventsAttendee"
              }
            />
            <MenuDropdownItemNew
              hierarchy={hierarchy}
              icon={
                hierarchy === "primary"
                  ? usersIcon
                  : hierarchy === "default"
                  ? usersGrayIcon
                  : usersGrayLightIcon
              }
              label="Contacts"
              onClick={() => navigate("/contacts")}
              selected={currentItem === "Contacts/Network"}
            />
          </div>
        ) : null}
      </div>
      {sizeIsDesktop || menuIsOpen ? (
        <div className="navigationNew_footer">
          <div className="menuDropdown_buttons items-center">
            <MenuDropdownItemNew
              hierarchy={hierarchy}
              icon={
                hierarchy === "primary"
                  ? lifeBuoyIcon
                  : hierarchy === "default"
                  ? lifeBuoyGrayIcon
                  : lifeBuoyGrayLightIcon
              }
              label="Support"
              onClick={() => navigate("/support")}
              selected={currentItem === "Support"}
            />
            <MenuDropdownItemNew
              hierarchy={hierarchy}
              icon={
                hierarchy === "primary"
                  ? settingsIcon
                  : hierarchy === "default"
                  ? settingsGrayIcon
                  : settingsGrayLightIcon
              }
              label="Settings"
              onClick={() => navigate("/settings")}
              selected={currentItem === "Settings"}
            />
          </div>
          {progressBarIsOpen && hasProblem ? (
            <div>
              <ProgressBar
                hierarchy={hierarchy}
                progress={80}
                onClose={() => setProgressBarIsOpen(false)}
              />
            </div>
          ) : null}
          {/* <div 
                className={`
                  navigationNew_userInfo-container 
                  ${hierarchy === 'primary' ? 'navigationNew_userInfo-container-primary' : ''}
                  ${hierarchy === 'default' ? 'navigationNew_userInfo-container-default' : ''}
                  ${hierarchy === 'dark' ? 'navigationNew_userInfo-container-dark' : ''}
                `}>
                <div className='navigationNew_userInfo'>
                  {
                    user.fullName
                      ? <>
                          <UserImagePlaceholder name={user.fullName.toLocaleUpperCase()} verified={false} />
                          <div style={{width: '80%'}}>
                            <h3 
                              className={`
                                navigationNew_userInfo-name
                                ${hierarchy === 'primary' || hierarchy === 'dark' ? 'navigationNew_userInfo-name-primary' : ''}
                                ${hierarchy === 'default' ? 'navigationNew_userInfo-name-default' : ''}
                              `}
                            >
                              {user.fullName}
                            </h3>
                            <p
                              className={`
                                navigationNew_userInfo-email
                                ${hierarchy === 'primary' ? 'navigationNew_userInfo-email-primary' : ''}
                                ${hierarchy === 'default' ? 'navigationNew_userInfo-email-default' : ''}
                                ${hierarchy === 'dark' ? 'navigationNew_userInfo-email-dark' : ''}
                              `}
                            >
                              {user.email}
                            </p>
                          </div>
                        </>
                      : <div className='flex'>
                        <Loader size='xl' />
                      </div>
                  }
                </div>
                <Button
                  buttonType='small'
                  buttonImage={hierarchy === 'primary' ? logoutIcon : logoutGrayIcon}
                  size='sm'
                  hierarchy='tertiaryGray'
                  onClick={async () => {
                    const { error } = await supabase().auth.signOut();
                    localStorage.clear()
                    localStorage.clear();
                    navigate('/login')
                  }}
                />
              </div> */}
          <div
            className="menuDropdownNew"
            onClick={() => setAccountIsOpen((prevState) => !prevState)}
          >
            <UserImagePlaceholder
              name={user.fullName.toLocaleUpperCase()}
              verified={false}
            />
            <h3
              className={`
                    navigationNew_userInfo-name
                    color-gray-900
                  `}
            >
              Account
            </h3>
          </div>
          <div
            className={`navigationNew-account ${
              accountIsOpen ? "navigationNew-account-isOpen" : ""
            }`}
          >
            <div
              className="flex gap-4 items-center"
              style={{
                paddingLeft: "8px",
              }}
            >
              <UserImagePlaceholder
                name={user.fullName.toLocaleUpperCase()}
                verified={false}
              />
              <div>
                <h3
                  className={`
                        navigationNew_userInfo-name
                        color-gray-900
                      `}
                >
                  {user.fullName}
                </h3>
                <p
                  className={`
                        navigationNew_userInfo-email
                      `}
                >
                  {user.email}
                </p>
              </div>
            </div>
            <p
              className="text-md font-medium color-gray-900 menuDropdownNew w-fit h-auto"
              onClick={async () => {
                const { error } = await supabase().auth.signOut();
                localStorage.clear();
                setAccountIsOpen(false);
                navigate("/login");
              }}
            >
              Log out
            </p>
          </div>
        </div>
      ) : null}
    </div>
  );
}
