import arrowDownLight from "../../assets/images/chevron-down-light.svg";
import arrowDown from "../../assets/images/chevron-down.svg";
import arrowDownGrayLight from "../../assets/images/chevron-down-gray-300.svg";
import arrowRightLightBlue from "../../assets/images/chevron-right-light-blue.svg";
import { useState } from "react";

interface Props {
  hierarchy: "primary" | "default" | "dark";
  label: string;
  icon: string;
  hasDropdown?: boolean;
  onClick: () => void;
  selected: boolean;
  dropdownContent?: {
    name: string;
    isSelected: boolean;
    onClick: () => void;
  }[];
  hoverGray?: boolean;
}

export function MenuDropdownItem({
  label,
  icon,
  hasDropdown,
  hierarchy,
  onClick,
  selected,
  dropdownContent,
  hoverGray,
}: Props) {
  const [dropdownIsOpen, setDropdownIsOpen] = useState(false);

  return (
    <div
      onClick={() => {
        hasDropdown ? setDropdownIsOpen((prevState) => !prevState) : onClick();
      }}
    >
      <div
        className={`menuDropdown_content menuDropdown ${
          hoverGray ? "menuDropdown-gray" : ""
        } ${
          selected
            ? hoverGray
              ? "menuDropdown-gray-selected"
              : "menuDropdown-primary-selected"
            : ""
        }`}
      >
        <img src={icon} alt="" />
        <h1
          className={`
            menuDropdown-title 
            ${hierarchy === "default" ? "menuDropdown-title-default" : ""}
            ${hierarchy === "primary" ? "menuDropdown-title-primary" : ""}
            ${hierarchy === "dark" ? "menuDropdown-title-dark" : ""}
          `}
        >
          {label}
        </h1>
        {hasDropdown ? (
          <img
            src={
              hierarchy === "default"
                ? dropdownIsOpen
                  ? arrowRightLightBlue
                  : arrowDown
                : hierarchy === "primary"
                ? dropdownIsOpen
                  ? arrowRightLightBlue
                  : arrowDownLight
                : dropdownIsOpen
                ? arrowRightLightBlue
                : arrowDownGrayLight
            }
            alt=""
            style={{ marginLeft: "auto" }}
          />
        ) : null}
      </div>
      {(dropdownIsOpen || dropdownContent?.some((item) => item.isSelected)) &&
      dropdownContent ? (
        <div className="menuDropdown-dropdown">
          {dropdownContent.map((dropdownItem) => (
            <div
              className={`menuDropdown ${
                hoverGray ? "menuDropdown-gray" : ""
              } ${
                hierarchy === "primary" && dropdownItem.isSelected
                  ? hoverGray
                    ? "menuDropdown-gray-selected"
                    : "menuDropdown-primary-selected"
                  : ""
              }`}
              onClick={() => dropdownItem.onClick()}
            >
              <div className="menuDropdown_content">
                <h1
                  className={`
                            menuDropdown-title 
                            ${
                              hierarchy === "default"
                                ? "menuDropdown-title-default"
                                : ""
                            }
                            ${
                              hierarchy === "primary"
                                ? "menuDropdown-title-primary"
                                : ""
                            }
                            ${
                              hierarchy === "dark"
                                ? "menuDropdown-title-dark"
                                : ""
                            }
                          `}
                >
                  {dropdownItem.name}
                </h1>
              </div>
            </div>
          ))}
        </div>
      ) : null}
    </div>
  );
}
