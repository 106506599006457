export function getTimeInMinutes(time: string) {
  let hoursInMinutes = (time.slice(-2) === 'PM' && +time.split(':')[0] !== 12 
    ? (+time.split(':')[0] + 12) 
    : +time.split(':')[0]) * 60;

  const minutes = +time.split(':')[1].slice(0, 2);

  if (time.slice(-2) === 'AM' && +time.split(':')[0] === 12) {
    hoursInMinutes = ((12 + 12) * 60)
  };
  
  return hoursInMinutes + minutes;
}