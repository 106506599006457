import { useWindowSize } from "@uidotdev/usehooks";
import React, { useEffect, useState } from "react";
import SideBarNavigationNew from "../../components/Navigation/SideBarNavigationNew";
import HorizontalTabs from "../../components/Tabs/HorizontalTabs";
import MyDetails from "./components/MyDetails";
import MyPassword from "./components/MyPassword";
import BillingsDetails from "./components/BillingsDetails";
import Notification from "../../components/Notifications/Notification";
import BottomNavigation from "../../components/Navigation/BottomNavigation";

export default function Settings() {
  const size = useWindowSize();
  const [sizeIsDesktop, setSizeIsDesktop] = useState(true);
  const [currentTab, setCurrentTab] = useState<{
    id: number;
    name: string;
  } | null>({ id: 1, name: "My details" });
  const [notification, setNotification] = useState<{
    isError: boolean;
    title: string;
  } | null>(null);

  useEffect(() => {
    setSizeIsDesktop(size.width! >= 768);
  }, [size.width]);

  return (
    <div className="fullHeight">
      <div
        className={`eventDetails_container ${
          !sizeIsDesktop ? "flex-column" : ""
        }`}
      >
        <SideBarNavigationNew hierarchy="primary" currentItem="Settings" />
        <div className={`flex flex-column fullWidth overflowYScroll`}>
          <div
            className="flex flex-column bgWhite px-24 py-24"
            style={{ paddingBottom: "0" }}
          >
            <div
              className="flex items-center spaceBetween maxWidth-900"
              style={{ margin: sizeIsDesktop ? "0 auto" : "", width: "900px" }}
            >
              <div className="flex flex-column">
                <h3 className="eventsPage-header-title xs">Settings</h3>
                <HorizontalTabs
                  current={currentTab}
                  setCurrent={setCurrentTab}
                  tabs={[
                    { id: 1, name: "My details" },
                    { id: 2, name: "Password" },
                    { id: 3, name: "Billing" },
                  ]}
                  type="Underline"
                  height="auto"
                />
              </div>
              <div></div>
            </div>
          </div>
          {notification ? (
            <Notification
              isError={notification.isError}
              setIsOpen={() => {
                setNotification(null);
              }}
              title={notification.title}
              description=""
            />
          ) : null}
          <div
            className={`eventDetails_main-container eventDetails_main-container-withoutBorder border-box h-fit overflowYScroll ${
              sizeIsDesktop ? "" : "fullWidth"
            }`}
            style={{
              margin: sizeIsDesktop ? "0 auto" : "0",
              padding: "0 12px 0",
            }}
          >
            <div
              className="eventDetails_main h-fit eventDetails_main-bgTransparent paddingNone"
              style={{ padding: "12px 0" }}
            >
              {currentTab?.name === "My details" && currentTab.id === 1 ? (
                <MyDetails
                  setNotification={setNotification}
                  isDesktopSize={sizeIsDesktop}
                />
              ) : null}
              {currentTab?.name === "Password" && currentTab.id === 2 ? (
                <MyPassword
                  setNotification={setNotification}
                  isDesktopSize={sizeIsDesktop}
                />
              ) : null}
              {currentTab?.name === "Billing" && currentTab.id === 3 ? (
                <BillingsDetails />
              ) : null}
            </div>
          </div>
        </div>
        <div style={{ marginTop: "auto" }}>
          <BottomNavigation selected="none" />
        </div>
      </div>
    </div>
  );
}
