import { useWindowSize } from "@uidotdev/usehooks";
import React, { useEffect, useState } from "react";
import SideBarNavigationNew from "../../components/Navigation/SideBarNavigationNew";
import ContactsTable from "../../components/Tables/ContactsTable";
import ReviewContactDetails from "../../components/reviewContactDetails/reviewContactDetails";
import { createPortal } from "react-dom";
import ModalAddNewAttende from "../../components/Modal/ModalAddNewAttende";
import { useQuery } from "@tanstack/react-query";
import { fetchAttendeesContacts, queryClient } from "../../httpQueries/http";
import Loader from "../../components/Loader";
import { logoColor } from "../../types/logoColor";
import { logoImage } from "../../types/logoImage";
import { AttendeeRSVP } from "../../types/attendeeRSVP";
import EmptyPage from "./EmptyPage";
import ModalUserAdded from "../../components/Modal/ModalUserAdded";
import BottomNavigation from "../../components/Navigation/BottomNavigation";
import { Button } from "../../components/Buttons/Button";

export default function Contacts() {
  const size = useWindowSize();
  const token = localStorage.getItem("token");
  const [sizeIsDesktop, setSizeIsDesktop] = useState(true);
  const [reviewContactDetailsIsOpen, setReviewContactDetailsIsOpen] =
    useState(false);
  const [createNewUserIsOpen, setCreateNewUserIsOpen] = useState(false);
  const [newUserFullName, setNewUserFullName] = useState("");
  const [newUserEmail, setNewUserEmail] = useState("");
  const [newUserPhoneNumber, setNewUserPhoneNumber] = useState("");
  const [newUserBusinessName, setNewUserBusinessName] = useState("");
  const [newUserTitle, setNewUserTitle] = useState("");
  const [newUserAdded, setNewUserAdded] = useState(false);
  const [addedUserName, setAddedUserName] = useState("");
  const [showUserInfo, setShowUserInfo] = useState<{
    id: number;
    fullName: string;
    email: string;
    phone?: string;
    businessName: string;
    address: string;
    title: string;
    updatedAt: string;
    user: {
      id: number;
      email: string;
      fullName: string;
      phone?: null | string;
    };
    events: [
      {
        id: number;
        name: string;
        slug: string;
        description: string;
        timezone: string;
        color: null | logoColor;
        pattern: null | logoImage;
        startTime: string;
        endTime: string;
        attendee: {
          email: string;
          userId: number;
          status: "added";
          rsvp: null | AttendeeRSVP;
        };
      }
    ];
  } | null>(null);
  const [selectedPaginationPage, setSelectedPaginationPage] =
    useState<number>(0);
  const [userUpdated, setUserUpdated] = useState<number | null>(null);
  const { data: network, fetchStatus } = useQuery({
    queryKey: [
      "network",
      newUserAdded,
      selectedPaginationPage,
      userUpdated,
      token,
    ],
    queryFn: () =>
      fetchAttendeesContacts({
        skip: selectedPaginationPage * 6,
        take: (selectedPaginationPage + 1) * 6,
        token,
        fetchEvents: true,
      }),
  });
  const [userDeleted, setUserDeleted] = useState<number | null>(null);

  useEffect(() => {
    if (userDeleted) {
      queryClient.setQueryData(
        ["network", newUserAdded, selectedPaginationPage, userUpdated, token],
        {
          network: network!.network.filter(
            (contact) => contact.id !== userDeleted
          ),
          count: network!.count - 1,
        }
      );

      setUserDeleted(null);
    }
  }, [userDeleted]);

  useEffect(() => {
    if (newUserFullName.length) {
      setAddedUserName(newUserFullName);
    }
  }, [newUserFullName]);

  useEffect(() => {
    if (userDeleted === showUserInfo?.id) {
      setShowUserInfo(null);
    }

    if (userUpdated === showUserInfo?.id) {
      setShowUserInfo(
        network?.network.find((contact) => contact.id === showUserInfo.id) ||
          null
      );
    }
  }, [userDeleted, userUpdated]);

  useEffect(() => {
    setSizeIsDesktop(size.width! >= 768);
  }, [size.width]);
  return (
    <div
      className={`eventDetails_container ${
        !sizeIsDesktop ? "flex-column" : "flex-row"
      }`}
    >
      {createNewUserIsOpen
        ? createPortal(
            <ModalAddNewAttende
              setShow={() => setCreateNewUserIsOpen(false)}
              setUserFullName={setNewUserFullName}
              userFullName={newUserFullName}
              setUserEmail={setNewUserEmail}
              userEmail={newUserEmail}
              setUserPhoneNumber={setNewUserPhoneNumber}
              userPhoneNumber={newUserPhoneNumber}
              setUserBusinessName={setNewUserBusinessName}
              userBusinessName={newUserBusinessName}
              setUserTitle={setNewUserTitle}
              userTitle={newUserTitle}
              eventId={0}
              setNewUserAdded={setNewUserAdded}
              isContactsPage={!userUpdated}
              isEdit={userUpdated}
              setUserUpdated={setUserUpdated}
              isDesktopSize={sizeIsDesktop}
            />,
            document.getElementById("modal") as HTMLElement
          )
        : null}
      {newUserAdded
        ? createPortal(
            <ModalUserAdded setIsOpen={setNewUserAdded} name={addedUserName} />,
            document.getElementById("modal") as HTMLElement
          )
        : null}
      <SideBarNavigationNew
        hierarchy="primary"
        currentItem="Contacts/Network"
      />
      <div
        className={`fullWdith bgWhite border-gray-200 ${
          sizeIsDesktop
            ? "eventDetails_main-footer-positionAbsolute border-box"
            : ""
        }`}
      >
        <div
          className="fullWdith flex items-center spaceBetween px-24 py-24"
          style={{
            maxWidth: "900px",
            margin: sizeIsDesktop ? "0 auto" : undefined,
          }}
        >
          <p className="text-xl font-semibold color-gray-950">Contacts</p>
          <Button
            size={"md"}
            hierarchy={"newDesign-primary"}
            buttonType={"regular"}
            newDesignPaddingNone
            onClick={() => setCreateNewUserIsOpen(true)}
          >
            Add contact
          </Button>
        </div>
      </div>
      <div
        className={`eventDetails_main-container flex-row flex fullWidth h-fit overflowYScroll  ${
          sizeIsDesktop ? "overflowYScroll borderNone" : ""
        }`}
        style={{
          margin: sizeIsDesktop ? undefined : "0",
          padding: sizeIsDesktop ? undefined : "12px 12px 0",
          boxSizing: "border-box",
          marginBottom: "0",
          paddingBottom: sizeIsDesktop ? "20px" : undefined,
        }}
      >
        <main
          className={`
            reviewEventDetailsPage-main overflowYScroll fullWidth h-fit
          `}
          style={{
            paddingTop: sizeIsDesktop ? "65px" : "",
            height: sizeIsDesktop ? "auto" : "",
          }}
        >
          <div className="contacts">
            {fetchStatus !== "fetching" ? (
              network?.count ? (
                <ContactsTable
                  selectedPaginationPage={selectedPaginationPage}
                  setSelectedPaginationPage={setSelectedPaginationPage}
                  setIsNewUserModalOpen={setCreateNewUserIsOpen}
                  content={network}
                  setReviewContactDetailsIsOpen={setReviewContactDetailsIsOpen}
                  setShowUserInfo={setShowUserInfo}
                  setUserUpdated={setUserUpdated}
                  setUserFullName={setNewUserFullName}
                  setUserEmail={setNewUserEmail}
                  setUserPhoneNumber={setNewUserPhoneNumber}
                  setUserBusinessName={setNewUserBusinessName}
                  setUserTitle={setNewUserTitle}
                  setUserDeleted={setUserDeleted}
                  selected={showUserInfo?.id}
                />
              ) : (
                <EmptyPage setCreateNewUserIsOpen={setCreateNewUserIsOpen} />
              )
            ) : (
              <div className="fullHeight fullWidth flex items-center justifyCenter">
                <Loader size="xl" />
              </div>
            )}
          </div>
        </main>
        {reviewContactDetailsIsOpen ? (
          <ReviewContactDetails
            setShowUserInfo={setShowUserInfo}
            setIsOpen={setReviewContactDetailsIsOpen}
            showUserInfo={showUserInfo}
            setUserUpdated={setUserUpdated}
            setUserFullName={setNewUserFullName}
            setUserEmail={setNewUserEmail}
            setUserPhoneNumber={setNewUserPhoneNumber}
            setUserBusinessName={setNewUserBusinessName}
            setUserTitle={setNewUserTitle}
            setUserDeleted={setUserDeleted}
            setIsNewUserModalOpen={setCreateNewUserIsOpen}
          />
        ) : null}
      </div>
      <div style={{ marginTop: "auto" }}>
        <BottomNavigation selected="contacts" />
      </div>
    </div>
  );
}
