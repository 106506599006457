import React, { useEffect, useState } from "react";
import CheckBox from "../CheckBox/CheckBox";
import trashIcon from "../../assets/images/trash-01-black.svg";
import pencilIcon from "../../assets/images/edit-01.svg";
import trashIconWhite from "../../assets/images/trash-01-white.svg";
import pencilIconWhite from "../../assets/images/edit-01-white.svg";
import dotsIcon from "../../assets/images/dots-vertical.svg";
import { Button } from "../Buttons/Button";
import { useWindowSize } from "@uidotdev/usehooks";
import { deleteAttendee } from "../../httpQueries/http";
import DotsModal from "../Modal/DotsModal";
import editDotsIcon from "../../assets/images/edit-01.svg";
import deleteIcon from "../../assets/images/trash-01-black.svg";

interface Props {
  content: {
    supportText: string;
    name: string;
    id: number;
    address: string;
    businessName: string;
    title: string;
    status: string;
  }[];
  token: string | null;
  selectedAttendees: {
    id: number;
    name: string;
    supportText?: string | undefined;
  }[];
  setSelectedAttendees: React.Dispatch<
    React.SetStateAction<
      {
        id: number;
        name: string;
        supportText?: string | undefined;
      }[]
    >
  >;
  onDeleteSelected: () => Promise<void>;
  onDeleteAttendee: (item: {
    id: number;
    name: string;
    supportText?: string;
  }) => Promise<void>;
}

export default function UserInvitesTable({
  content,
  token,
  selectedAttendees,
  setSelectedAttendees,
  onDeleteSelected,
  onDeleteAttendee,
}: Props) {
  const size = useWindowSize();
  const [sizeIsDesktop, setSizeIsDesktop] = useState(true);
  const [showDotsModal, setShowDotsModal] = useState<boolean>(false);

  useEffect(() => {
    setSizeIsDesktop(size.width! >= 768);
  }, [size.width]);

  return (
    <>
      <table className="userInviteTable">
        {selectedAttendees.length ? (
          <div className="userInviteTable-top">
            <p>{selectedAttendees.length} selected</p>
            <div className="flex gap-4">
              <Button
                buttonType="small"
                hierarchy="tertiaryGray"
                size="md"
                buttonImage={trashIconWhite}
                onClick={onDeleteSelected}
              />
              {selectedAttendees.length === 1 ? (
                <Button
                  buttonType="small"
                  hierarchy="tertiaryGray"
                  size="md"
                  buttonImage={pencilIconWhite}
                />
              ) : null}
            </div>
          </div>
        ) : null}
        <thead className="userInviteTable-header">
          <tr className="userInviteTable-header-container">
            <th className="userInviteTable-header-invitees">
              <CheckBox
                isSelected={content
                  .map((item) => item.id)
                  .every((item) =>
                    selectedAttendees.map((item) => item.id).includes(item)
                  )}
                items={content}
                setIsSelected={setSelectedAttendees}
              />
              Invitees
            </th>
            <th className="userInviteTable-header-status">Status</th>
            <th></th>
          </tr>
        </thead>
        <tbody className="userInviteTable-body">
          {content.map((item, index) => (
            <tr
              className={`
                userInviteTable-body-container 
                ${
                  (index + 1) % 2 === 0
                    ? "userInviteTable-body-container-isOdd"
                    : ""
                } 
                ${
                  index + 1 === content.length
                    ? "border-none border-radius-10"
                    : ""
                }`}
              key={item.id}
              onClick={() => {
                if (
                  selectedAttendees.map((item) => item.id).includes(item.id)
                ) {
                  setSelectedAttendees((prevState) =>
                    prevState.filter((it) => it.id !== item.id)
                  );
                } else {
                  setSelectedAttendees((prevState) => [...prevState, item]);
                }
              }}
            >
              <td className="userInviteTable-body-item-info">
                <CheckBox
                  isSelected={selectedAttendees
                    .map((item) => item.id)
                    .includes(item.id)}
                  item={item}
                  setIsSelected={setSelectedAttendees}
                />
                <div>
                  <h3 className="userInviteTable-body-item-info-name">
                    {item.name}
                  </h3>
                  <p className="userInviteTable-body-item-info-email">
                    {item.supportText}
                  </p>
                </div>
              </td>
              <td className="userInviteTable-body-item-status">
                <p className="userInviteTable-body-item-status-info">
                  {`${item.status
                    .slice(0, 1)
                    .toLocaleUpperCase()}${item.status.slice(1)}`}
                </p>
              </td>
              <td className="userInviteTable-body-item-actions">
                {sizeIsDesktop ? (
                  <>
                    <Button
                      buttonType="small"
                      hierarchy="tertiaryGray"
                      size="md"
                      buttonImage={trashIcon}
                      onClick={async () => await onDeleteAttendee(item)}
                    />
                    <Button
                      buttonType="small"
                      hierarchy="tertiaryGray"
                      size="md"
                      buttonImage={pencilIcon}
                    />
                  </>
                ) : (
                  <Button
                    buttonType="small"
                    hierarchy="tertiaryGray"
                    size="md"
                    buttonImage={dotsIcon}
                    onClick={() => {
                      setShowDotsModal((prev) => !prev);
                    }}
                  />
                )}
                {showDotsModal && !sizeIsDesktop ? (
                  <DotsModal
                    items={[
                      {
                        id: 1,
                        img: editDotsIcon,
                        text: "Edit",
                        onClick: () => {},
                      },
                      {
                        id: 2,
                        img: deleteIcon,
                        text: "Delete",
                        onClick: () => {
                          onDeleteAttendee({
                            id: item.id,
                            name: item.name,
                            supportText: item.supportText,
                          });
                        },
                      },
                    ]}
                    setIsOpen={setShowDotsModal}
                    top="105px"
                    right="0"
                  />
                ) : null}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
}
