import React from "react";

interface Props {
  bullets: any[];
  className?: string;
  isSmall?: boolean,
}

export default function BulletList({ bullets, className, isSmall }: Props) {
  return (
    <div className={`bulletList ${className && className}`}>
      {bullets.map((_bullet, index) => {
        return (
          <div
            className={`bulletList-bullet ${
              index === bullets.length - 1 ? "bulletList-bullet-last" : ""
            } ${isSmall ? 'bulletList-bullet-small' : ''}`}
          >
            <div className="bulletList-bullet-circle-container">
              <div className="bulletList-bullet-circle"></div>
            </div>
            {index < bullets.length - 1 ? (
              <div className="bulletList-bullet-line"></div>
            ) : null}
          </div>
        );
      })}
    </div>
  );
}
