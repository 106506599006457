import React from "react";
import closeIcon from "../../assets/images/x-close-gray-lg.svg";
import checkCircle from "../../assets/images/check-circle-success.svg";

interface Props {
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  name: string;
}

export default function ModalUserAdded({ setIsOpen, name }: Props) {
  return (
    <div className="modalNotification-container modalNotification-container-success">
      <div className="modalNotification-header modalNotification-header-success">
        <div className="modalNotification-header-actions modalNotification-header-actions-success">
          <div className="modalNotification-header-actions-icon-container-success">
            <div className="modalNotification-header-actions-icon modalNotification-header-actions-icon-success">
              <img src={checkCircle} alt="trash icon" />
            </div>
          </div>
        </div>
        <div className="modalNotification-header-info modalNotification-header-info-success">
          <h3>New contact added</h3>
          <p>
            {name} has been added to your contacts, we will send an email to
            create account.
          </p>
        </div>
      </div>
      <img
        src={closeIcon}
        alt="close icon"
        className="modalNotification-header-actions-close modalNotification-header-actions-close-success"
        onClick={() => setIsOpen(false)}
      />
    </div>
  );
}
