import React, { useEffect, useState } from "react";
import { FormInput } from "../../../components/Inputs/FormInput";
import { Button } from "../../../components/Buttons/Button";
import { useQuery } from "@tanstack/react-query";
import { fetchMyData, patchUserSettings } from "../../../httpQueries/http";
import { useNavigate } from "react-router-dom";
import { supabase } from "../../../utilities/supabaseClient";
import { User } from "../../../types/userForAddType";
import DropdownWithAction from "../../../components/Dropdown/DropdownWithAction";
import { countriesList } from "../../../utilities/countriesList";
import { timeZones } from "../../../utilities/defaultTimeZonesArray";
import DropdownWithRadioButtons from "../../../components/Dropdown/DropdownWithRadioButtons";
import MyDetailsModal from "../../../components/Modal/MyDetailsModal";
import { createPortal } from "react-dom";
import SettingValue from "./SettingValue";
import CountryDropdown from "../../../components/Dropdown/CountryDropdown";
import TimeZoneDropdown from "../../../components/Dropdown/TimeZoneDropdown";

interface Props {
  setNotification: React.Dispatch<
    React.SetStateAction<{
      isError: boolean;
      title: string;
    } | null>
  >;
  isDesktopSize?: boolean;
}

export default function MyDetails({ setNotification, isDesktopSize }: Props) {
  const token = window.localStorage.getItem("token");
  const [editModalIsOpen, setEditModalIsOpen] = useState<
    "personal" | "location" | "food" | null
  >(null);
  const navigate = useNavigate();
  const { data: user } = useQuery({
    queryKey: ["userData", editModalIsOpen],
    queryFn: () => fetchMyData({ token }),
  });
  const [userFirstName, setUserFirstName] = useState(
    user?.fullName ? user?.fullName.split(" ")[0] : ""
  );
  const [userLastName, setUserLastName] = useState(
    user?.fullName ? user?.fullName.split(" ")[1] : ""
  );
  const [userEmail, setUserEmail] = useState(user?.email || "");
  const [timeZone, setTimeZone] = useState<{
    id: number;
    name: string;
    supportText?: string | undefined;
    value?: number | undefined;
    abbr?: string | undefined;
  } | null>(
    timeZones.find((zone) => zone.name === user?.timezone || "") || null
  );
  const [country, setCountry] = useState<{
    id: number;
    name: string;
    supportText?: string | undefined;
    emoji?: string;
    value?: number | undefined;
    abbr?: string | undefined;
  } | null>(
    countriesList.find((listItem) => listItem.name === user?.location || "") ||
      null
  );
  const [foodPreferencesList, setFoodPreferencesList] = useState<User[]>([
    { id: 1, img: "", name: "Gluten free" },
    { id: 2, img: "", name: "Keto/low carb" },
    { id: 3, img: "", name: "Vegan" },
    { id: 4, img: "", name: "Vegetarian" },
  ]);
  const [isFoodPreferencesDropdownOpen, setIsFoodPreferencesDropdownOpen] =
    useState(false);
  const [allergiesList, setAllergiesList] = useState<User[]>([
    { id: 1, img: "", name: "Peanuts" },
    { id: 2, img: "", name: "Tree nuts (Specify)" },
    { id: 3, img: "", name: "Soy" },
    { id: 4, img: "", name: "Dairy" },
    { id: 5, img: "", name: "Eggs" },
  ]);
  const [isAlergiesDropdownOpen, setIsAlergiesDropdownOpen] = useState(false);
  const [pronounsList, setPronounsList] = useState<User[]>([
    { id: 1, img: "", name: "She/her" },
    { id: 2, img: "", name: "He/him" },
    { id: 3, img: "", name: "They/them" },
  ]);
  const [isPronounsDropdownOpen, setIsPronounsDropdownOpen] = useState(false);
  const [selectedPronoun, setSelectedPronoun] = useState<User | null>(null);
  const [allergies, setAllergies] = useState<User[]>(
    user?.allergies?.map((allergy) => ({ img: "", name: allergy })) || []
  );
  const [foodPreferences, setFoodPreferences] = useState<User[]>(
    user?.foodPreferences?.map((preference) => ({
      img: "",
      name: preference,
    })) || []
  );
  const [selectedInModalPronoun, setSelectedInModalPronoun] =
    useState<User | null>(selectedPronoun);
  const [allergiesInModal, setAllergiesInModal] = useState<User[]>(allergies);
  const [foodPreferencesInModal, setFoodPreferencesInModal] =
    useState<User[]>(foodPreferences);
  const [userFirstNameInModal, setUserFirstNameInModal] =
    useState(userFirstName);
  const [userLastNameInModal, setUserLastNameInModal] = useState(userLastName);
  const [userEmailInModal, setUserEmailInModal] = useState(userEmail);
  const [timeZoneInModal, setTimeZoneInModal] = useState<{
    id: number;
    name: string;
    supportText?: string | undefined;
    value?: number | undefined;
    abbr?: string | undefined;
  } | null>(timeZone);
  const [countryInModal, setCountryInModal] = useState<{
    id: number;
    name: string;
    supportText?: string | undefined;
    emoji?: string;
    value?: number | undefined;
    abbr?: string | undefined;
  } | null>(country);

  useEffect(() => {
    setUserFirstName(user?.fullName ? user?.fullName.split(" ")[0] : "");
    setUserLastName(user?.fullName ? user?.fullName.split(" ")[1] : "");
    setUserEmail(user?.email || "");
    setTimeZone(
      timeZones.find((zone) => zone.name === user?.timezone || "") || null
    );
    setCountry(
      countriesList.find(
        (listItem) => listItem.name === user?.location || ""
      ) || null
    );
    setFoodPreferences(
      user?.foodPreferences?.map(
        (preference) =>
          foodPreferencesList.find((pref) => pref.name === preference) || {
            img: "",
            name: preference,
          }
      ) || []
    );
    setAllergies(
      user?.allergies?.map(
        (allergy) =>
          allergiesList.find((allrg) => allrg.name === allergy) || {
            img: "",
            name: allergy,
          }
      ) || []
    );
    setSelectedPronoun(
      user?.pronouns
        ? pronounsList.find((item) => item.name === user?.pronouns) || {
            img: "",
            name: user?.pronouns,
          }
        : null
    );
    setUserFirstNameInModal(user?.fullName ? user?.fullName.split(" ")[0] : "");
    setUserLastNameInModal(user?.fullName ? user?.fullName.split(" ")[1] : "");
    setUserEmailInModal(user?.email || "");
    setTimeZoneInModal(
      timeZones.find((zone) => zone.name === user?.timezone || "") || null
    );
    setCountryInModal(
      countriesList.find(
        (listItem) => listItem.name === user?.location || ""
      ) || null
    );
    setFoodPreferencesInModal(
      user?.foodPreferences?.map(
        (preference) =>
          foodPreferencesList.find((pref) => pref.name === preference) || {
            img: "",
            name: preference,
          }
      ) || []
    );
    setAllergiesInModal(
      user?.allergies?.map(
        (allergy) =>
          allergiesList.find((allrg) => allrg.name === allergy) || {
            img: "",
            name: allergy,
          }
      ) || []
    );
    setSelectedInModalPronoun(
      user?.pronouns
        ? pronounsList.find((item) => item.name === user?.pronouns) || {
            img: "",
            name: user?.pronouns,
          }
        : null
    );
  }, [user]);

  if (!token?.length) {
    supabase().auth.signOut();
    localStorage.clear();
    localStorage.clear();

    navigate("/login");

    return null;
  }

  return (
    <div className="flex flex-column gap-24">
      {editModalIsOpen === "personal"
        ? createPortal(
            <MyDetailsModal
              title="Personal info"
              setIsOpen={() => setEditModalIsOpen(null)}
              content={
                <div className="myDetails-content borderNone boxShadowNone overflowVisible">
                  <div className="myDetails-content-container paddingNone overflowVisible">
                    <div className="myDetails-content-name">
                      <FormInput
                        type="Text"
                        label="First name"
                        fullWidth
                        value={userFirstNameInModal}
                        onChange={(e) => {
                          setUserFirstNameInModal(e.target.value);
                        }}
                        onFocus={() => {
                          setIsAlergiesDropdownOpen(false);
                          setIsFoodPreferencesDropdownOpen(false);
                        }}
                        design="new"
                      />
                      <FormInput
                        type="Text"
                        label="Last name"
                        fullWidth
                        value={userLastNameInModal}
                        onChange={(e) => {
                          setUserLastNameInModal(e.target.value);
                        }}
                        onFocus={() => {
                          setIsAlergiesDropdownOpen(false);
                          setIsFoodPreferencesDropdownOpen(false);
                        }}
                        design="new"
                      />
                    </div>
                    <FormInput
                      type="EmailWithIconBefore"
                      label="Email address"
                      value={userEmailInModal}
                      onChange={(e) => {
                        setUserEmailInModal(e.target.value);
                      }}
                      onFocus={() => {
                        setIsAlergiesDropdownOpen(false);
                        setIsFoodPreferencesDropdownOpen(false);
                      }}
                      design="new"
                    />
                    <DropdownWithRadioButtons
                      content={pronounsList}
                      setContent={setPronounsList}
                      isDropdownOpen={isPronounsDropdownOpen}
                      setIsDropdownOpen={setIsPronounsDropdownOpen}
                      label={"Pronouns"}
                      placeholder={"Pronoun"}
                      onFocus={() => {
                        setIsAlergiesDropdownOpen(false);
                      }}
                      selectedItem={selectedInModalPronoun}
                      setSelectedItem={setSelectedInModalPronoun}
                      bottom="105%"
                    />
                  </div>
                  <div className="fullWidth flex spaceBetween items-center mt-32">
                    <Button
                      buttonType="regular"
                      hierarchy="linkColor"
                      size="md"
                      contentCenter
                      onClick={() => setEditModalIsOpen(null)}
                    >
                      Cancel
                    </Button>
                    <Button
                      buttonType="regular"
                      hierarchy="newDesign-primary"
                      size="md"
                      contentCenter
                      disabled={
                        !(
                          user?.fullName !==
                            `${userFirstName} ${userLastName}` ||
                          user?.location !== country?.name ||
                          user?.allergies.length !== allergies.length ||
                          user?.foodPreferences.length !==
                            foodPreferences.length ||
                          user?.timezone !== timeZone?.name ||
                          user.pronouns !== selectedPronoun?.name
                        )
                      }
                      asyncOnClick={async () => {
                        setUserEmail(userEmailInModal);
                        setUserFirstName(userFirstNameInModal);
                        setUserLastName(userLastNameInModal);
                        setSelectedPronoun(selectedInModalPronoun);
                        const response = await patchUserSettings({
                          token,
                          body: {
                            fullName: `${userFirstNameInModal} ${userLastNameInModal}`,
                            location: country?.name,
                            allergies: allergies.map((item) => item.name),
                            foodPreferences: foodPreferences.map(
                              (item) => item.name
                            ),
                            pronouns: selectedInModalPronoun?.name,
                            timezone: timeZone?.name,
                          },
                        });

                        if (response) {
                          setNotification({
                            isError: false,
                            title: "Personal info updated.",
                          });
                          setEditModalIsOpen(null);
                        }
                      }}
                      newDesignPaddingNone
                    >
                      <p className="px-5">Save</p>
                    </Button>
                  </div>
                </div>
              }
            />,
            document.getElementById("modal") as HTMLElement
          )
        : editModalIsOpen === "food"
        ? createPortal(
            <MyDetailsModal
              title="Food preferences"
              setIsOpen={() => setEditModalIsOpen(null)}
              content={
                <div className="myDetails-content borderNone boxShadowNone oveflowVisible">
                  <div className="myDetails-content-container paddingNone oveflowVisible">
                    <DropdownWithAction
                      placeholder={
                        foodPreferences.length
                          ? ""
                          : "Manage your food preferences"
                      }
                      label="Manage food preferences"
                      content={foodPreferencesList}
                      setContent={setFoodPreferencesList}
                      isDropdownOpen={isFoodPreferencesDropdownOpen}
                      setIsDropdownOpen={setIsFoodPreferencesDropdownOpen}
                      onFocus={() => {
                        setIsAlergiesDropdownOpen(false);
                      }}
                      alreadyAddedItems={foodPreferencesInModal}
                      setAlreadyAddedItems={setFoodPreferencesInModal}
                      top="105%"
                    />
                    <DropdownWithAction
                      placeholder={
                        allergies.length ? "" : "Manage your allergies"
                      }
                      label="Manage allergies"
                      content={allergiesList}
                      setContent={setAllergiesList}
                      isDropdownOpen={isAlergiesDropdownOpen}
                      setIsDropdownOpen={setIsAlergiesDropdownOpen}
                      onFocus={() => {
                        setIsFoodPreferencesDropdownOpen(false);
                      }}
                      alreadyAddedItems={allergiesInModal}
                      setAlreadyAddedItems={setAllergiesInModal}
                      bottom="105%"
                    />
                  </div>
                  <div className="fullWidth flex spaceBetween items-center mt-32">
                    <Button
                      buttonType="regular"
                      hierarchy="linkColor"
                      size="md"
                      contentCenter
                      onClick={() => setEditModalIsOpen(null)}
                    >
                      Cancel
                    </Button>
                    <Button
                      buttonType="regular"
                      hierarchy="newDesign-primary"
                      size="md"
                      contentCenter
                      disabled={false}
                      asyncOnClick={async () => {
                        setAllergies(allergiesInModal);
                        setFoodPreferences(foodPreferencesInModal);
                        const response = await patchUserSettings({
                          token,
                          body: {
                            fullName: `${userFirstName} ${userLastName}`,
                            location: country?.name,
                            allergies: allergiesInModal.map(
                              (item) => item.name
                            ),
                            foodPreferences: foodPreferencesInModal.map(
                              (item) => item.name
                            ),
                            pronouns: selectedPronoun?.name,
                            timezone: timeZone?.name,
                          },
                        });

                        if (response) {
                          setNotification({
                            isError: false,
                            title: "Personal info updated.",
                          });
                          setEditModalIsOpen(null);
                        }
                      }}
                      newDesignPaddingNone
                    >
                      <p className="px-5">Save</p>
                    </Button>
                  </div>
                </div>
              }
            />,
            document.getElementById("modal") as HTMLElement
          )
        : editModalIsOpen === "location"
        ? createPortal(
            <MyDetailsModal
              title="Location and timezone"
              setIsOpen={() => setEditModalIsOpen(null)}
              content={
                <div className="myDetails-content borderNone boxShadowNone">
                  <div className="myDetails-content-container paddingNone">
                    <CountryDropdown
                      selectedCountry={countryInModal}
                      setSelectedCountry={setCountryInModal}
                    />
                    {timeZone ? (
                      <TimeZoneDropdown
                        selectedTimeZone={timeZoneInModal}
                        setSelectedTimeZone={setTimeZoneInModal}
                      />
                    ) : (
                      <div className="flex flex-column">
                        <p className="color-gray-600 text-xs font-medium">
                          Timezone
                        </p>
                        <p className="color-gray-950 text-lg font-semmediumibold">
                          --
                        </p>
                      </div>
                    )}
                  </div>
                  <div className="fullWidth flex spaceBetween items-center mt-32">
                    <Button
                      buttonType="regular"
                      hierarchy="linkColor"
                      size="md"
                      contentCenter
                      onClick={() => setEditModalIsOpen(null)}
                    >
                      Cancel
                    </Button>
                    <Button
                      buttonType="regular"
                      hierarchy="newDesign-primary"
                      size="md"
                      contentCenter
                      disabled={false}
                      asyncOnClick={async () => {
                        setCountry(countryInModal);
                        setTimeZone(timeZoneInModal);
                        const response = await patchUserSettings({
                          token,
                          body: {
                            fullName: `${userFirstName} ${userLastName}`,
                            location: countryInModal?.name,
                            allergies: allergies.map((item) => item.name),
                            foodPreferences: foodPreferences.map(
                              (item) => item.name
                            ),
                            pronouns: selectedPronoun?.name,
                            timezone: timeZoneInModal?.name,
                          },
                        });

                        if (response) {
                          setNotification({
                            isError: false,
                            title: "Personal info updated.",
                          });
                          setEditModalIsOpen(null);
                        }
                      }}
                      newDesignPaddingNone
                    >
                      <p className="px-5">Save</p>
                    </Button>
                  </div>
                </div>
              }
            />,
            document.getElementById("modal") as HTMLElement
          )
        : null}
      <div className="myDetails">
        <div
          className="myDetails-content"
          style={{ padding: isDesktopSize ? "0 150px" : undefined }}
        >
          <div className="myDetails-content-container">
            <div className={`flex fullWidth items-center spaceBetween`}>
              <h3 className="xs font-semibold">Personal info</h3>
              <Button
                size={"md"}
                hierarchy={"linkColor"}
                buttonType={"regular"}
                onClick={() => setEditModalIsOpen("personal")}
              >
                Edit
              </Button>
            </div>
            <div
              className={`flex spaceBetween gap-32 fullWidth ${
                !isDesktopSize ? "flex-column" : "items-center"
              }`}
            >
              <div className="flex flex-column gap-8 fullWidth min-w-half">
                <SettingValue name="First name" value={userFirstName} />
                <SettingValue name="Email address" value={userEmail} />
              </div>
              <div className="flex flex-column gap-8 fullWidth min-w-half">
                <SettingValue name="Last name" value={userLastName} />
                <SettingValue name="Pronouns" value={selectedPronoun?.name} />
              </div>
            </div>
            {/* <div className={`maxWidth-80 flex fullWidth spaceBetween ${!isDesktopSize ? 'flex-column' : 'items-center'}`}>
              <SettingValue name='Email address' value={userEmail} />
              <SettingValue name='Pronouns' value={selectedPronoun?.name} />
            </div> */}
          </div>
        </div>
      </div>
      <div className="myDetails">
        <div
          className="myDetails-content"
          style={{ padding: isDesktopSize ? "0 150px" : undefined }}
        >
          <div className="myDetails-content-container">
            <div className={`flex fullWidth items-center spaceBetween`}>
              <h3 className="xs font-semibold">Location and timezone</h3>
              <Button
                size={"md"}
                hierarchy={"linkColor"}
                buttonType={"regular"}
                onClick={() => setEditModalIsOpen("location")}
              >
                Edit
              </Button>
            </div>
            <div
              className={`flex gap-32 fullWidth spaceBetween ${
                !isDesktopSize ? "flex-column" : "items-center"
              }`}
            >
              <SettingValue name="Country" value={country?.name} />
              <SettingValue name="Timezone" value={timeZone?.name} />
            </div>
          </div>
        </div>
      </div>
      <div className="myDetails">
        <div
          className="myDetails-content"
          style={{ padding: isDesktopSize ? "0 150px" : undefined }}
        >
          <div className="myDetails-content-container">
            <div className={`flex fullWidth items-center spaceBetween`}>
              <h3 className="xs font-semibold">Food preferences</h3>
              <Button
                size={"md"}
                hierarchy={"linkColor"}
                buttonType={"regular"}
                onClick={() => setEditModalIsOpen("food")}
              >
                Edit
              </Button>
            </div>
            <div
              className={`flex fullWidth gap-32 spaceBetween ${
                !isDesktopSize ? "flex-column" : "items-center"
              }`}
            >
              <SettingValue
                name="Food preferences"
                value={foodPreferences.map((item) => item.name).join(", ")}
              />
              <SettingValue
                name="Allergies"
                value={allergies.map((item) => item.name).join(", ")}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
