import React, { useEffect, useState } from "react";
import closeIcon from "../../assets/images/x-close-primary-sm.svg";
import { Button } from "../Buttons/Button";
import { useQuery } from "@tanstack/react-query";
import { deleteAttendee, fetchAttendeesNetwork } from "../../httpQueries/http";

interface Props {
  invitedAttendeesIds: {
    networkMemberId: number;
    id: number;
  }[];
  onClick: () => void;
  invitedAttendees:
    | {
        deleteId: number;
        accountId: number;
        createdAt: string;
        email: string;
        fullName: string;
        id: number;
        phone: string | undefined;
        updatedAt: string;
        address: string;
        businessName: string;
        title: string;
      }[]
    | undefined;
  setInvitedAttendees: React.Dispatch<
    React.SetStateAction<
      | {
          deleteId: number;
          accountId: number;
          createdAt: string;
          email: string;
          fullName: string;
          id: number;
          phone: string | undefined;
          updatedAt: string;
          address: string;
          businessName: string;
          title: string;
        }[]
      | undefined
    >
  >;
}

export default function ReviewInvitedAttendeesSection({
  invitedAttendeesIds,
  onClick,
  invitedAttendees,
  setInvitedAttendees,
}: Props) {
  const token = localStorage.getItem("token");

  return (
    <div className="reviewDetailsSection fullWidth">
      <div
        className="reviewDetailsSection-content reviewAgendaSection"
        style={{ padding: "0" }}
      >
        <div className="reviewDetailsSection-content-about max-w-full">
          <div className="reviewDetailsSection-content-about-info  max-w-full">
            <div className="reviewAgendaSection-blocks flex-row overflowXScroll  max-w-full">
              {invitedAttendees?.map((attendee) => (
                <div
                  className="reviewAgendaSection-blocks-block reviewAgendaSection-blocks-block-session"
                  key={attendee.id}
                >
                  <p
                    className="reviewAgendaSection-blocks-block-session-title"
                    style={{ whiteSpace: "nowrap" }}
                  >
                    {attendee.fullName}
                  </p>
                  <img
                    src={closeIcon}
                    alt=""
                    className="reviewAgendaSection-blocks-close"
                    onClick={async () => {
                      try {
                        await deleteAttendee({
                          attendeeId: attendee.deleteId,
                          token,
                        });
                        setInvitedAttendees((prevState) =>
                          prevState?.filter(
                            (item) => item.deleteId !== attendee.deleteId
                          )
                        );
                      } catch (error) {
                        console.log(error);
                      }
                    }}
                  />
                </div>
              ))}
            </div>
          </div>
          <Button
            buttonType="regular"
            hierarchy="linkColor"
            size="md"
            paddingNone
            onClick={onClick}
          >
            View detail
          </Button>
        </div>
      </div>
    </div>
  );
}
