interface Props {
  icon: string;
  title: string;
  subtitle: string;
}

export function CustomIcon({ icon, title, subtitle }: Props) {
  return (
    <div className="customIcon">
      <div className="customIcon-icon">
        <img src={icon} alt="" />
      </div>
      <div>
        <h3>{title}</h3>
        <p>{subtitle}</p>
      </div>
    </div>
  );
}
