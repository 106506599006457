import React, { useEffect, useState } from "react";
import { Button } from "../../components/Buttons/Button";
import logo from "../../assets/images/logo-lg-color.svg";
import searchIcon from "../../assets/images/search-lg-gray.svg";
import FeaturedIcon from "../../components/FeaturedIcons/FeaturedIcon";
import arrowLeftIcon from "../../assets/images/arrow-left-lg.svg";
import backgroundIcon from "../../assets/images/bg-notFound.svg";
import { useWindowSize } from "@uidotdev/usehooks";
import { useNavigate } from "react-router-dom";

export default function NotFound() {
  const size = useWindowSize();
  const [sizeIsDesktop, setSizeIsDesktop] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    setSizeIsDesktop(size.width! >= 768);
  }, [size.width]);

  return (
    <div className="notFound">
      <img className="notFound-background" src={backgroundIcon} alt="" />
      <div className="notFound-header">
        <FeaturedIcon icon={searchIcon} type={"gray"} size={"lg"} />
        <div className="notFound-header-info">
          <img src={logo} alt="" className="notFound-header-info-logo" />
          <h1 className={`font-semibold ${sizeIsDesktop ? "xl" : "md"}`}>
            Page not found
          </h1>
        </div>
      </div>
      <div className="notFound-actions">
        <Button
          size="2xl"
          hierarchy="secondaryGray"
          buttonType="regular"
          fullWidth={!sizeIsDesktop}
          contentCenter
          onClick={() => navigate(-1)}
        >
          <div style={{ display: "flex", gap: "12px" }}>
            <img src={arrowLeftIcon} alt="arrow left" />
            <p>Go back</p>
          </div>
        </Button>
        <Button
          size="2xl"
          hierarchy="primary"
          buttonType="regular"
          fullWidth={!sizeIsDesktop}
          contentCenter
          onClick={() => navigate("/")}
        >
          Take me home
        </Button>
      </div>
    </div>
  );
}
