import React from "react";
import flagIcon from '../../assets/images/flag-02-primary.svg';
import { useNavigate } from "react-router-dom";
import { Button } from "../../components/Buttons/Button";

export default function EmptyPage({ isOrganizer }: { isOrganizer: boolean }) {
  const navigate = useNavigate();

  return (
    <div className="eventsEmptyPage flex-column items-center justifyCenter">
      <div className="eventsEmptyPage-icon">
        <img src={flagIcon} alt="" />
      </div>
      <h3 className="eventsEmptyPage-info-title">
        Events I'm {isOrganizer ? "organizing" : "Attending"}
      </h3>
      <p className="eventsEmptyPage-info-description">
        {isOrganizer
          ? "This is where you will see all the events you create, start by creating your first event!"
          : "This is where you will see all the events you get invited to, wile you wait for that you could create your first event."}
      </p>
      <div style={{ height: "fit-content" }}>
        <Button
          buttonType="regular"
          hierarchy="newDesign-primary"
          newDesignPaddingNone
          size="md"
          onClick={() => navigate("/createEvent")}
        >
          Create event
        </Button>
      </div>
    </div>
  );
}
