import React from "react";
import ReactDOM from 'react-dom/client';
import './scss/main.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { Provider as ReduxProvider } from "react-redux";
import { store } from "./store";
import { QueryClientProvider } from "@tanstack/react-query";
import { queryClient } from "./httpQueries/http";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { NotificationContext } from "./utilities/NotificationContext";
import { RSVPEventValueContext } from "./utilities/RSVPEventContext";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <BrowserRouter>
    {/* <React.StrictMode> */}
      <QueryClientProvider client={queryClient}>
          <ReduxProvider store={store}>
            <NotificationContext>
              <RSVPEventValueContext>
                <App />
              </RSVPEventValueContext>
            </NotificationContext>
          </ReduxProvider>
        <ReactQueryDevtools />
      </QueryClientProvider>
    {/* </React.StrictMode> */}
  </BrowserRouter>
);
reportWebVitals();
