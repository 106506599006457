import React, { useState } from "react";
import { Button } from "../../../components/Buttons/Button";
import { FormInput } from "../../../components/Inputs/FormInput";
import { supabase } from "../../../utilities/supabaseClient";
import { useSelector } from "react-redux";
import { currentUserSelector } from "../../../store/currentUser";
import { updatePassword } from "../../../httpQueries/http";

interface Props {
  setNotification: React.Dispatch<
    React.SetStateAction<{
      isError: boolean;
      title: string;
    } | null>
  >;
  isDesktopSize?: boolean;
}

export default function MyPassword({ setNotification, isDesktopSize }: Props) {
  const token = localStorage.getItem("token");
  const currentUser = useSelector(currentUserSelector);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPasswordFirstTry, setNewPasswordFirstTry] = useState("");
  const [newPasswordSecondTry, setNewPasswordSecondTry] = useState("");
  const [currentPasswordInputError, setCurrentPasswordInputError] =
    useState("");
  const [newPasswordFirstTryInputError, setNewPasswordFirstTryInputError] =
    useState("");
  const [newPasswordSecondTryInputError, setNewPasswordSecondTryInputError] =
    useState("");

  const onUpdatePassword = async () => {
    if (!newPasswordFirstTry.length) {
      setNewPasswordFirstTryInputError("Please enter a valid password");
    }

    if (!currentPassword.length) {
      setCurrentPasswordInputError("Please enter a valid password");
    }

    if (!newPasswordSecondTry.length) {
      setNewPasswordSecondTryInputError("Please enter a valid password");
    }

    if (newPasswordFirstTry !== newPasswordSecondTry) {
      setNewPasswordSecondTryInputError("Passwords must match");
    }

    if (
      currentPassword.length &&
      newPasswordFirstTry.length &&
      newPasswordSecondTry.length &&
      newPasswordFirstTry === newPasswordSecondTry
    ) {
      const { data: loginResponse, error } =
        await supabase().auth.signInWithPassword({
          email: currentUser.email,
          password: currentPassword,
        });

      if (error) {
        setCurrentPasswordInputError("Invalid password");

        return;
      }

      if (loginResponse.user) {
        const updatePasswordResponse = await updatePassword({
          token,
          body: { password: newPasswordFirstTry },
        });

        if (
          updatePasswordResponse.status >= 200 &&
          updatePasswordResponse.status < 300
        ) {
          setNotification({ isError: false, title: "Password updated." });
        }

        setCurrentPassword("");
        setNewPasswordFirstTry("");
        setNewPasswordSecondTry("");
        setCurrentPasswordInputError("");
        setNewPasswordFirstTryInputError("");
        setNewPasswordSecondTryInputError("");
      }
    }
  };

  return (
    <div className="myDetails">
      <div
        className="myDetails-content"
        style={{ padding: isDesktopSize ? "0 150px" : undefined }}
      >
        <div className="myDetails-content-container">
          <h3 className="xs font-semibold">Change password</h3>
          <FormInput
            label="Current password"
            type="Password"
            value={currentPassword}
            onChange={(e) => {
              setCurrentPassword(e.target.value);
            }}
            errorMessage={currentPasswordInputError}
            isError={!!currentPasswordInputError.length}
            design="new"
          />
          <FormInput
            label="New password"
            type="Password"
            value={newPasswordFirstTry}
            onChange={(e) => {
              setNewPasswordFirstTry(e.target.value);
            }}
            errorMessage={newPasswordFirstTryInputError}
            isError={!!newPasswordFirstTryInputError.length}
            design="new"
          />
          <FormInput
            label="Confirm new password"
            type="Password"
            value={newPasswordSecondTry}
            onChange={(e) => {
              setNewPasswordSecondTry(e.target.value);
            }}
            errorMessage={newPasswordSecondTryInputError}
            isError={!!newPasswordSecondTryInputError.length}
            design="new"
          />
        </div>
        <div className="myDetails-footer" style={{ border: "none" }}>
          <Button
            buttonType="regular"
            hierarchy="newDesign-primary"
            size="md"
            contentCenter
            asyncOnClick={onUpdatePassword}
            newDesignPaddingNone
          >
            Update password
          </Button>
        </div>
      </div>
    </div>
  );
}
