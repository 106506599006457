import React from "react";

interface Props {
  categories: {
    name: string;
    items: number;
    isSelected: boolean;
    onSelect: () => void;
  }[];
}

export default function CategoryTabs({ categories }: Props) {
  return (
    <div className="categoryTabs overflowYScroll">
      {categories.map((category) => (
        <div
          className={`categoryTabs-tab ${
            category.isSelected ? "categoryTabs-tab-selected" : ""
          }`}
          onClick={category.onSelect}
          key={category.name}
        >
          <h6
            className={`categoryTabs-tab-name ${
              category.isSelected ? "categoryTabs-tab-name-selected" : ""
            }`}
          >
            {category.name}
          </h6>
          <p
            className={`categoryTabs-tab-description ${
              category.isSelected ? "categoryTabs-tab-description-selected" : ""
            }`}
          >
            {category.items} items
          </p>
        </div>
      ))}
    </div>
  );
}
