import React, { useState } from "react";
import logo from "../../assets/images/logo-lg-color.svg";
import mailIcon from "../../assets/images/mail-01-black.svg";
import { Button } from "../../components/Buttons/Button";
import FeaturedIcon from "../../components/FeaturedIcons/FeaturedIcon";
import leftArrow from "../../assets/images/arrow-left.svg";
import VerifyCode from "../../components/VerifyCode/VerifyCode";
import bgIcon from "../../assets/images/contentAccountCreatedBg.svg";
import { supabase } from "../../utilities/supabaseClient";
import { useNavigate } from "react-router-dom";

export default function VerifyEmail({ email }: { email: string }) {
  const [firstDigit, setFirstDigit] = useState<string>("");
  const [secondDigit, setSecondDigit] = useState<string>("");
  const [thirdDigit, setThirdDigit] = useState<string>("");
  const [fourthDigit, setFourthDigit] = useState<string>("");
  const [fifthDigit, setFifthDigit] = useState<string>("");
  const [sixthDigit, setSixthDigit] = useState<string>("");
  const [isError, setIsError] = useState(false);
  const navigate = useNavigate();

  const onVerify = async () => {
    const {
      data: { session },
      error,
    } = await supabase().auth.verifyOtp({
      email,
      token: `${firstDigit}${secondDigit}${thirdDigit}${fourthDigit}${fifthDigit}${sixthDigit}`,
      type: "email",
    });

    if (error) {
      setIsError(true);
      return;
    }

    if (session) {
      localStorage.setItem("token", session.access_token);
      localStorage.setItem("lastTokenSetTime", new Date().toISOString());
      navigate("/createNewPassword");
    }
  };

  return (
    <div className="accountCreated">
      <header className="accountCreated-header">
        <img src={logo} alt="" className="accountCreated-header-logo" />
      </header>
      <section className="accountCreated-content">
        <img src={bgIcon} alt="" className="accountCreated-content-image" />
        <div className="accountCreated-content-content">
          <div className="flex flex-column gap-24 items-center">
            <FeaturedIcon icon={mailIcon} size="lg" type="gray" />
            <div className="flex flex-column items-center">
              <h3 className="accountCreated-content-content-title">
                Check your email
              </h3>
              <div className="flex flex-wrap gap-4 items-center justifyCenter">
                <p className="accountCreated-content-content-description">
                  We sent a password reset link to
                </p>
                <p className="accountCreated-content-content-medium">{email}</p>
              </div>
            </div>
          </div>
          <VerifyCode
            fifthDigit={fifthDigit}
            firstDigit={firstDigit}
            fourthDigit={fourthDigit}
            secondDigit={secondDigit}
            thirdDigit={thirdDigit}
            sixthDigit={sixthDigit}
            setFifthDigit={setFifthDigit}
            setFourthDigit={setFourthDigit}
            setFirstDigit={setFirstDigit}
            setSecondDigit={setSecondDigit}
            setSixthDigit={setSixthDigit}
            setThirdDigit={setThirdDigit}
            isError={isError}
          />
          <div className="verifyCode-button">
            <Button
              buttonType="regular"
              hierarchy="primary"
              size="lg"
              fullWidth
              contentCenter
              asyncOnClick={onVerify}
            >
              Verify email
            </Button>
          </div>
          <div className="flex gap-4 items-center">
            <p className="login-signIn-copyright">Didn’t receive the email?</p>
            <Button
              buttonType="regular"
              hierarchy="linkColor"
              size="md"
              paddingNone
            >
              Click to resend
            </Button>
          </div>
          <Button
            buttonType="regular"
            hierarchy="linkGray"
            size="md"
            onClick={() => navigate("/login")}
          >
            <div className="flex gap-8 items-center">
              <img src={leftArrow} alt="" />
              <p>Back to Log in</p>
            </div>
          </Button>
        </div>
      </section>
    </div>
  );
}
