import React from "react";
import checkIcon from "../../../assets/images/check-white.svg";

interface Props {
  id: string;
  name: string;
  status: boolean;
  location: string;
  distance: number;
  cuisines: string[];
  image: string;
  setSelectedRestaurant: React.Dispatch<
    React.SetStateAction<{
      id: string | number;
      name: string;
      logo: string;
      status?: "Open" | "Close";
      address: string;
      distance?: number;
      cuisines?: string[];
    } | null>
  >;
  onRestaurantSelect: () => Promise<any>;
  selectedRestaurantId: string | number | undefined;
  agendaBlockId: number;
  restaurantsForOrders: {
    id: string | number;
    name: string;
    status?: "Open" | "Close" | undefined;
    logo: string;
    address: string;
    distance?: number | undefined;
    cuisines?: string[] | undefined;
    agendaBlockId: number;
  }[];
  setRestaurantsForOrders: React.Dispatch<
    React.SetStateAction<
      {
        id: string | number;
        name: string;
        status?: "Open" | "Close" | undefined;
        logo: string;
        address: string;
        distance?: number | undefined;
        cuisines?: string[] | undefined;
        agendaBlockId: number;
      }[]
    >
  >;
}

export default function RestaurantItem({
  id,
  name,
  status,
  location,
  distance,
  cuisines,
  image,
  selectedRestaurantId,
  setSelectedRestaurant,
  restaurantsForOrders,
  agendaBlockId,
  setRestaurantsForOrders,
  onRestaurantSelect,
}: Props) {
  return (
    <div
      className={`
        restaurantItem
        ${
          restaurantsForOrders?.find(
            (restaurant) => restaurant.agendaBlockId === agendaBlockId
          )?.id === id
            ? "restaurantItem-selected"
            : ""
        }
      `}
      onClick={() => {
        onRestaurantSelect().then(() => {
          setSelectedRestaurant({
            id,
            name,
            status: status ? "Open" : "Close",
            logo: image,
            address: location,
            cuisines,
            distance,
          });
          setRestaurantsForOrders((prevState) => {
            return [
              ...prevState.filter(
                (item) => item.agendaBlockId !== agendaBlockId
              ),
              {
                id,
                name,
                status: status ? "Open" : "Close",
                logo: image,
                address: location,
                cuisines,
                distance,
                agendaBlockId: agendaBlockId,
              },
            ];
          });
        });
      }}
    >
      <img className="restaurantItem-logo" src={image} alt="restaurant logo" />
      <div className="restaurantItem-content">
        <h3 className="restaurantItem-name">{name}</h3>
        <p className="restaurantItem-info">
          {status ? "Open" : "Close"} • {location}
        </p>
        <p className="restaurantItem-info">{distance.toFixed(1)}mi</p>
      </div>
      {selectedRestaurantId === id ? (
        <img
          src={checkIcon}
          alt=""
          className="fileUploadBase-downloaded restaurantItem-selected-icon"
        />
      ) : null}
    </div>
  );
}
