import searchIcon from "../../assets/images/search-lg.svg";
import searchIconGray from "../../assets/images/search-lg-gray.svg";
import helpIcon from "../../assets/images/help-circle.svg";
import errorIcon from "../../assets/images/alert-circle.svg";
import searchIconGrayLight from "../../assets/images/search-lg-gray-300.svg";
import closeIcon from '../../assets/images/x-close-black.svg';

interface Props {
  type: "primary" | "gray" | "dark";
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  isError?: boolean;
  hasHelpIcon?: boolean;
  value?: string;
  border?: "full" | "left" | "right" | "bottom" | "top" | "none";
  hasIconBefore?: string | boolean;
  fullWidth?: boolean;
  setShow?: () => void;
  onFocus?: () => void;
  design: "new" | "old";
  placeholder?: string;
  hasClearOption?: () => void,
}

export function SearchInput({
  type,
  onChange,
  isError,
  onFocus,
  hasHelpIcon,
  placeholder,
  value,
  border,
  hasIconBefore,
  fullWidth,
  setShow,
  design,
  hasClearOption
}: Props) {
  return (
    <div
      autoFocus
      onBlur={setShow}
      onFocus={(e) => {
        e.preventDefault();
        e.stopPropagation();
        onFocus && onFocus();
      }}
      className={`
        ${design === "new" ? "inputNew inputNew-filled" : "input"}
        ${isError && design === "old" ? "input-error" : ""} 
        ${type === "primary" ? "input-search-primary" : ""}
        ${type === "gray" ? "input-search-gray" : ""}
        ${type === "dark" ? "input-search-dark" : ""}
        ${border !== "full" ? "border-input" : ""}
        ${border !== "full" && border === "right" ? "border-input-right" : ""}
        ${border !== "full" && border === "left" ? "border-input-left" : ""}
        ${border !== "full" && border === "top" ? "border-input-top" : ""}
        ${border !== "full" && border === "bottom" ? "border-input-bottom" : ""}
        ${fullWidth ? "fullWidth" : ""}
      `}
      tabIndex={0}
    >
      <div
        className={`inputWrapper  ${value?.length ? "inputWrapper-filled" : ""}`}
      >
        {hasIconBefore ? (
          <img
            src={
              typeof hasIconBefore === 'string' && hasIconBefore.length
                ? hasIconBefore
                : type === "primary"
                  ? searchIcon
                  : type === "gray"
                    ? searchIconGray
                    : searchIconGrayLight
            }
            alt="search icon"
          />
        ) : null}
        <input
          type="text"
          className="text-md"
          onChange={onChange}
          value={value}
          tabIndex={1}
          placeholder={placeholder}
        />
        {
          hasClearOption
            ? <img
                src={closeIcon}
                alt="clear icon"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();

                  hasClearOption();
                }}
                className="cursorPointer"
              />
            : null
        }
      </div>
      {hasHelpIcon && design === "old" ? (
        <img src={isError ? errorIcon : helpIcon} alt="help" />
      ) : null}
    </div>
  );
}
