import React from "react";
import logo from "../../assets/images/logo-lg-color.svg";
import checkBlack from "../../assets/images/check-circle-black.svg";
import bgIcon from "../../assets/images/contentAccountCreatedBg.svg";
import leftArrow from "../../assets/images/arrow-left.svg";
import FeaturedIcon from "../../components/FeaturedIcons/FeaturedIcon";
import { Button } from "../../components/Buttons/Button";
import { useNavigate } from "react-router-dom";

interface Props {
  setIsAccountCreated: React.Dispatch<React.SetStateAction<boolean>>;
  email: string;
}

export default function AccountCreated({ email, setIsAccountCreated }: Props) {
  const navigate = useNavigate();

  return (
    <div className="accountCreated">
      <header className="accountCreated-header">
        <img src={logo} alt="" className="accountCreated-header-logo" />
      </header>
      <section className="accountCreated-content">
        <img src={bgIcon} alt="" className="accountCreated-content-image" />
        <div className="accountCreated-content-content">
          <div className="flex flex-column gap-24 items-center">
            <FeaturedIcon icon={checkBlack} size="lg" type="gray" />
            <div className="flex flex-column items-center">
              <h3 className="accountCreated-content-content-title">
                Account created
              </h3>
              <div className="flex gap-4 items-center">
                <p className="accountCreated-content-content-description">
                  We sent a link to
                </p>
                <p className="accountCreated-content-content-email">{email}</p>
              </div>
            </div>
          </div>
          <Button
            buttonType="regular"
            hierarchy="primary"
            size="lg"
            fullWidth
            contentCenter
            onClick={() => navigate("/login")}
          >
            Continue
          </Button>
          <Button
            buttonType="regular"
            hierarchy="linkGray"
            size="md"
            onClick={() => setIsAccountCreated(false)}
          >
            <div className="flex gap-8 items-center">
              <img src={leftArrow} alt="" />
              <p>Back to Sign up</p>
            </div>
          </Button>
        </div>
      </section>
    </div>
  );
}
