import React, { useContext, useEffect, useState } from "react";
import { EventInfoContext } from "../../../utilities/eventInfoContext";
import { useNavigate, useParams } from "react-router-dom";
import ReviewAgendaSection from "../../../components/Sections/ReviewAgendaSection";
import { Button } from "../../../components/Buttons/Button";
import ModalSaveAsDraft from "../../../components/Modal/ModalSaveAsDraft";
import closeIcon from "../../../assets/images/x-close-black.svg";
import ModalNotification from "../../../components/Modal/ModalNotification";
import { createPortal } from "react-dom";
import ModalEventWasCreated from "../../../components/Modal/ModalEventWasCreated";
import HorizontalTabs from "../../../components/Tabs/HorizontalTabs";
import LogoRepeat from "../../../components/LogoRepeat/LogoRepeat";
import ModalCreateEventLogo from "../../../components/Modal/ModalCreateEventLogo";
import presentationIcon from "../../../assets/images/presentation-chart-01.svg";
import clockPlusIcon from "../../../assets/images/clock-plus.svg";
import happyFaceIcon from "../../../assets/images/face-happy.svg";
import {
  deleteAttendee,
  editEventLogo,
  fetchEvent,
  fetchEventAgenda,
  fetchInvitedAttendees,
  publishEvent,
} from "../../../httpQueries/http";
import pathIcon from "../../../assets/images/path90.svg";
import { logoColor } from "../../../types/logoColor";
import { logoImage } from "../../../types/logoImage";
import { getLogoRepeatImage } from "../../../utilities/getLogoRepeatImage";
import { supabase } from "../../../utilities/supabaseClient";
import AttachDocumentModal from "../../../components/Modal/AttachDocumentModal";
import { DropdownMenu } from "../../../components/Dropdown/DropdownMenu";
import linkIcon from "../../../assets/images/link-03.svg";
import uploadCloudIcon from "../../../assets/images/upload-cloud-02-black.svg";
import AttachLinkModal from "../../../components/Modal/AttachLinkModal";
import { AttendeeRSVP } from "../../../types/attendeeRSVP";
import { AgendaBlockType } from "../../../types/agendaBlockType";
import moment from "moment";
import { getUTCTimeFromMinutes } from "../../../utilities/getUTCTimeFromMinutes";
import { editTime } from "../../../utilities/EditAgendaBlockTime";
import { durations, smallDurations } from "../../../utilities/agendaDurationsArray";
import EventReviewBlockV2 from "../../../components/EventBlock/EventReviewBlockV2";
import { getTimeDiffence } from "../../../utilities/getTimeDifference";
import { getTimeInMinutes } from "../../../utilities/getTimeInMinutes";
import arrowLeft from "../../../assets/images/arrow-narrow-left.svg";
import fileAttachment from "../../../assets/images/file-attachment-05.svg";
import UserInvitesTable from "../../../components/Tables/UserInvitesTable";
import pdfIcon from "../../../assets/images/fileTypeIconPdf.svg";
import { getEventTime } from "../../../utilities/getEventStartTime";
import Document from "../../../components/Document/Document";
import fileTypeIcon from "../../../assets/images/file_type_icon_link.svg";
import { editSubBlockTime } from "../../../utilities/editSubBlockTime";

interface Props {
  isDesktopSize: boolean;
  setInvitedAttendees: React.Dispatch<
    React.SetStateAction<{
      attendees: {
        id: number;
        networkMemberId: number;
        status: "added";
        rsvp: AttendeeRSVP | null;
        networkMember: {
          id: number;
          fullName: string;
          email: string;
          phone: string;
          businessName: string;
          address: string;
          title: string;
          userId: null | number;
          accountId: number;
          createdAt: string;
          updatedAt: string;
          user: {
            accountId: number;
            createdAt: string;
            email: string;
            fullName: string;
            id: number;
            phone?: string | null;
            status: string;
            supabaseId: string;
            updatedAt: string;
          } | null;
        };
      }[];
      count: number;
    }>
  >;
  isEdit?: boolean;
  setAttachedDocumentsCount: React.Dispatch<React.SetStateAction<number>>;
  setSelectedStartTime: React.Dispatch<
    React.SetStateAction<{
      id: number;
      name: string;
    } | null>
  >;
  setSelectedEndTime: React.Dispatch<
    React.SetStateAction<{
      id: number;
      name: string;
    } | null>
  >;
  setFirstDate: React.Dispatch<React.SetStateAction<moment.Moment | null>>;
  setDescriptionInputValue: React.Dispatch<React.SetStateAction<string>>;
  setNameInputValue: React.Dispatch<React.SetStateAction<string>>;
  setEventIds: React.Dispatch<
    React.SetStateAction<{
      eventId: number;
      eventDayId: number;
    } | null>
  >;
  setSelectedLocation: React.Dispatch<
    React.SetStateAction<{
      id: number;
      name: string;
      supportText?: string | undefined;
      lat?: string;
      lng?: string;
    } | null>
  >;
  setLocationNotes: React.Dispatch<React.SetStateAction<string>>;
  setSelectedZone: React.Dispatch<
    React.SetStateAction<{
      id: number;
      name: string;
      timezone?: string;
    } | null>
  >;
  setAgenda: React.Dispatch<
    React.SetStateAction<
      {
        id: string;
        icon: string;
        name: string;
        description: string;
        duration: {
          id: number;
          name: string;
          value?: number | undefined;
        };
        startTime: string;
        endTime: string;
        type: AgendaBlockType;
        index: number;
      }[]
    >
  >;
  setCurrentLastStep: React.Dispatch<React.SetStateAction<number>>;
  currentLastStep: number;
  eventSlug: string;
  setSlug: React.Dispatch<React.SetStateAction<string>>;
  invitedAttendees: {
    attendees: {
      id: number;
      networkMemberId: number;
      status: "added";
      rsvp: AttendeeRSVP | null;
      networkMember: {
        id: number;
        fullName: string;
        email: string;
        phone: string;
        businessName: string;
        address: string;
        title: string;
        userId: null | number;
        accountId: number;
        createdAt: string;
        updatedAt: string;
        user: {
          accountId: number;
          createdAt: string;
          email: string;
          fullName: string;
          id: number;
          phone?: string | null;
          status: string;
          supabaseId: string;
          updatedAt: string;
        } | null;
      };
    }[];
    count: number;
  };
  setSubBlocks: React.Dispatch<
    React.SetStateAction<
      {
        id: string;
        name: string;
        startTime: string;
        endTime: string;
        duration: {
          id: number;
          name: string;
          value?: number | undefined;
        } | null;
        index: number;
        agendaItemId: string;
      }[]
    >
  >;
  subBlocks: {
    id: string;
    name: string;
    startTime: string;
    endTime: string;
    duration: {
      id: number;
      name: string;
      value?: number | undefined;
    } | null;
    index: number;
    agendaItemId: string;
  }[];
}

export default function EventReviewAndSubmit({
  isDesktopSize,
  isEdit,
  setAttachedDocumentsCount,
  setAgenda,
  setDescriptionInputValue,
  setEventIds,
  setFirstDate,
  setLocationNotes,
  setNameInputValue,
  setSelectedEndTime,
  setSelectedLocation,
  setSelectedStartTime,
  setSelectedZone,
  setCurrentLastStep,
  currentLastStep,
  setSlug,
  invitedAttendees,
  setInvitedAttendees,
  eventSlug,
  subBlocks,
  setSubBlocks
}: Props) {
  const currentStep = 4;
  const {
    agenda,
    eventDate,
    eventDescription,
    eventName,
    location,
    startTime,
    endTime,
    eventIds,
    attachedDocumentsCount,
    timeZone,
  } = useContext(EventInfoContext);
  const [isSavedAsDraft, setIsSavedAsDraft] = useState(false);
  const [modalNotificationIsOpen, setModalNotificationIsOpen] = useState<{
    name: string;
    description: string;
    firstButtonName: string;
    secondButtonName: string;
    onFirstButtonClick: () => void;
    onSecondButtonClick: () => void;
  } | null>(null);
  const [modalEventWasCreatedIsOpen, setModalEventWasCreatedIsOpen] =
    useState(false);
  const [currentTab, setCurrentTab] = useState<{
    id: number;
    name: string;
  } | null>({ id: 1, name: "Overview" });
  const [isEditLogoModalOpen, setIsEditLogoModalOpen] = useState(false);
  const [eventLogo, setEventLogo] = useState<logoImage>(logoImage.PATH);
  const [eventLogoColor, setEventLogoColor] = useState<logoColor>(
    logoColor.BLUE
  );
  const [isAttachDocumentModalOpen, setIsAttachDocumentModalOpen] =
    useState(false);
  const token = localStorage.getItem("token");
  const slug = useParams().slug;
  const [selectedFileModal, setSelectedFileModal] = useState<{
    id: number;
    name: string;
    img?: string;
  } | null>(null);
  const [isSelectFileModalOpen, setIsSelectFileModalOpen] =
    useState<boolean>(false);
  const [isPublished, setIsPublished] = useState(false);
  const [selectedAttendees, setSelectedAttendees] = useState<
    { id: number; name: string; supportText?: string }[]
  >([]);
  const navigate = useNavigate();
  const today = new Date();
  const [documents, setDocuments] = useState<
    { name: string; url: string; id?: number }[]
  >([]);
  const [links, setLinks] = useState<{ url: string; id: number }[]>([]);

  async function loadEditableEventData() {
    const data = await fetchEvent({ slug, token });
    return data;
  }

  useEffect(() => {
    if (isEdit) {
      loadEditableEventData().then((data) => {
        const docs =
          data?.documents.map((item) => ({
            name: item.originalFileName,
            url: item.url || "",
            id: item.id,
          })) || [];
        setDocuments(docs);
        setFirstDate(moment(data!.startDate));
        setDescriptionInputValue(data!.description);
        setNameInputValue(data!.name);
        setSelectedEndTime(
          data?.endTime
            ? { id: 1, name: getUTCTimeFromMinutes(data!.endTime) }
            : null
        );
        setSelectedLocation({
          id: 1,
          name: data!.location,
          supportText: "",
          lat: data?.locationLatitude ? data.locationLatitude : "0",
          lng: data?.locationLongitude ? data.locationLongitude : "0",
        });
        setLocationNotes(data?.locationNote || "");
        setSelectedStartTime({
          id: 1,
          name: getUTCTimeFromMinutes(data!.startTime),
        });
        setSelectedZone(
          data?.timezone
            ? { id: 1, name: data.timezone, timezone: data.timezoneName }
            : null
        );
        setEventIds({ eventId: data!.id, eventDayId: data!.days[0].id });
        setSlug(data!.slug);
      });
    }
  }, [isEdit]);

  useEffect(() => {
    if (slug) {
      fetchEvent({ slug, token }).then((response) => {
        const startTime = getEventTime(
          response!.startTime,
          response?.timezoneName
        );
        const endTime = response?.endTime
          ? getEventTime(response!.endTime, response.timezoneName)
          : null;

        if (response) {
          fetchEventAgenda({ dayId: response!.id, token }).then((res) => {
            const agenda = editTime(
              res!
                .sort((it1, it2) => it1.index - it2.index)
                .map((item, index) => ({
                  id: `${item.id}`,
                  icon:
                    item.type === AgendaBlockType.Break
                      ? clockPlusIcon
                      : item.type === AgendaBlockType.Meal
                      ? happyFaceIcon
                      : presentationIcon,
                  name: item.name,
                  description: item.description,
                  duration: durations.find(
                    (duration) => duration.value === item.duration
                  ) || {
                    id: 1,
                    name: `${item.duration} min`,
                    value: item.duration,
                  },
                  startTime: getUTCTimeFromMinutes(response!.startTime),
                  endTime: response?.endTime
                    ? getUTCTimeFromMinutes(response!.endTime)
                    : "",
                  type: item.type,
                  index,
                })),
              getEventTime(response!.startTime, timeZone?.timezone)?.name ?? ""
            )!;
            setAgenda(agenda);
            const agendaSubBlocks: {
              id: string;
              name: string;
              startTime: string;
              endTime: string;
              duration: {
                  id: number;
                  name: string;
                  value?: number | undefined;
              } | null;
              index: number;
              agendaItemId: string;
            }[] = [];
      
            res?.map(block => agendaSubBlocks.push(...block.sections?.map(section => ({
              id: `${section.id}`,
              name: section.name,
              startTime: '',
              endTime: '',
              duration: smallDurations.find(duration => duration.value === section.duration) || null,
              index: 0,
              agendaItemId: `${block.id}`,
            })) || []));
      
            setSubBlocks(editSubBlockTime(agendaSubBlocks, getEventTime(response!.startTime, response?.timezoneName)?.name || null, agenda, setSubBlocks) || []);
          });
          setIsPublished(response.isPublished);
          setEventLogo(response.pattern || logoImage.PATH);
          setEventLogoColor(response.color || logoColor.BLUE);
          setFirstDate(moment(response!.startDate));
          setDescriptionInputValue(response!.description);
          setNameInputValue(response!.name);
          setSelectedEndTime(endTime || null);
          setSelectedLocation({
            id: 1,
            name: response!.location,
            supportText: "",
          });
          setLocationNotes(response?.locationNote || "");
          setSelectedStartTime(startTime!);
          setSelectedZone(
            response?.timezone ? { id: 1, name: response.timezone } : null
          );
          setEventIds({
            eventId: response!.id,
            eventDayId: response!.days[0].id,
          });
          setAttachedDocumentsCount(response.documents.length);
        }
      });
    } else {
      setEventLogo(logoImage.PATH);
      setEventLogoColor(logoColor.BLUE);
      setAttachedDocumentsCount(0);
    }
  }, [slug]);

  useEffect(() => {
    if (
      (!eventDate ||
        !agenda.length ||
        !eventDescription ||
        !eventName ||
        !location ||
        !startTime) &&
      !isEdit
    ) {
      navigate("/");
    }

    if (currentLastStep < currentStep) {
      setCurrentLastStep(currentStep);
    }
  }, []);

  useEffect(() => {
    if (isSavedAsDraft === true) {
      setTimeout(() => setIsSavedAsDraft(false), 5000);
    }
  }, [isSavedAsDraft]);

  if (
    !eventDate ||
    !agenda.length ||
    !eventDescription ||
    !eventName ||
    !location ||
    !startTime ||
    !eventIds
  ) {
    return null;
  }

  if (!token?.length) {
    supabase().auth.signOut();
    localStorage.clear();
    localStorage.clear();

    navigate("/login");

    return null;
  }

  const onDeleteSelected = async () => {
    await Promise.all(
      selectedAttendees.map(async (item) => {
        setSelectedAttendees((prevState) =>
          prevState.filter((it) => it !== item)
        );
        await deleteAttendee({ attendeeId: item.id, token });
      })
    );

    fetchInvitedAttendees({
      token,
      eventId: eventIds?.eventId,
      skip: 0,
      take: 20,
    }).then((response) => {
      if (response) {
        setInvitedAttendees(response);
      }
    });
  };

  const onDeleteAttendee = async (item: {
    id: number;
    name: string;
    supportText?: string;
  }) => {
    setSelectedAttendees((prevState) => prevState.filter((it) => it !== item));
    await deleteAttendee({ attendeeId: item.id, token });

    fetchInvitedAttendees({
      token,
      eventId: eventIds?.eventId,
      skip: 0,
      take: 20,
    }).then((response) => {
      if (response) {
        setInvitedAttendees(response);
      }
    });
  };

  return (
    <div className="fullHeight fullWidth flex flex-column gap-24">
      <div className="eventDetails_main-header eventDetails_main-header-absolute">
        <h2 className="color-gray-900 text-xl font-semibold">Create event</h2>
        <img
          src={closeIcon}
          alt=""
          onClick={() => navigate("/events?filter=organizer")}
        />
      </div>
      <div className="overflowYScroll eventDetails_content-absolute">
        <div
          className={`eventDetails_main eventDetails_main-createEvent ${
            isDesktopSize ? "paddingNone" : ""
          } overflowVisible h-fit`}
        >
          {modalNotificationIsOpen
            ? createPortal(
                <ModalNotification
                  setIsOpen={setModalNotificationIsOpen}
                  name={modalNotificationIsOpen.name}
                  description={modalNotificationIsOpen.description}
                  onFirstButton={modalNotificationIsOpen.onFirstButtonClick}
                  onSecondButton={modalNotificationIsOpen.onSecondButtonClick}
                  firstButtonName={modalNotificationIsOpen.firstButtonName}
                  secondButtonName={modalNotificationIsOpen.secondButtonName}
                />,
                document.getElementById("modal") as HTMLElement
              )
            : null}
          {modalEventWasCreatedIsOpen
            ? createPortal(
                <ModalEventWasCreated
                  setIsOpen={setModalEventWasCreatedIsOpen}
                />,
                document.getElementById("modal") as HTMLElement
              )
            : null}
          {isAttachDocumentModalOpen && selectedFileModal
            ? selectedFileModal.name === "Upload files"
              ? createPortal(
                  <AttachDocumentModal
                    setDocuments={setDocuments}
                    widthIsMobile={!isDesktopSize}
                    setSelectedFileModal={setSelectedFileModal}
                    attachedFilesCount={attachedDocumentsCount}
                    setShow={setIsAttachDocumentModalOpen}
                    eventId={eventIds.eventId}
                  />,
                  document.getElementById("modal") as HTMLElement
                )
              : createPortal(
                  <AttachLinkModal
                    setSelectedLinks={setLinks}
                    setSelectedFileModal={setSelectedFileModal}
                    setShow={setIsAttachDocumentModalOpen}
                    eventId={eventIds.eventId}
                  />,
                  document.getElementById("modal") as HTMLElement
                )
            : null}
          {isEditLogoModalOpen
            ? createPortal(
                <ModalCreateEventLogo
                  onSave={(color: logoColor, image: logoImage) => {
                    setEventLogo(image);
                    setEventLogoColor(color);
                    editEventLogo({
                      color,
                      pattern: image,
                      token,
                      id: eventIds.eventId,
                      isOrganizer: true,
                    });
                  }}
                  setIsOpen={setIsEditLogoModalOpen}
                />,
                document.getElementById("modal") as HTMLElement
              )
            : null}
          {isSavedAsDraft ? (
            <ModalSaveAsDraft setIsOpen={setIsSavedAsDraft} />
          ) : null}
          <div className="eventDetails_main reviewEventDetailsPage gap-12 overflowVisible">
            <div
              className="flex gap-12 items-center"
              style={{ paddingBottom: "12px" }}
            >
              <img
                className="selectRestaurant-main-info-backButton"
                src={arrowLeft}
                alt=""
                onClick={() => navigate(`/edit/${eventSlug}/inviteAttendees`)}
              />
              <h3 className="xs font-semibold color-gray-900">
                Review and submit
              </h3>
            </div>
            <header className="reviewEventDetailsPage-header gap-12">
              <LogoRepeat
                color={eventLogoColor}
                image={
                  eventLogo === logoImage.PATH
                    ? pathIcon
                    : getLogoRepeatImage({
                        selectedColor: eventLogoColor,
                        selectedPattern: eventLogo,
                      })
                }
                size={"lg"}
                height={"80px"}
                borderNone
                opacity={0.4}
              />
              <EventReviewBlockV2
                slug={slug ?? ""}
                iconIsEditable
                description={eventDescription}
                timeDifference={getTimeDiffence({
                  currentTime: endTime
                    ? moment()
                        .minutes(getTimeInMinutes(endTime.name))
                        .toISOString()
                    : "0",
                  prevTime: moment()
                    .minutes(getTimeInMinutes(startTime.name))
                    .toISOString(),
                })}
                color={
                  +moment(eventDate)
                    .minutes(getTimeInMinutes(startTime.name))
                    .valueOf() < +moment().valueOf()
                    ? "pastEvent"
                    : eventLogoColor
                }
                pattern={eventLogo}
                key={eventName}
                onClick={() => setIsEditLogoModalOpen(true)}
                endTime={endTime ? endTime.name : undefined}
                name={eventName}
                date={moment(eventDate)
                  .minutes(getTimeInMinutes(startTime.name))
                  .format("ll")}
                startTime={startTime.name}
                location={location.name}
                withoutButton
              />
              <div className="flex gap-12 items-center fullWidth flex-wrap">
                {documents.map((item) => (
                  <Document
                    key={item.url}
                    url={item.url}
                    img={pdfIcon}
                    name={item.name}
                    type={"document"}
                    id={item.id}
                    eventId={eventIds.eventId}
                    withoutActions
                  />
                ))}
                {links.map((item) => (
                  <Document
                    key={item.url}
                    url={item.url}
                    img={fileTypeIcon}
                    name={item.url}
                    type={"link"}
                    id={item.id}
                    eventId={eventIds.eventId}
                    withoutActions
                  />
                ))}
              </div>
            </header>
            <main className="reviewEventDetailsPage-main overflowYVisible gap-12">
              <div className="flex gap-8 items-center positionRelative">
                <HorizontalTabs
                  tabs={[
                    { id: 1, name: "Overview" },
                    { id: 2, name: "Invitees" },
                  ]}
                  current={currentTab}
                  setCurrent={setCurrentTab}
                  type="Underline"
                />
                <img
                  src={fileAttachment}
                  alt=""
                  className="reviewEventDetailsPage-main-attachDoc"
                  onClick={() => {
                    setIsSelectFileModalOpen(true);
                    setIsAttachDocumentModalOpen(true);
                  }}
                />
                {isSelectFileModalOpen ? (
                  <DropdownMenu
                    content={[
                      { id: 1, name: "Add links", img: linkIcon },
                      { id: 2, name: "Upload files", img: uploadCloudIcon },
                    ]}
                    selectedItem={selectedFileModal}
                    setSelectedItem={setSelectedFileModal}
                    setShow={() => {
                      setIsSelectFileModalOpen(false);
                    }}
                    size="max-content"
                    positionRight={"0"}
                    positionTop={"98%"}
                  />
                ) : null}
              </div>
              {currentTab &&
              currentTab.id === 1 &&
              currentTab.name === "Overview" ? (
                <div
                  className={`eventDetails_main-content gap-32 ${
                    isDesktopSize ? "flex-row" : ""
                  }`}
                  // onScroll={() => {
                  //   scrollFunction();
                  // }}
                  style={{ marginBottom: "70px" }}
                  id="scrolledInfo"
                >
                  {/* <ReviewDetailsSection
                          eventDate={eventDate!}
                          eventDescription={eventDescription!}
                          eventLocation={location!.name}
                          eventName={eventName!}
                          eventStartTime={startTime?.name!}
                          eventLocationNotes={locationNotes!}
                          lat={location.lat || null}
                          lng={location.lng || null}
                          documents={undefined}
                          eventId={eventIds.eventId}
                        /> */}
                  <ReviewAgendaSection
                    agenda={agenda.map((item) => ({
                      id: item.id,
                      icon: item.icon,
                      name: item.name,
                      description: item.description,
                      duration: item.duration,
                      startTime: item!.startTime,
                      endTime: item.endTime ? item!.endTime ?? "" : "",
                      type: item.type,
                      index: item.index,
                    }))}
                    withoutLabel
                    subBlocks={subBlocks}
                    setSubBlocks={setSubBlocks}
                  />
                </div>
              ) : null}
              {currentTab &&
              currentTab.id === 2 &&
              currentTab.name === "Invitees" ? (
                <div
                  className={`eventDetails_main-content gap-32 ${
                    isDesktopSize ? "flex-row" : ""
                  }`}
                  // onScroll={() => {
                  //   scrollFunction();
                  // }}
                  style={{ marginBottom: "70px" }}
                  id="scrolledInfo"
                >
                  <UserInvitesTable
                    content={invitedAttendees.attendees.map((attendee) => ({
                      supportText: attendee.networkMember.user
                        ? attendee.networkMember.user.email
                        : attendee.networkMember.email,
                      name: attendee.networkMember.user
                        ? attendee.networkMember.user.fullName
                        : attendee.networkMember.fullName,
                      id: attendee.id,
                      address: attendee.networkMember.address,
                      businessName: attendee.networkMember.businessName,
                      title: attendee.networkMember.title,
                      status: attendee.status,
                    }))}
                    token={token}
                    selectedAttendees={selectedAttendees}
                    setSelectedAttendees={setSelectedAttendees}
                    onDeleteSelected={onDeleteSelected}
                    onDeleteAttendee={onDeleteAttendee}
                  />
                </div>
              ) : null}
            </main>
          </div>
        </div>
      </div>
      <div
        className={`eventDetails_main-footer`}
        style={{ position: "fixed", bottom: "0" }}
      >
        {isDesktopSize ? (
          <Button
            buttonType="regular"
            hierarchy="tertiaryColor"
            size="lg"
            onClick={() =>
              setModalNotificationIsOpen({
                name: "Delete event",
                description:
                  "You have not saved this event yet. If you leave now, your event will be deleted. Are you sure you want to continue?",
                firstButtonName: "Cancel",
                secondButtonName: "Delete event",
                onFirstButtonClick: () => setModalNotificationIsOpen(null),
                onSecondButtonClick: () => navigate("/"),
              })
            }
          >
            Cancel
          </Button>
        ) : null}
        <div
          className={`flex flex-row gap-12 items-center ${
            !isDesktopSize ? "spaceBetween fullWidth" : ""
          }`}
        >
          <Button
            buttonType="regular"
            hierarchy="newDesign-secondary"
            size="lg"
            contentCenter
            fullWidth
            onClick={() => {
              setIsSavedAsDraft(true);
            }}
            newDesignPaddingNone
          >
            <div className="eventDetails_main-footer-buttonSaveAsDraft">
              <p>Save as draft</p>
            </div>
          </Button>
          <Button
            size="lg"
            buttonType="regular"
            hierarchy="newDesign-primary"
            fullWidth
            contentCenter
            disabled={!agenda.length}
            fullHeight
            newDesignPaddingNone
            asyncOnClick={async () => {
              if (!isPublished) {
                await publishEvent({ eventId: eventIds.eventId, token });
              }
              navigate("/events");
            }}
          >
            {isEdit ? "Save" : "Create event"}
          </Button>
        </div>
      </div>
    </div>
  );
}
