import { useState } from "react";
import { Button } from "../Buttons/Button";
import { FormInput } from "../Inputs/FormInput";
import arrowLeftIcon from "../../assets/images/arrow-left.svg";
import userPlusIcon from "../../assets/images/user-plus-01-primary.svg";
import DropdownSelect from "../Dropdown/DropdownSelect";
import { postAttendee } from "../../httpQueries/http";

interface Props {
  attendees: {
    accountId: number;
    createdAt: string;
    email: string;
    fullName: string;
    id: number;
    phone?: string;
    updatedAt: string;
    address: string | null;
    businessName: string | null;
    title: string | null;
  }[];
  setShow: React.Dispatch<
    React.SetStateAction<{
      id: number;
      name: string;
      supportText?: string | undefined;
      value?: number | undefined;
    } | null>
  >;
  inputValue: string;
  setInputValue: React.Dispatch<React.SetStateAction<string>>;
  searchValue: string;
  setSearchValue: React.Dispatch<React.SetStateAction<string>>;
  eventId: number;
  setIsUsersInvitedQueryWorks: React.Dispatch<React.SetStateAction<boolean>>;
}

export function AddAttendee({
  attendees,
  inputValue,
  setInputValue,
  setShow,
  searchValue,
  setSearchValue,
  eventId,
  setIsUsersInvitedQueryWorks,
}: Props) {
  const [invitedAttendees, setInvitedAttendees] = useState<
    {
      id: number;
      name: string;
      supportText?: string;
      accountId?: number;
      createdAt?: string;
      phone?: string;
      updatedAt?: string;
      address?: string | null;
      businessName?: string | null;
      title?: string | null;
    }[]
  >([]);
  const token = localStorage.getItem("token");

  async function OnAddAttendees() {
    if (invitedAttendees.length) {
      await Promise.all(
        invitedAttendees.map(async (item) => {
          return postAttendee({
            body: {
              email: item.supportText!,
              businessName: item.businessName ? item.businessName : "",
              address: item.address ? item.address : "",
              title: item.title ? item.title : "",
              eventId: eventId,
              fullName: item.name,
              phone: item.phone ? item.phone : "",
            },
            token,
          });
        })
      );

      setIsUsersInvitedQueryWorks(false);
    }

    setShow(null);
  }

  return (
    <div className="addAttendees z1000">
      <div className="addAttendees_header">
        <h3 className="addAttendees_header-title">Add attendees</h3>
        <FormInput
          label="Add attendee"
          type="SearchGray"
          hasIconBefore
          onChange={(
            event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
          ) => {
            setInputValue(event.target.value);
            setSearchValue(event.target.value);
          }}
          value={searchValue}
        />
        <Button
          buttonType="regular"
          size="md"
          hierarchy={"tertiaryColor"}
          fullWidth
          paddingNone
          onClick={() => setShow({ id: 2, name: "Add new" })}
        >
          <div className={`openButton text-sm`}>
            <img src={userPlusIcon} alt="" className="calendarIcon" />
            <p>Add new attendee</p>
          </div>
        </Button>
        <div className="addAttendees_header-backButton">
          <Button
            buttonType="regular"
            hierarchy="linkGray"
            size="md"
            onClick={() => setShow(null)}
          >
            <div style={{ display: "flex", gap: "8px" }}>
              <img src={arrowLeftIcon} alt="arrow left" />
              <p>Back</p>
            </div>
          </Button>
        </div>
      </div>
      <div className="addAttendees_main">
        <DropdownSelect
          content={attendees.map((attendee) => ({
            accountId: attendee.accountId,
            createdAt: attendee.createdAt,
            supportText: attendee.email,
            name: attendee.fullName,
            id: attendee.id,
            phone: attendee.phone,
            updatedAt: attendee.updatedAt,
            address: attendee.address,
            businessName: attendee.businessName,
            title: attendee.title,
          }))}
          inputValue={searchValue}
          heightSize="lg"
          label="All users"
          selectedItems={invitedAttendees}
          setInputValue={setSearchValue}
          setSelectedItems={setInvitedAttendees}
          paddingNone
          setShowAddNewAttendee={setShow}
        />
        {invitedAttendees.length ? (
          <div className="modalInvite-actions">
            <p className="modalInvite-actions-selectedItems">
              {invitedAttendees.length} selected
            </p>
            <Button
              buttonType="regular"
              size="sm"
              hierarchy="secondaryGray"
              onClick={OnAddAttendees}
            >
              Add attendees
            </Button>
          </div>
        ) : null}
      </div>
    </div>
  );
}
