import dayjs from "dayjs";
import { getTimeFromMinutesForEdit } from "./getTimeFromMinutes";
import { Times } from "./timesArray";

export const getEventTime = (timeInDate: string, timezone: string | undefined): {
    id: number;
    name: string;
} | undefined => {
  const timeName = dayjs.tz(dayjs(timeInDate).utc(false), timezone).format('HH:mm');
  
  const time = Times.find(time => time.name === (getTimeFromMinutesForEdit(timeName)));

  return time
}