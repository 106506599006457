import React, { useEffect, useState } from "react";
import menuIcon from "../../assets/images/menu-05-white.svg";
import dotsIcon from "../../assets/images/dots-vertical.svg";
import trashIcon from "../../assets/images/trash-01-white.svg";
import trashIconGray from "../../assets/images/trash-01-gray.svg";
import editIcon from "../../assets/images/edit-01-white.svg";
import editIconGray from "../../assets/images/edit-01.svg";
import { Button } from "../Buttons/Button";
import FeaturedIcon from "../FeaturedIcons/FeaturedIcon";
import { useWindowSize } from "@uidotdev/usehooks";
import {
  DragDropContext,
  Draggable,
  DraggableProvidedDragHandleProps,
  Droppable,
} from "react-beautiful-dnd";
import DotsModal from "../Modal/DotsModal";
import { AgendaBlockType } from "../../types/agendaBlockType";
import { useQuery } from "@tanstack/react-query";
import { fetchMeals } from "../../httpQueries/http";
import Loader from "../Loader";
import SubBlockItem from "./SubBlockItem";
import { onSubBlocksDragEnd } from "../../utilities/onSubBlocksDragEnd";
import { editSubBlockTime } from "../../utilities/editSubBlockTime";
import BulletList from "../BulletList";

interface Props {
  id: string;
  icon: string;
  startTime: string;
  endTime: string;
  name: string;
  duration: {
    id: number;
    name: string;
    value?: number | undefined;
  };
  index: number;
  description: string;
  type: AgendaBlockType;
  onClick?: () => Promise<void>;
  setAgenda?: React.Dispatch<
    React.SetStateAction<
      {
        id: string;
        icon: string;
        name: string;
        description: string;
        duration: {
          id: number;
          name: string;
          value?: number | undefined;
        };
        startTime: string;
        endTime: string;
        type: AgendaBlockType;
        index: number;
      }[]
    >
  >;
  hasEditActions?: boolean;
  hasDragElement?: boolean;
  setShowAgendaModal?: React.Dispatch<React.SetStateAction<boolean>>;
  setCurrentItem?: React.Dispatch<
    React.SetStateAction<
      | {
          id: string;
          icon: string;
          name: string;
          description: string;
          duration: {
            id: number;
            name: string;
            value?: number | undefined;
          };
          startTime: string;
          endTime: string;
          type: AgendaBlockType;
          index: number;
        }
      | undefined
    >
  >;
  dragHandleProps?: DraggableProvidedDragHandleProps | null | undefined;
  isReview?: boolean;
  height: "full" | "max";
  isAgendaMealBlockWithoutMeals?: boolean;
  mealBlockId?: number;
  isReviewPageWithActions?: boolean;
  onDelete?: (agendaName: string, agendaId: string) => void;
  isSubBlockPreview?: boolean;
  subBlocks?: {
    id: string;
    name: string;
    startTime: string;
    endTime: string;
    duration: {
      id: number;
      name: string;
      value?: number | undefined;
    } | null;
    index: number;
    agendaItemId: string;
  }[];
  setSubBlocks?: React.Dispatch<
    React.SetStateAction<
      {
        id: string;
        name: string;
        startTime: string;
        endTime: string;
        duration: {
          id: number;
          name: string;
          value?: number | undefined;
        } | null;
        index: number;
        agendaItemId: string;
      }[]
    >
  >;
  setEditSubBlock?: React.Dispatch<
    React.SetStateAction<{
      id: string;
      name: string;
      startTime: string;
      endTime: string;
      duration: {
        id: number;
        name: string;
        value?: number | undefined;
      } | null;
      index: number;
      agendaItemId: string;
    } | null>
  >;
  subBlocksIsDraggable?: boolean;
  agendaStartTime?: string;
  agenda: {
    id: string;
    icon: string;
    name: string;
    description: string;
    duration: {
      id: number;
      name: string;
      value?: number | undefined;
    };
    startTime: string;
    endTime: string;
    type: AgendaBlockType;
    index: number;
  }[];
}

export default function AgendaItem({
  id,
  icon,
  startTime,
  endTime,
  name,
  index,
  duration,
  description,
  type,
  setAgenda,
  setShowAgendaModal,
  setCurrentItem,
  dragHandleProps,
  hasDragElement,
  hasEditActions,
  isReview,
  height,
  isReviewPageWithActions,
  onClick,
  mealBlockId,
  onDelete,
  isSubBlockPreview,
  subBlocks,
  setSubBlocks,
  setEditSubBlock,
  subBlocksIsDraggable,
  agendaStartTime,
  agenda,
}: Props) {
  const size = useWindowSize();
  const [sizeIsDesktop, setSizeIsDesktop] = useState(true);
  const [showDotsModal, setShowDotsModal] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);
  const token = localStorage.getItem("token");
  const { data: mealBlock } = useQuery({
    queryKey: ["mealBlock", mealBlockId],
    queryFn: async () => await fetchMeals({ id: mealBlockId ?? 0, token }),
  });
  const [isDrag, setIsDrag] = useState(false);

  const onBlockDelete = () => {
    if (setAgenda) {
      setSubBlocks &&
        setSubBlocks((prevState) =>
          prevState.filter((subBlock) => subBlock.agendaItemId !== id)
        );
      setAgenda((prevState) => prevState.filter((item) => item.id !== id));
    }
  };

  useEffect(() => {
    if (subBlocks) {
      editSubBlockTime(
        subBlocks,
        agendaStartTime || null,
        agenda,
        setSubBlocks
      );
      setIsDrag(false);
    }
  }, [isDrag, subBlocks?.length]);

  useEffect(() => {
    setSizeIsDesktop(size.width! >= 768);
  }, [size.width]);

  return (
    <div
      className={`agendaItem ${
        !hasDragElement ? "agendaItem-withoutEditActions" : ""
      } ${isReview ? "reviewAgendaModalBlock" : ""} ${
        height === "max" ? "agendaItem-maxHeight" : ""
      }`}
    >
      <div
        className={`agendaItem-timeInfo ${
          !hasDragElement && !isSubBlockPreview
            ? "agendaItem-timeInfo-withoutEditActions"
            : ""
        } ${isSubBlockPreview ? "agendaItem-subBlockPreview" : ""} ${
          isReview ? "reviewAgendaModalBlock-timeInfo" : ""
        }`}
      >
        <div className="flex items-center gap-16">
          {hasDragElement ? (
            <div {...dragHandleProps} className="agendaItem-info-dragBtn">
              <Button
                size="md"
                buttonType="small"
                hierarchy="tertiaryGray"
                buttonImage={menuIcon}
                pointerEventsNone
                disabled
                hoverDark
              />
            </div>
          ) : null}
          <div className="agendaItem-timeInfo-container">
            <div
              className={`agendaItem-timeInfo-time-circle ${
                !hasDragElement && !isSubBlockPreview
                  ? "agendaItem-timeInfo-time-circle-withoutEditActions"
                  : ""
              }`}
            />
            <p className="agendaItem-timeInfo-time">
              {startTime} - {endTime}
            </p>
          </div>
        </div>
        {isReviewPageWithActions ? (
          <div className="agendaItem-info-actions">
            <Button
              size="md"
              buttonType="small"
              hierarchy="tertiaryGray"
              buttonImage={trashIconGray}
              imgProperties={16}
              onClick={() => onDelete && onDelete(name, id)}
            />
            <Button
              size="md"
              buttonType="small"
              hierarchy="tertiaryGray"
              buttonImage={editIconGray}
              imgProperties={16}
              onClick={() => {
                setShowAgendaModal && setShowAgendaModal(true);
                setCurrentItem &&
                  setCurrentItem({
                    id,
                    description,
                    duration,
                    endTime,
                    icon,
                    name,
                    startTime,
                    type,
                    index,
                  });
              }}
            />
          </div>
        ) : null}
        {!mealBlock && hasEditActions ? (
          <>
            {!sizeIsDesktop ? (
              <Button
                size="md"
                buttonType="small"
                hierarchy="tertiaryGray"
                buttonImage={dotsIcon}
                hoverDark
                onClick={() => setShowDotsModal((prev) => !prev)}
              />
            ) : null}

            {sizeIsDesktop ? (
              <div className="agendaItem-info-actions">
                <Button
                  size="md"
                  buttonType="small"
                  hierarchy="tertiaryGray"
                  hoverDark
                  buttonImage={trashIcon}
                  onClick={onBlockDelete}
                />
                <Button
                  size="md"
                  buttonType="small"
                  hierarchy="tertiaryGray"
                  buttonImage={editIcon}
                  hoverDark
                  onClick={() => {
                    setShowAgendaModal && setShowAgendaModal(true);
                    setCurrentItem &&
                      setCurrentItem({
                        id,
                        description,
                        duration,
                        endTime,
                        icon,
                        name,
                        startTime,
                        type,
                        index,
                      });
                  }}
                />
              </div>
            ) : null}
          </>
        ) : null}
        {showDotsModal && !sizeIsDesktop ? (
          <DotsModal
            items={[
              {
                id: 1,
                img: editIcon,
                text: "Edit",
                onClick: () => {
                  setShowAgendaModal && setShowAgendaModal(true);
                  setCurrentItem &&
                    setCurrentItem({
                      id,
                      description,
                      duration,
                      endTime,
                      icon,
                      name,
                      startTime,
                      type,
                      index,
                    });
                },
              },
              {
                id: 2,
                img: trashIcon,
                text: "Delete",
                onClick: onBlockDelete,
              },
            ]}
            setIsOpen={setShowDotsModal}
            top="60px"
            right="0"
          />
        ) : null}
      </div>
      <div
        className={`agendaItem-info 
          ${isSubBlockPreview ? "agendaItem-info-subBlockPreview" : ""}
          ${!hasDragElement ? "agendaItem-info-withoutEditActions" : ""} 
          ${isReview ? "reviewAgendaModalBlock-info" : ""}
          ${onClick && "cursorPointer"}
        `}
        onClick={async () => {
          setIsLoading(true);
          onClick && (await onClick());
          setIsLoading(false);
        }}
      >
        {!isLoading ? (
          <div className="flex flex-column gap-16 fullWidth">
            <div className="agendaItem-info-title-wrapper">
              <div className="agendaItem-info-title">
                <div
                  className={`
                      agendaItem-info-title-icon 
                      ${
                        type === AgendaBlockType.Break
                          ? "agendaItem-info-title-icon-break"
                          : type === AgendaBlockType.Meal
                          ? "agendaItem-info-title-icon-meal"
                          : ""
                      }
                    `}
                >
                  <FeaturedIcon
                    icon={icon}
                    type={
                      type === AgendaBlockType.Session
                        ? "primary"
                        : type === AgendaBlockType.Break
                        ? "gray"
                        : "warning"
                    }
                    size="sm"
                  />
                </div>
                <div className="flex flex-column gap-6">
                  <h3 className="agendaItem-info-title-text">{name}</h3>
                  {mealBlock ? (
                    <p className="agendaItem-info-description">
                      {mealBlock.mealBlock?.restaurantName} • Estimated total $
                      {mealBlock?.totalPrice &&
                        (mealBlock.totalPrice / 100).toFixed(2)}
                    </p>
                  ) : null}
                </div>
                {/* <p className='agendaItem-info-title-duration'>{duration.name}</p> */}
              </div>
              {description.length ? (
                <p className="agendaItem-info-description">{description}</p>
              ) : null}
            </div>
            {subBlocks?.length && setSubBlocks ? (
              <div className="flex gap-8 fullWidth">
                <BulletList
                  bullets={subBlocks.filter(
                    (subBlock) => subBlock.agendaItemId === id
                  )}
                  className="mt-13px"
                  isSmall={!subBlocksIsDraggable}
                />
                {subBlocksIsDraggable ? (
                  <DragDropContext
                    onDragEnd={(result) =>
                      onSubBlocksDragEnd(
                        result,
                        setIsDrag,
                        subBlocks,
                        setSubBlocks
                      )
                    }
                  >
                    <Droppable droppableId="droppable-2" type="SUBBLOCK">
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          className="eventDetails_main-content-agenda-draggable gap-32 fullWidth"
                          {...provided.droppableProps}
                          style={{
                            paddingBottom: snapshot.isDraggingOver
                              ? "105px"
                              : "0",
                          }}
                        >
                          {subBlocks &&
                            subBlocks
                              .filter(
                                (subBlock) => subBlock.agendaItemId === id
                              )
                              .sort((it1, it2) => it1.index - it2.index)
                              .map((item, i) => (
                                <React.Fragment key={item.id}>
                                  <Draggable draggableId={item.id} index={i}>
                                    {(provided, snapshot) => {
                                      return (
                                        <div
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                        >
                                          <SubBlockItem
                                            setSubBlocks={setSubBlocks}
                                            isDragElement={
                                              !!subBlocksIsDraggable
                                            }
                                            item={item}
                                            dragHandleProps={
                                              provided.dragHandleProps
                                            }
                                            setEditSubBlock={setEditSubBlock}
                                            withActions={!!subBlocksIsDraggable}
                                          />
                                        </div>
                                      );
                                    }}
                                  </Draggable>
                                </React.Fragment>
                              ))}

                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                ) : (
                  <div
                    className="flex flex-column gap-32 fullWidth"
                  >
                    {subBlocks &&
                      subBlocks
                        .filter((subBlock) => subBlock.agendaItemId === id)
                        .sort((it1, it2) => it1.index - it2.index)
                        .map((item, i) => (
                          <SubBlockItem
                            setSubBlocks={setSubBlocks}
                            isDragElement={!!subBlocksIsDraggable}
                            item={item}
                            setEditSubBlock={setEditSubBlock}
                            withActions={!!subBlocksIsDraggable}
                          />
                        ))}
                  </div>
                )}
              </div>
            ) : null}
          </div>
        ) : (
          <div className="flex items-center justifyCenter fullWidth heightWidth">
            <Loader size={"md"}></Loader>
          </div>
        )}
      </div>
    </div>
  );
}
