import React from "react";

interface Props {
  name: string;
  value: string | any;
}

export default function SettingValue({ value, name }: Props) {
  return (
    <div className="flex flex-column gap-10 fullWidth min-w-half text-ellipsis overflowHidden">
      <p className="text-xs font-medium color-gray-600">{name}</p>
      <h4 className="text-lg font-medium color-gray-900 text-ellipsis overflowHidden">
        {value ? value : "-"}
      </h4>
    </div>
  );
}
