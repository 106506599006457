import { useState } from "react";

interface Props {
  hierarchy: "primary" | "default" | "dark";
  label: string;
  icon: string;
  hasDropdown?: boolean;
  onClick: () => void;
  selected: boolean;
  dropdownContent?: {
    name: string;
    isSelected: boolean;
    onClick: () => void;
    icon: string;
  }[];
}

export function MenuDropdownItemNew({
  label,
  icon,
  hasDropdown,
  hierarchy,
  onClick,
  selected,
  dropdownContent,
}: Props) {
  const [dropdownIsOpen, setDropdownIsOpen] = useState(false);

  return (
    <div
      onClick={() => {
        hasDropdown
          ? setDropdownIsOpen((prevState) => !prevState)
          : !selected && onClick();
      }}
      style={{ position: "relative" }}
    >
      <div
        className={`menuDropdownNew_content ${
          dropdownIsOpen ? "menuDropdownNew-withDropdown" : ""
        }  menuDropdownNew ${
          hierarchy === "primary" && selected
            ? "menuDropdownNew-primary-selected"
            : ""
        }`}
      >
        <img src={icon} alt="" />
        <h1
          className={`
            menuDropdownNew-title 
            ${hierarchy === "default" ? "menuDropdownNew-title-default" : ""}
            ${hierarchy === "primary" ? "menuDropdownNew-title-primary" : ""}
            ${hierarchy === "dark" ? "menuDropdownNew-title-dark" : ""}
          `}
        >
          {label}
        </h1>
      </div>
      {dropdownIsOpen && dropdownContent ? (
        <div className="menuDropdownNew-dropdown">
          {dropdownContent.map((dropdownItem) => (
            <div
              className={`menuDropdownNew-dropdown-item ${
                dropdownItem.isSelected
                  ? "menuDropdownNew-dropdown-item-selected"
                  : ""
              }`}
              onClick={() => !dropdownItem.isSelected && dropdownItem.onClick()}
            >
              <img
                src={dropdownItem.icon}
                alt=""
                className="menuDropdownNew-dropdown-item-image"
              />
              <div className="menuDropdown_content">
                <h1
                  className={`
                            menuDropdownNew-title 
                            ${
                              hierarchy === "default"
                                ? "menuDropdownNew-title-default"
                                : ""
                            }
                            ${
                              hierarchy === "primary"
                                ? "menuDropdownNew-title-primary"
                                : ""
                            }
                            ${
                              hierarchy === "dark"
                                ? "menuDropdownNew-title-dark"
                                : ""
                            }
                          `}
                >
                  {dropdownItem.name}
                </h1>
              </div>
            </div>
          ))}
        </div>
      ) : null}
    </div>
  );
}
