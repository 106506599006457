import React, { useEffect, useState } from "react";
import arrowDown from "../../assets/images/chevron-down.svg";
import { countriesList } from "../../utilities/countriesList";
import { DropdownMenu } from "./DropdownMenu";

interface Props {
  selectedCountry: {
    id: number;
    name: string;
    supportText?: string | undefined;
    value?: number | undefined;
    abbr?: string | undefined;
    emoji?: string;
  } | null;
  setSelectedCountry: React.Dispatch<
    React.SetStateAction<{
      id: number;
      name: string;
      supportText?: string | undefined;
      value?: number | undefined;
      abbr?: string | undefined;
      emoji?: string;
    } | null>
  >;
}

export default function CountryDropdown({
  selectedCountry,
  setSelectedCountry,
}: Props) {
  const [isOpen, setIsOpen] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [inputIsTarget, setInputIsTarget] = useState(false);

  useEffect(() => {
    if (selectedCountry) {
      setInputValue(selectedCountry.name);
    }
  }, [selectedCountry]);

  return (
    <div className={`countryDropdown ${selectedCountry ? "formNew" : ""}`}>
      {selectedCountry?.name ? (
        <h3 className="countryDropdown-label">Country</h3>
      ) : null}
      <div
        className={`inputNew ${selectedCountry ? "inputNew-filled" : ""}`}
        onClick={() => setIsOpen((prevState) => !prevState)}
      >
        <div className="countryDropdown-input-container">
          <p>{selectedCountry?.emoji}</p>
          <input
            onFocus={() => setInputIsTarget(true)}
            onBlur={() => setInputIsTarget(false)}
            type="text"
            value={inputValue}
            placeholder="Country"
            onChange={(e) => setInputValue(e.target.value)}
          />
        </div>
        <img src={arrowDown} alt="" />
      </div>

      {isOpen ? (
        <DropdownMenu
          content={
            inputValue
              ? countriesList.filter((item) =>
                  item.name
                    .toLocaleLowerCase()
                    .includes(inputValue.toLocaleLowerCase())
                )
              : countriesList
          }
          selectedItem={selectedCountry}
          setSelectedItem={setSelectedCountry}
          setShow={() => setIsOpen((prevState) => !prevState)}
          size="base"
          positionTop={"70px"}
          positionRight={"0"}
          reactToFocus={!inputIsTarget}
          height="150px"
          fullWidth
        />
      ) : null}
    </div>
  );
}
