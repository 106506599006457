import React, { useState } from "react";
import { FormInput } from "../Inputs/FormInput";
import { User } from "../../types/userForAddType";

export default function TeamsDropdown() {
  const [isDropdownOpen, setIsDropDownOpen] = useState(false);
  const [alreadyAddedItems, setAlreadyAddedItems] = useState<User[]>([]);

  return (
    <div className="flex flex-column gap-12 fullWidth">
      <FormInput
        type="Users"
        label={"Team"}
        setUsersFromInput={setAlreadyAddedItems}
        usersFromInput={alreadyAddedItems}
      />
      {true ? (
        <div className="teamsDropdown">
          <div className={`teamsDropdown-item`} key={1}>
            <div
              className={`
                      newCategoryDropdown-colorCircle
                      newCategoryDropdown-colorCircle-blue
                   `}
            />
            <li className={`teamsDropdown-item-name`}>Design</li>
          </div>
          <div className={`teamsDropdown-item`} key={1}>
            <div
              className={`
                      newCategoryDropdown-colorCircle
                      newCategoryDropdown-colorCircle-blue
                   `}
            />
            <li className={`teamsDropdown-item-name`}>Design</li>
          </div>
          <div className={`teamsDropdown-item`} key={1}>
            <div
              className={`
                      newCategoryDropdown-colorCircle
                      newCategoryDropdown-colorCircle-blue
                   `}
            />
            <li className={`teamsDropdown-item-name`}>Design</li>
          </div>
          <div className={`teamsDropdown-item`} key={1}>
            <div
              className={`
                      newCategoryDropdown-colorCircle
                      newCategoryDropdown-colorCircle-blue
                   `}
            />
            <li className={`teamsDropdown-item-name`}>Design</li>
          </div>
          <div className={`teamsDropdown-item`} key={1}>
            <div
              className={`
                      newCategoryDropdown-colorCircle
                      newCategoryDropdown-colorCircle-blue
                   `}
            />
            <li className={`teamsDropdown-item-name`}>Design</li>
          </div>
          <div className={`teamsDropdown-item`} key={1}>
            <div
              className={`
                      newCategoryDropdown-colorCircle
                      newCategoryDropdown-colorCircle-blue
                   `}
            />
            <li className={`teamsDropdown-item-name`}>Design</li>
          </div>
          <div className={`teamsDropdown-item`} key={1}>
            <div
              className={`
                      newCategoryDropdown-colorCircle
                      newCategoryDropdown-colorCircle-blue
                   `}
            />
            <li className={`teamsDropdown-item-name`}>Design</li>
          </div>
          <div className={`teamsDropdown-item`} key={1}>
            <div
              className={`
                      newCategoryDropdown-colorCircle
                      newCategoryDropdown-colorCircle-blue
                   `}
            />
            <li className={`teamsDropdown-item-name`}>Design</li>
          </div>
          <div className={`teamsDropdown-item`} key={1}>
            <div
              className={`
                      newCategoryDropdown-colorCircle
                      newCategoryDropdown-colorCircle-blue
                   `}
            />
            <li className={`teamsDropdown-item-name`}>Design</li>
          </div>
          <div className={`teamsDropdown-item`} key={1}>
            <div
              className={`
                      newCategoryDropdown-colorCircle
                      newCategoryDropdown-colorCircle-blue
                   `}
            />
            <li className={`teamsDropdown-item-name`}>Design</li>
          </div>
        </div>
      ) : null}
    </div>
  );
}
