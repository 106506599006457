import React, { useState } from "react";
import { Button } from "../Buttons/Button";
import plusIcon from "../../assets/images/plus-icon.svg";
import minusIcon from "../../assets/images/minus-icon.svg";

interface Props {
  onIncrease: () => void;
  onDescrease: () => void;
  onInputChange: (count: number) => void;
  count: number;
  disableIncrease?: boolean;
  maxInputValue?: number;
}

export default function Counter({
  onDescrease,
  onIncrease,
  count,
  disableIncrease,
  onInputChange,
  maxInputValue,
}: Props) {
  const [inputIsOpen, setInputIsOpen] = useState(false);
  const [inputValue, setInputValue] = useState(count);

  return (
    <div className="counter">
      <Button
        size="sm"
        hierarchy="secondaryGray"
        buttonType="small"
        buttonImage={minusIcon}
        fullHeight
        contentCenter
        disabled={count <= 0}
        onClick={onDescrease}
        isCircle
      />
      {inputIsOpen ? (
        <input
          type="number"
          className="counter-count"
          value={inputValue}
          onChange={(e) => setInputValue(+e.target.value)}
          min={0}
          onBlur={() => {
            onInputChange(inputValue);
            setInputIsOpen(false);
          }}
          max={maxInputValue}
        />
      ) : (
        <p className="counter-count" onClick={() => setInputIsOpen(true)}>
          {count}
        </p>
      )}
      <Button
        size="sm"
        hierarchy="secondaryGray"
        buttonType="small"
        buttonImage={plusIcon}
        fullHeight
        contentCenter
        disabled={disableIncrease}
        onClick={onIncrease}
        isCircle
      />
    </div>
  );
}
