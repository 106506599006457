import React from "react";
import closeIcon from "../../assets/images/x-close-gray-lg.svg";

interface Props {
  setShow: () => void;
  children: React.ReactNode,
}

export default function ReviewDocumentsModal({ setShow, children }: Props) {
  return (
    <div className="modalNotification" style={{ zIndex: "10002" }}>
      <div className="modalNotification-container modalNotification-container-selectMealsCustomization" style={{ maxHeight: '90%' }}>
        <div className="modalNotification-header gap-8">
          <div className="modalNotification-header-actions">
            <h3>Event attachments</h3>
            <img
              src={closeIcon}
              alt="close icon"
              className="modalNotification-header-actions-close"
              onClick={setShow}
            />
          </div>
          <div className="modalNotification-header-info">
            <p>Manage your event attachments</p>
          </div>
        </div>
        <div className="modalNotification-main">
          {children}
        </div>
      </div>
    </div>
  );
}
