import React, { useEffect } from "react";
import { Button } from "../Buttons/Button";
import closeIcon from "../../assets/images/x-close-black.svg";
import clockPlus from "../../assets/images/clock-plus-warning.svg";

interface Props {
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function ModalAgendaExtendsBeyondEndTime({ setIsOpen }: Props) {
  useEffect(() => {
    window.scrollTo(0, 0);

    document.getElementById("modal")?.classList.add("modal-main-container");
    document.body.style.overflow = "hidden";

    return () => {
      document.body.style.overflow = "auto";
      document
        .getElementById("modal")
        ?.classList.remove("modal-main-container");
    };
  }, []);

  return (
    <div className="modalNotification">
      <div className="modalNotification-container">
        <div className="modalNotification-circles-error">
          <div className="eventDetails_circles">
            <div className="eventDetails-circle eventDetails-circle-fifth">
              <div className="eventDetails-circle eventDetails-circle-fourth">
                <div className="eventDetails-circle eventDetails-circle-third">
                  <div className="eventDetails-circle eventDetails-circle-second">
                    <div className="eventDetails-circle eventDetails-circle-first"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modalNotification-header modalNotification-header-error">
          <div className="modalNotification-header-actions">
            <div className="modalNotification-header-actions-icon modalNotification-header-actions-icon-warning">
              <img src={clockPlus} alt="trash icon" />
            </div>
            <img
              src={closeIcon}
              alt="close icon"
              className="modalNotification-header-actions-close"
              onClick={() => setIsOpen(false)}
            />
          </div>
          <div className="modalNotification-header-info">
            <h3>Your agenda extends beyond your end time.</h3>
            <p>
              You might want to consider deleting blocks or changing the
              duration of some.
            </p>
          </div>
        </div>
        <div className="modalNotification-actions">
          <Button
            buttonType="regular"
            size="lg"
            hierarchy="secondaryGray"
            fullWidth
            onClick={() => setIsOpen(false)}
            contentCenter
          >
            Cancel
          </Button>
          <Button
            buttonType="regular"
            size="lg"
            hierarchy="primary"
            fullWidth
            onClick={() => setIsOpen(false)}
            contentCenter
          >
            Confirm
          </Button>
        </div>
      </div>
    </div>
  );
}
