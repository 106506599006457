import React from "react";
import FeaturedIcon from "../FeaturedIcons/FeaturedIcon";
import { Button } from "../Buttons/Button";

interface Props {
  icon: string;
  title: string;
  action: "email" | "phone";
  description: string;
  actionText: string;
}

export default function ContactBlock({
  icon,
  title,
  description,
  actionText,
  action,
}: Props) {
  return (
    <div className="contactBlock contactBlock-white">
      <FeaturedIcon icon={icon} type="primaryColor" size={"lg"} />
      <div className="contactBlock-content">
        <div>
          <h3 className="contactBlock-content-title">{title}</h3>
          <p className="contactBlock-content-description">{description}</p>
        </div>
        <Button
          size="lg"
          hierarchy="linkColor"
          buttonType="regular"
          paddingNone
        >
          <a
            href={`${action === "email" ? "mailto" : "Tel"}:${actionText}`}
            style={{ textDecoration: "none" }}
          >
            {actionText}
          </a>
        </Button>
      </div>
    </div>
  );
}
