import logo from "../../assets/images/gatherwise-logo.svg";
import logoColor from "../../assets/images/logo-lg-color.svg";
import homeIcon from "../../assets/images/home-icon.svg";
import homeGrayIcon from "../../assets/images/home-line-gray-500.svg";
import homeGrayLightIcon from "../../assets/images/home-line-gray-300.svg";
import lifeBuoyIcon from "../../assets/images/life-buoy.svg";
import lifeBuoyGrayIcon from "../../assets/images/life-buoy-gray-500.svg";
import lifeBuoyGrayLightIcon from "../../assets/images/life-buoy-gray-300.svg";
import settingsIcon from "../../assets/images/settings.svg";
import settingsGrayIcon from "../../assets/images/settings-gray-500.svg";
import settingsGrayLightIcon from "../../assets/images/settings-gray-300.svg";
import logoutIcon from "../../assets/images/log-out.svg";
import logoutGrayIcon from "../../assets/images/log-out-gray.svg";
import { MenuDropdownItem } from "../Dropdown/MenuDropdownItem";
import { Button } from "../Buttons/Button";
import ProgressBar from "../ProgressBar/ProgressBar";
import menuIcon from "../../assets/images/menu-02.svg";
import menuPrimary from "../../assets/images/menu-lg-primary.svg";
import { useEffect, useState } from "react";
import closeIcon from "../../assets/images/x-close-white-lg.svg";
import { useWindowSize } from "@uidotdev/usehooks";
import { useNavigate } from "react-router-dom";
import announcementIcon from "../../assets/images/announcement-01-primary.svg";
import usersIcon from "../../assets/images/users.svg";
import usersGrayIcon from "../../assets/images/users-gray-500.svg";
import usersGrayLightIcon from "../../assets/images/users-gray-300.svg";
import { useSelector } from "../../store/hooks";
import { currentUserSelector } from "../../store/currentUser";
import { supabase } from "../../utilities/supabaseClient";
import Loader from "../Loader";
import { UserImagePlaceholder } from "../userProfileImagePlaceholder/userImagePlaceholder";

interface Props {
  hierarchy: "primary" | "default" | "dark";
  fullWidth?: boolean;
  menuIsOpen?: boolean;
  currentItem: string;
  hasProblem?: boolean;
  hoverGray?: boolean;
  setMenuIsOpen?: any;
}

export default function SideBarNavigation({
  hierarchy,
  fullWidth,
  menuIsOpen,
  currentItem,
  hasProblem,
  hoverGray,
  setMenuIsOpen,
}: Props) {
  const [progressBarIsOpen, setProgressBarIsOpen] = useState<boolean>(true);
  const size = useWindowSize();
  const [sizeIsDesktop, setSizeIsDesktop] = useState(true);
  const [isOpenMobileMenu, setIsOpenMobileMenu] = useState(false);
  const [startPosition, setStartPosition] = useState<number>(0);
  const navigate = useNavigate();
  const user = useSelector(currentUserSelector);

  function getColorHierarchy() {
    switch (hierarchy) {
      case "primary":
        return "navigation_primary";
      case "default":
        return "navigation_default";
      case "dark":
        return "navigation_dark";
    }
  }

  useEffect(() => {
    setSizeIsDesktop(size.width! >= 768);
  }, [size.width]);

  return (
    <div
      className={`navigation ${getColorHierarchy()}`}
      style={{ width: fullWidth || !sizeIsDesktop ? "100%" : "25%" }}
      onTouchStart={
        isOpenMobileMenu
          ? (e) => setStartPosition(e.changedTouches[0]?.clientX)
          : () => {}
      }
      onTouchEnd={
        isOpenMobileMenu
          ? (e) =>
              e.changedTouches[0].clientX < startPosition
                ? setIsOpenMobileMenu(false)
                : null
          : () => {}
      }
    >
      <div className="navigation_header">
        {sizeIsDesktop ? (
          <img
            src={hierarchy === "default" ? logoColor : logo}
            alt="gatherwise logo"
            className="navigation_header-logo"
          />
        ) : null}
        {!sizeIsDesktop ? (
          <div className="flex flex-row gap-24 spaceBetween">
            <img
              src={hierarchy === "default" ? logoColor : logo}
              alt="gatherwise logo"
              className="navigation_header-logo"
            />
            {!sizeIsDesktop && !menuIsOpen ? (
              <Button
                buttonType="small"
                hierarchy="tertiaryGray"
                size="md"
                buttonImage={hierarchy === "default" ? menuIcon : menuPrimary}
                onClick={() => setIsOpenMobileMenu((prevState) => !prevState)}
              />
            ) : null}
          </div>
        ) : null}
        {isOpenMobileMenu && !sizeIsDesktop ? (
          <div className="topSideNavigation-nav-items-container-mobile">
            <SideBarNavigation
              hierarchy={hierarchy}
              fullWidth
              menuIsOpen
              currentItem={currentItem}
              setMenuIsOpen={setIsOpenMobileMenu}
            />
            <div className="topSideNavigation-nav-items-container-mobile-close">
              <img
                src={closeIcon}
                alt=""
                className="topSideNavigation-nav-items-container-mobile-close-icon"
                onClick={() => setIsOpenMobileMenu(false)}
              />
            </div>
          </div>
        ) : null}
        {sizeIsDesktop || menuIsOpen ? (
          <>
            <div className="menuDropdown_buttons">
              <MenuDropdownItem
                hierarchy={hierarchy}
                icon={
                  hierarchy === "primary"
                    ? homeIcon
                    : hierarchy === "default"
                    ? homeGrayIcon
                    : homeGrayLightIcon
                }
                label="Home"
                onClick={() => navigate("/")}
                selected={currentItem === "Home"}
                hoverGray={hoverGray}
              />
              <MenuDropdownItem
                hierarchy={hierarchy}
                icon={
                  hierarchy === "primary"
                    ? announcementIcon
                    : hierarchy === "default"
                    ? announcementIcon
                    : announcementIcon
                }
                label="Events"
                hasDropdown
                dropdownContent={[
                  {
                    name: "Events I'm Organizing",
                    isSelected: currentItem === "EventsOrganizer",
                    onClick: () => {
                      navigate("/events?filter=organizer");
                      setMenuIsOpen(false);
                    },
                  },
                  {
                    name: "Events I'm Attending",
                    isSelected: currentItem === "EventsAttendee",
                    onClick: () => {
                      setMenuIsOpen(false);
                      navigate("/events?filter=attendee");
                    },
                  },
                ]}
                onClick={() => {}}
                selected={
                  currentItem === "EventsOrganizer" ||
                  currentItem === "EventsAttendee"
                }
                hoverGray={hoverGray}
              />
              <MenuDropdownItem
                hierarchy={hierarchy}
                icon={
                  hierarchy === "primary"
                    ? usersIcon
                    : hierarchy === "default"
                    ? usersGrayIcon
                    : usersGrayLightIcon
                }
                label="Contacts/Network"
                onClick={() => navigate("/contacts")}
                selected={currentItem === "Contacts/Network"}
                hoverGray={hoverGray}
              />
            </div>
          </>
        ) : null}
      </div>
      {sizeIsDesktop || menuIsOpen ? (
        <div className="navigation_footer">
          <div className="menuDropdown_buttons">
            <MenuDropdownItem
              hierarchy={hierarchy}
              icon={
                hierarchy === "primary"
                  ? lifeBuoyIcon
                  : hierarchy === "default"
                  ? lifeBuoyGrayIcon
                  : lifeBuoyGrayLightIcon
              }
              label="Support"
              onClick={() => navigate("/support")}
              selected={currentItem === "Support"}
              hoverGray={hoverGray}
            />
            <MenuDropdownItem
              hierarchy={hierarchy}
              icon={
                hierarchy === "primary"
                  ? settingsIcon
                  : hierarchy === "default"
                  ? settingsGrayIcon
                  : settingsGrayLightIcon
              }
              label="Settings"
              onClick={() => navigate("/settings")}
              selected={currentItem === "Settings"}
              hoverGray={hoverGray}
            />
          </div>
          {progressBarIsOpen && hasProblem ? (
            <div>
              <ProgressBar
                hierarchy={hierarchy}
                progress={80}
                onClose={() => setProgressBarIsOpen(false)}
              />
            </div>
          ) : null}
          <div
            className={`
                  navigation_userInfo-container 
                  ${
                    hierarchy === "primary"
                      ? "navigation_userInfo-container-primary"
                      : ""
                  }
                  ${
                    hierarchy === "default"
                      ? "navigation_userInfo-container-default"
                      : ""
                  }
                  ${
                    hierarchy === "dark"
                      ? "navigation_userInfo-container-dark"
                      : ""
                  }
                `}
          >
            <div className="navigation_userInfo">
              {user.fullName ? (
                <>
                  <UserImagePlaceholder
                    name={user.fullName.toLocaleUpperCase()}
                    verified={false}
                  />
                  <div style={{ width: "80%" }}>
                    <h3
                      className={`
                                navigation_userInfo-name
                                ${
                                  hierarchy === "primary" ||
                                  hierarchy === "dark"
                                    ? "navigation_userInfo-name-primary"
                                    : ""
                                }
                                ${
                                  hierarchy === "default"
                                    ? "navigation_userInfo-name-default"
                                    : ""
                                }
                              `}
                    >
                      {user.fullName}
                    </h3>
                    <p
                      className={`
                                navigation_userInfo-email
                                ${
                                  hierarchy === "primary"
                                    ? "navigation_userInfo-email-primary"
                                    : ""
                                }
                                ${
                                  hierarchy === "default"
                                    ? "navigation_userInfo-email-default"
                                    : ""
                                }
                                ${
                                  hierarchy === "dark"
                                    ? "navigation_userInfo-email-dark"
                                    : ""
                                }
                              `}
                    >
                      {user.email}
                    </p>
                  </div>
                </>
              ) : (
                <div className="flex">
                  <Loader size="xl" />
                </div>
              )}
            </div>
            <Button
              buttonType="small"
              buttonImage={
                hierarchy === "primary" ? logoutIcon : logoutGrayIcon
              }
              size="sm"
              hierarchy="tertiaryGray"
              onClick={async () => {
                const { error } = await supabase().auth.signOut();
                localStorage.clear();
                navigate("/login");
              }}
            />
          </div>
        </div>
      ) : null}
    </div>
  );
}
