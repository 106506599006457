import React from "react";

interface Props {
  buttons: {
    first: {
      id: number;
      name: string;
      image: string;
      imageSelected: string;
      onSelect: () => void;
    };
    second: {
      id: number;
      name: string;
      image: string;
      imageSelected: string;
      onSelect: () => void;
    };
  };
  selected: number;
  type: "default" | "gray";
  firstButtonPaddingNone?: boolean;
  secondButtonPaddingNone?: boolean;
}

export default function ToggleButtons({
  buttons,
  selected,
  type,
  firstButtonPaddingNone,
  secondButtonPaddingNone,
}: Props) {
  return (
    <div className="toggleButtons">
      <div
        className={`toggleButtons-first ${
          firstButtonPaddingNone ? "paddingNone" : ""
        } ${
          selected === buttons.first.id
            ? "toggleButtons-selected"
            : type === "gray"
            ? "toggleButtons-notSelected-gray"
            : "toggleButtons-notSelected"
        }`}
        onClick={() => buttons.first.onSelect()}
      >
        <img
          src={
            selected === buttons.first.id
              ? buttons.first.imageSelected
              : buttons.first.image
          }
          alt=""
        />
        <p>{buttons.first.name}</p>
      </div>
      <div
        className={`toggleButtons-second ${
          secondButtonPaddingNone ? "paddingNone" : ""
        } ${
          selected === buttons.second.id
            ? "toggleButtons-selected"
            : type === "gray"
            ? "toggleButtons-notSelected-gray"
            : "toggleButtons-notSelected"
        }`}
        onClick={() => buttons.second.onSelect()}
      >
        <img
          src={
            selected === buttons.second.id
              ? buttons.second.imageSelected
              : buttons.second.image
          }
          alt=""
        />
        <p>{buttons.second.name}</p>
      </div>
    </div>
  );
}
