import styled from "@emotion/styled";
import { Tabs, Tab } from "@mui/material";

interface StyledTabsProps {
  children?: React.ReactNode;
  value?: number;
  onChange: (event: React.SyntheticEvent, newValue: number) => void;
}

export const StyledOrderChoiceTabs = styled((props: StyledTabsProps) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  minHeight: "unset",
  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
  },
});

interface StyledTabProps {
  label: string;
  isLast: boolean;
}
export const StyledOrderChoiceTab = styled((props: StyledTabProps) => (
  <Tab disableRipple {...props} />
))((props) => ({
  textTransform: "none",
  minWidth: 0,
  marginRight: !props.isLast ? "8px" : "0",
  color: "#667085",
  padding: "8px 12px !important",
  minHeight: "unset",
  height: "36px",
  fontFamily: [
    "-apple-system",
    "BlinkMacSystemFont",
    '"Segoe UI"',
    "Roboto",
    '"Helvetica Neue"',
    "Arial",
    "sans-serif",
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(","),
  "&:hover": {
    color: "#3538CD",
    backgroundColor: '#F9FAFB',
    opacity: 1,
  },
  "&.Mui-selected": {
    color: "#3538CD",
    backgroundColor: '#F9FAFB',
    fontWeight: "600",
  },
  "&.Mui-focusVisible": {
    backgroundColor: "none",
  },
  "&.MuiButtonBase-root": {
    fontSize: "0.875rem !important",
    lineHeight: "1.25rem !important",
    fontStyle: "normal !important",
    fontWeight: "600",
    letterSpacing: "0",
  },
}));
