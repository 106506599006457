import React from "react";
import homeLineIcon from "../../assets/images/home-line.svg";
import homeLineIconSelected from "../../assets/images/home-line-white.svg";
import flagSelectedIcon from "../../assets/images/flag-02.svg";
import flagIcon from "../../assets/images/flag-02-black.svg";
import usersIcon from "../../assets/images/users-02.svg";
import usersIconSelected from "../../assets/images/users-01-white.svg";
import { useNavigate } from "react-router-dom";

export default function BottomNavigation({
  selected,
}: {
  selected: "home" | "events" | "contacts" | "none";
}) {
  const navigate = useNavigate();

  return (
    <div className="bottomNavigation">
      <div
        onClick={() => navigate("/")}
        className={`${
          selected === "home"
            ? "bottomNavigation-item bottomNavigation-item-selected"
            : "bottomNavigation-item"
        }`}
      >
        <img
          src={selected === "home" ? homeLineIconSelected : homeLineIcon}
          alt=""
        />
        <p>Home</p>
      </div>
      <div
        onClick={() => navigate("/events?filter=organizer")}
        className={`${
          selected === "events"
            ? "bottomNavigation-item bottomNavigation-item-selected"
            : "bottomNavigation-item"
        }`}
      >
        <img src={selected === "events" ? flagSelectedIcon : flagIcon} alt="" />
        <p>Events</p>
      </div>
      <div
        onClick={() => navigate("/contacts")}
        className={`${
          selected === "contacts"
            ? "bottomNavigation-item bottomNavigation-item-selected"
            : "bottomNavigation-item"
        }`}
      >
        <img
          src={selected === "contacts" ? usersIconSelected : usersIcon}
          alt=""
        />
        <p>Contacts</p>
      </div>
    </div>
  );
}
