import { createContext, useState } from "react";

export const NotificationValue = createContext<{
  notification: {
    title: string,
    description: string,
    isError?: boolean,
  } | null,
  setNotification: React.Dispatch<React.SetStateAction<{
    title: string,
    description: string,
    isError?: boolean,
  } | null>>
}>({
  notification: null,
  setNotification: () => {},
});

export function NotificationContext({ children }: React.PropsWithChildren) {
  const [notification, setNotification] = useState<{
    title: string,
    description: string,
    isError?: boolean,
  } | null>(null)

  return <NotificationValue.Provider value={
    {
      notification,
      setNotification,
    }
  }>
    {children}
  </NotificationValue.Provider>
}