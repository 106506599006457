import React from "react";
import { TodoItem, TodoItemType } from "../../types/todoItem";
import { useNavigate } from "react-router-dom";
import alertIcon from "../../assets/images/alert-circle.svg";
import { AttendeeRSVP } from "../../types/attendeeRSVP";
import moment from "moment";

interface Props {
  toDo: TodoItem;
  name: string;
  description: string;
  onTodoClick?: (item?: AttendeeRSVP) => Promise<void>;
}

export default function Todo({ toDo, name, description, onTodoClick }: Props) {
  const navigate = useNavigate();

  return (
    <div
      className="eventDetailsWelcomePage-thingsTodo-todo flex-column items-start gap-8"
      onClick={() =>
        onTodoClick ? onTodoClick() : navigate(`events/${toDo.event.slug}`)
      }
      style={{ cursor: "pointer" }}
    >
      <div className="agendaItem-info-title">
        <img src={alertIcon} alt="" />
        <div>
          <p className="todoCard-description text-xs font-semibold">
            {description}
          </p>
        </div>
      </div>
      {toDo.dueDate ? (
        <div className="eventDetailsWelcomePage-thingsTodo-todo-message">
          <div className="eventDetailsWelcomePage-thingsTodo-todo-circle"></div>
          <p>{toDo.type === TodoItemType.RespondToInvite ? 'Respond' : 'Order'} by {moment(toDo?.dueDate).format("ll")}</p>
        </div>
      ) : null}
      {/* <img src={arrowRight} alt="" style={{ cursor: 'pointer' }} /> */}
    </div>
  );
}
