import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { Button } from "../../components/Buttons/Button";
import HorizontalTabs from "../../components/Tabs/HorizontalTabs";
import { useWindowSize } from "@uidotdev/usehooks";
import SideBarNavigationNew from "../../components/Navigation/SideBarNavigationNew";
import DatePicker from "../../components/DatePickers/Date";
import EventReviewBlockV2 from "../../components/EventBlock/EventReviewBlockV2";
import { useQuery } from "@tanstack/react-query";
import { fetchEvents } from "../../httpQueries/http";
import moment from "moment";
import { useNavigate, useSearchParams } from "react-router-dom";
import Loader from "../../components/Loader";
import EventsCalendarPage from "./EventsCalendarPage";
import NoEventsFound from "./NoEventsFound";
import EmptyPage from "./EmptyPage";
import { useSelector } from "../../store/hooks";
import { recentlyCreatedEventSelector } from "../../store/recentlyCreatedEvent";
import ModalEventWasCreated from "../../components/Modal/ModalEventWasCreated";
import { supabase } from "../../utilities/supabaseClient";
import { EventsFilter } from "../../types/eventsFilter";
import { currentUserSelector } from "../../store/currentUser";
import { DropdownMenu } from "../../components/Dropdown/DropdownMenu";
import chevronDown from "../../assets/images/chevron-down.svg";
import BottomNavigation from "../../components/Navigation/BottomNavigation";
import ToggleButtons from "../../components/ToggleButtons";
import listWhiteIcon from "../../assets/images/list-selected.svg";
import listBlackIcon from "../../assets/images/list-black.svg";
import calendarBlackIcon from "../../assets/images/calendar.svg";
import calendarWhiteIcon from "../../assets/images/calendar-tabIcon-selected.svg";
import { NotificationValue } from "../../utilities/NotificationContext";
import Notification from "../../components/Notifications/Notification";
import { getEventTime } from "../../utilities/getEventStartTime";
import { FormInput } from "../../components/Inputs/FormInput";
import { Box, Tab, Tabs } from "@mui/material";
import { styled } from '@mui/material/styles';

interface StyledTabsProps {
  children?: React.ReactNode;
  value?: number;
  onChange: (event: React.SyntheticEvent, newValue: number) => void;
}

const StyledTabs = styled((props: StyledTabsProps) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  minHeight: 'unset',
  '& .MuiTabs-indicator': {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
  },
  '& .MuiTabs-indicatorSpan': {
    width: '100%',
    backgroundColor: '#3538CD',
  },
});

interface StyledTabProps {
  label: string;
  isLast: boolean
}
const StyledTab = styled((props: StyledTabProps) => <Tab disableRipple {...props} />)(
  (props) => ({
    textTransform: 'none',
    minWidth: 0,
    marginRight: !props.isLast ? '16px' : '0',
    color: '#667085',
    padding: '1px 4px 11px !important',
    minHeight: 'unset',
    height: '48px',
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:hover': {
      color: '#3538CD',
      opacity: 1,
    },
    '&.Mui-selected': {
      color: '#3538CD',
      fontWeight: '600',
    },
    '&.Mui-focusVisible': {
      backgroundColor: 'none',
    },
    '&.MuiButtonBase-root': {
      fontSize: '0.875rem !important', 
      lineHeight: '1.25rem !important', 
      fontStyle: 'normal !important', 
      fontWeight: '600',
      letterSpacing: '0',
    }
  }),
);

export default function EventsPageV2() {
  const [queryParams, setQueryParams] = useSearchParams();
  const size = useWindowSize();
  const [sizeIsDesktop, setSizeIsDesktop] = useState(true);
  const [currentTab, setCurrentTab] = useState<{
    id: number;
    name: string;
  } | null>({ id: 0, name: "Active" });
  const [currentSecondTab, setCurrentSecondTab] = useState<{
    id: number;
    name: string;
  } | null>(null);
  const [eventDate, setEventDate] = useState<moment.Moment | null>(null);
  const [eventSecondDate, setEventSecondDate] = useState<moment.Moment | null>(
    null
  );
  const [showDatePicker, setShowDatePicker] = useState(false);
  const startDay = queryParams.get("startDay") || new Date().getDate();
  const startMonth = queryParams.get("startMonth") || new Date().getMonth();
  const startYear = queryParams.get("startYear") || new Date().getFullYear();
  const endDay = queryParams.get("endDay") || new Date().getDate();
  const endMonth = queryParams.get("endMonth") || new Date().getMonth();
  const endYear = queryParams.get("endYear") || new Date().getFullYear();
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth();
  const currentDay = new Date().getDate();
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const recentlyCreatedEvent = useSelector(recentlyCreatedEventSelector);
  const [isEventCreatedModalOpen, setIsEventCreatedModalOpen] = useState(
    !!recentlyCreatedEvent.name.length
  );
  const currentUser = useSelector(currentUserSelector);
  const filter = queryParams.get("filter");
  const [contentIsScrolled, setContentIsScrolled] = useState(false);
  const [selectTimePeriodDropdownIsOpen, setSelectTimePeriodDropdownIsOpen] =
    useState(false);
  const [selectedView, setSelectedView] = useState<1 | 2>(1);
  const { notification, setNotification } = useContext(NotificationValue);
  const [searchValue, setSearchValue] = useState("");
  const [updateSearch, setUpdateSearch] = useState("");
  const pageHasBeenRendered = useRef(false);
  let timer = setTimeout(() => {}, 300);
  const tabs = useMemo(() => {
    if (filter === EventsFilter.Organizer) {
      return [
        { id: 0, name: "Active" },
        { id: 1, name: "Past" },
        { id: 2, name: "Draft" },
      ] 
    } else {
      return [
        { id: 0, name: "Active" },
        { id: 1, name: "Past" },
      ]
    }
  }, [])

  const { data: events, fetchStatus } = useQuery({
    queryKey: [
      "events",
      token,
      endYear,
      endMonth,
      endDay,
      startDay,
      startYear,
      startMonth,
      queryParams.get("filter"),
      currentTab,
      updateSearch
    ],
    queryFn: () =>
      fetchEvents({
        start: new Date(
          +startYear,
          +startMonth,
          +startDay,
          0,
          0,
          0,
          0
        ).valueOf(),
        end: new Date(+endYear, +endMonth, +endDay, 23, 59, 59, 999).valueOf(),
        token,
        eventFilter:
          queryParams.get("filter") === EventsFilter.Attendee
            ? EventsFilter.Attendee
            : EventsFilter.Organizer || EventsFilter.All,
        isPublished: currentTab?.id === 0 || currentTab?.id === 1,
        isFinished: currentTab?.name === "Past",
        isActive: currentTab?.name === "Active",
        searchQuery: updateSearch,
      }),
  });

  const setDateAndTimeInfoToParams = () => {
    const currentDateTab = queryParams.get("current");
    switch (currentDateTab) {
      case "custom":
        setCurrentSecondTab(null);
        break;
      case "12 months":
        setCurrentSecondTab({ id: 1, name: "12 months" });
        break;
      case "30 days":
        setCurrentSecondTab({ id: 2, name: "30 days" });
        break;
      case "7 days":
        setCurrentSecondTab({ id: 3, name: "7 days" });
        break;
      case "24 hours":
        setCurrentSecondTab({ id: 4, name: "24 hours" });
        break;
      case null:
        setCurrentSecondTab({ id: 2, name: "30 days" });
        setQueryParams({
          filter: filter!,
          startDay: `${currentDay}`,
          startMonth: `${currentMonth}`,
          startYear: `${currentYear}`,
          endYear: `${currentYear}`,
          endMonth: `${currentMonth + 1}`,
          endDay: `${currentDay}`,
          current: "30 days",
        });
        break;
    }
  };

  const tabProps = useCallback((index: number) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }, []);

  useEffect(() => {
    localStorage.removeItem("currentEditEvent");
    setDateAndTimeInfoToParams();
    pageHasBeenRendered.current = true;
  }, []);

  useEffect(() => {
    setDateAndTimeInfoToParams();
  }, [filter]);

  useEffect(() => {
    setSizeIsDesktop(size.width! >= 768);
  }, [size.width]);

  useEffect(() => {
    if (pageHasBeenRendered.current) {
      clearTimeout(timer);

      timer = setTimeout(async () => {
        setUpdateSearch(searchValue)
      }, 400);

      return () => clearTimeout(timer);
    }
  }, [searchValue]);

  if (!token?.length) {
    supabase().auth.signOut();
    localStorage.clear();
    localStorage.clear();

    navigate("/login");

    return null;
  }

  return (
    <div className="fullHeight">
      {notification ? (
        <Notification
          isError={!!notification.isError}
          setIsOpen={() => {
            setNotification(null);
          }}
          title={notification.title}
          description={notification.description}
        />
      ) : null}
      <div
        className="eventDetails_main-footer eventsPage-info eventDetails_main-footer-eventsPage"
        style={{ paddingBottom: "0" }}
      >
        <div className="eventsPage-header ">
          <div className="eventsPage-header-info">
            <h3
              className={`eventsPage-header-title ${
                !sizeIsDesktop ? "text-xl" : "xs"
              }`}
            >
              Events{" "}
              {queryParams.get("filter") === EventsFilter.Attendee
                ? "I'm Attending"
                : "I'm Organizing"}
            </h3>
            <Box sx={{ borderBottom: 1, borderColor: 'divider', width: 'fit-content' }}>
              <StyledTabs 
                value={currentTab?.id} 
                onChange={(event: React.SyntheticEvent, newValue: number) => {
                  const currentTab = tabs.find(item => item.id === newValue);

                  if (currentTab) {
                    setCurrentTab(currentTab);
                  }
                }} 
                aria-label="tabs"
              >
                {
                  tabs.map((tab, index) => <StyledTab
                    label={tab.name} 
                    isLast={index + 1 === tabs.length}
                    {...tabProps(tab.id)} 
                  />)
                }
              </StyledTabs>
            </Box>
          </div>
          {sizeIsDesktop ? (
            <div style={{ height: "fit-content" }}>
              <Button
                buttonType="regular"
                hierarchy="newDesign-primary"
                newDesignPaddingNone
                size="md"
                onClick={() => navigate("/createEvent")}
              >
                Create event
              </Button>
            </div>
          ) : null}
        </div>
      </div>
      <div
        className={`eventDetails_container ${
          !sizeIsDesktop ? "flex-column" : ""
        }`}
      >
        <SideBarNavigationNew
          hierarchy="primary"
          currentItem={
            queryParams.get("filter") === "attendee"
              ? "EventsAttendee"
              : "EventsOrganizer"
          }
        />
        {isEventCreatedModalOpen ? (
          <ModalEventWasCreated setIsOpen={setIsEventCreatedModalOpen} />
        ) : null}
        <div
          className={`eventDetails_main-container ${
            !sizeIsDesktop ? "fullWidth" : ""
          } eventDetails_main-container-withoutBorder eventDetails_main-container-reviewPage`}
        >
          <div className="eventDetails_main eventDetails_main-bgTransparent eventDetails_main-reviewPage">
            {currentUser.completeTutorial || events?.length ? (
              <>
                <div
                  className="eventDetails_content flex flex-column gap-20 eventsPage-content"
                  id="mobileCalendar"
                  onScroll={() =>
                    setContentIsScrolled(
                      (document.getElementById("mobileCalendar") as HTMLElement)
                        .scrollTop >= 32
                    )
                  }
                >
                  <div className="flex spaceBetween items-center positionRelative">
                    <div className="flex items-center gap-16">
                      <FormInput
                        type="SearchGray"
                        label="Search events"
                        hasIconBefore
                        design="new"
                        value={searchValue}
                        fullWidth
                        onChange={(e) => setSearchValue(e.target.value)}
                        height="sm"
                      />
                      <div>
                        <Button
                          hierarchy="newDesign-secondary"
                          buttonType="regular"
                          newDesignPaddingNone
                          size="md"
                          onClick={() =>
                            setSelectTimePeriodDropdownIsOpen(
                              (prevState) => !prevState
                            )
                          }
                        >
                          <div className="flex items-center gap-10">
                            <p className="color-gray-900">
                              {currentSecondTab?.id
                                ? currentSecondTab.id === 5
                                  ? `${
                                      !eventDate
                                        ? "Custom date"
                                        : `${eventDate?.format("ll")}
                                      ${eventSecondDate ? " -" : ""} 
                                      ${
                                        eventSecondDate
                                          ? eventSecondDate?.format("ll")
                                          : ""
                                      }`
                                    }`
                                  : currentSecondTab?.name
                                : "Select time"}
                            </p>
                            <img src={chevronDown} alt="" />
                          </div>
                        </Button>
                        {showDatePicker ? (
                          <div className="flex items-center gap-12">
                            <DatePicker
                              firstDate={eventDate}
                              secondDate={eventSecondDate}
                              setSecondDate={setEventSecondDate}
                              isOpen={showDatePicker}
                              placeholder="Select dates"
                              setFirstDate={setEventDate}
                              setIsOpen={setShowDatePicker}
                              type="double"
                              selectedDayOnlyFromToday={false}
                              selectSecondDateFrom={eventDate}
                              setShowOtherPickers={() => {}}
                              left={"0"}
                              top={"17px"}
                              withoutButton
                              onApply={() => {
                                const date = eventDate?.date();
                                const month = eventDate?.month();
                                const year = eventDate?.year();
                                const dateSecond = eventSecondDate?.date();
                                const monthSecond = eventSecondDate?.month();
                                const yearSecond = eventSecondDate?.year();

                                if (
                                  date &&
                                  month !== undefined &&
                                  year &&
                                  dateSecond &&
                                  monthSecond !== undefined &&
                                  yearSecond
                                ) {
                                  setQueryParams({
                                    filter: filter!,
                                    startDay: `${date}`,
                                    startMonth: `${month}`,
                                    startYear: `${year}`,
                                    endYear: `${yearSecond}`,
                                    endMonth: `${monthSecond}`,
                                    endDay: `${dateSecond}`,
                                    current: "custom",
                                  });
                                }
                              }}
                              paddingNone
                            />
                          </div>
                        ) : null}
                      </div>
                    </div>
                    {selectTimePeriodDropdownIsOpen ? (
                      <DropdownMenu
                        positionTop={"50px"}
                        selectedItem={currentSecondTab}
                        setSelectedItem={setCurrentSecondTab}
                        content={[
                          {
                            id: 1,
                            name: "12 months",
                            onSelect: () => {
                              setEventDate(null);
                              setEventSecondDate(null);

                              setQueryParams({
                                filter: filter!,
                                startDay: `${currentDay}`,
                                startMonth: `${currentMonth}`,
                                startYear: `${currentYear}`,
                                endYear: `${currentYear + 1}`,
                                endMonth: `${currentMonth}`,
                                endDay: `${currentDay}`,
                                current: "12 months",
                              });
                            },
                          },
                          {
                            id: 2,
                            name: "30 days",
                            onSelect: () => {
                              setEventDate(null);
                              setEventSecondDate(null);

                              setQueryParams({
                                filter: filter!,
                                startDay: `${currentDay}`,
                                startMonth: `${currentMonth}`,
                                startYear: `${currentYear}`,
                                endYear: `${currentYear}`,
                                endMonth: `${currentMonth + 1}`,
                                endDay: `${currentDay}`,
                                current: "30 days",
                              });
                            },
                          },
                          {
                            id: 3,
                            name: "7 days",
                            onSelect: () => {
                              setEventDate(null);
                              setEventSecondDate(null);

                              setQueryParams({
                                filter: filter!,
                                startDay: `${currentDay}`,
                                startMonth: `${currentMonth}`,
                                startYear: `${currentYear}`,
                                endYear: `${currentYear}`,
                                endMonth: `${currentMonth}`,
                                endDay: `${currentDay + 7}`,
                                current: "7 days",
                              });
                            },
                          },
                          {
                            id: 4,
                            name: "24 hours",
                            onSelect: () => {
                              setEventDate(null);
                              setEventSecondDate(null);

                              setQueryParams({
                                filter: filter!,
                                startDay: `${currentDay}`,
                                startMonth: `${currentMonth}`,
                                startYear: `${currentYear}`,
                                endYear: `${currentYear}`,
                                endMonth: `${currentMonth}`,
                                endDay: `${currentDay}`,
                                current: "24 hours",
                              });
                            },
                          },
                          {
                            id: 5,
                            name: `${
                              !eventDate
                                ? "Custom date"
                                : `${eventDate?.format("ll")}
                                        ${eventSecondDate ? " -" : ""} 
                                        ${
                                          eventSecondDate
                                            ? eventSecondDate?.format("ll")
                                            : ""
                                        }`
                            }`,
                            onSelect: () => {
                              setShowDatePicker(true);
                            },
                          },
                        ]}
                        setShow={() =>
                          setSelectTimePeriodDropdownIsOpen(
                            (prevState) => !prevState
                          )
                        }
                        size={"max-content"}
                      />
                    ) : null}
                    <div className="eventsPage-toggle">
                      <ToggleButtons
                        type={sizeIsDesktop ? "default" : "gray"}
                        buttons={{
                          first: {
                            id: 1,
                            name: "List",
                            onSelect: () => setSelectedView(1),
                            image: listBlackIcon,
                            imageSelected: listWhiteIcon,
                          },
                          second: {
                            id: 2,
                            name: "Calendar",
                            onSelect: () => {
                              setSelectedView(2);
                            },
                            image: calendarBlackIcon,
                            imageSelected: calendarWhiteIcon,
                          },
                        }}
                        selected={selectedView}
                      />
                    </div>
                  </div>
                  {selectedView === 1 ? (
                    <>
                      {fetchStatus === "fetching" ? (
                        <div className="flex justifyCenter items-center fullWidth fullHeight">
                          <Loader size="xl" />
                        </div>
                      ) : events && events.length > 0 ? (
                        events.map((item) => {
                          return (
                            <EventReviewBlockV2
                              slug={item.slug}
                              description={item.description}
                              timeDifference={item.totalDuration}
                              color={
                                +new Date(item.startTime).valueOf() <
                                +new Date().valueOf()
                                  ? "pastEvent"
                                  : item.color
                              }
                              pattern={item.pattern}
                              key={item.slug}
                              onClick={() => navigate(`${item.slug}`)}
                              startTime={
                                getEventTime(item!.startTime, item.timezoneName)
                                  ?.name || ""
                              }
                              endTime={
                                item.endTime
                                  ? getEventTime(
                                      item!.endTime,
                                      item.timezoneName
                                    )?.name
                                  : undefined
                              }
                              name={item.name}
                              date={moment(item.startDate).format("ll")}
                              location={item.location}
                            />
                          );
                        })
                      ) : (
                        <NoEventsFound />
                      )}
                    </>
                  ) : (
                    <EventsCalendarPage
                      filter={
                        queryParams.get("filter") === EventsFilter.Attendee
                          ? EventsFilter.Attendee
                          : EventsFilter.Organizer || EventsFilter.All
                      }
                      searchValue={updateSearch}
                      sizeIsDesktop={sizeIsDesktop}
                      contentIsScrolled={contentIsScrolled}
                    />
                  )}
                </div>
              </>
            ) : fetchStatus === "fetching" ? (
              <div className="flex justifyCenter items-center fullWidth fullHeight">
                <Loader size="xl" />
              </div>
            ) : (
              <EmptyPage
                isOrganizer={
                  queryParams.get("filter") === EventsFilter.Organizer
                }
              />
            )}
          </div>
        </div>
        <BottomNavigation selected="events" />
      </div>
    </div>
  );
}
