import React from "react";
import loader from "../assets/images/loader.json";
import { useLottie } from "lottie-react";

interface Props {
  size: "sm" | "md" | "lg" | "xl";
  scale?: string;
}

export default function ({ size, scale }: Props) {
  const options = {
    animationData: loader,
    loop: true,
    style: {
      width: "100%",
      height: "100%",
      scale: scale,
    },
  };

  const { View } = useLottie(options);

  return (
    <div
      className={`
      loader
      ${size === "sm" ? "loader-sm" : ""} 
      ${size === "md" ? "loader-md" : ""}
      ${size === "lg" ? "loader-lg" : ""}
    `}
    >
      {View}
    </div>
  );
}
