import { createContext, useState } from "react";

type RSVPEventType = {
  eventSlug: string,
  setEventSlug: React.Dispatch<React.SetStateAction<string>>
}

export const RSVPEventValue = createContext<RSVPEventType>({
  eventSlug: '',
  setEventSlug: () => {},
});

export function RSVPEventValueContext({ children }: React.PropsWithChildren) {
  const [eventSlug, setEventSlug] = useState('');

  return <RSVPEventValue.Provider value={
    {
      eventSlug,
      setEventSlug,
    }
  }>
    {children}
  </RSVPEventValue.Provider>
}