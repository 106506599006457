import React from "react";
import closeIcon from "../../assets/images/x-close-gray-lg.svg";
import { Button } from "../Buttons/Button";
import { useNavigate } from "react-router-dom";

interface Props {
  setShow: () => void;
  slug: string;
}


export default function NotLogginedUser({ setShow, slug }: Props) {
  const navigate = useNavigate();

  return (
    <div className="modalNotification" style={{ zIndex: "10002" }}>
      <div
        className="modalNotification-container modalNotification-container-selectMealsCustomization"
        style={{ maxHeight: "90%" }}
      >
        <div className="modalNotification-header gap-8">
          <div className="modalNotification-header-actions">
            <h3>Need an account to RSVP</h3>
            <img
              src={closeIcon}
              alt="close icon"
              className="modalNotification-header-actions-close"
              onClick={setShow}
            />
          </div>
        </div>
        <div className="modalNotification-header-info modalNotification-main">
          <p>You need to create an account in order to RSVP.</p>
        </div>
        <div className="modalNotification-main-actions flex spaceBetween items-center">
          <Button size={"md"} hierarchy={'linkColor'} buttonType={"regular"} onClick={setShow}>
            Cancel
          </Button>
          <Button size={"md"} newDesignPaddingNone hierarchy={'newDesign-primary'} buttonType={"regular"} onClick={() => navigate(`/login/rsvp?slug=${slug}`)}>
            Create account
          </Button>
        </div>
      </div>
    </div>
  );
}
