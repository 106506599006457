import React, { useEffect, useState } from "react";
import closeIcon from "../../assets/images/x-close-gray-lg.svg";
import { Button } from "../Buttons/Button";
import { FormInput } from "../Inputs/FormInput";
import { DropdownMenu } from "../Dropdown/DropdownMenu";
import { fetchLocations, postNewRestaurant } from "../../httpQueries/http";
import pinIcon from "../../assets/images/marker-pin-01.svg";

interface Props {
  setShow: () => void;
  //   isDesktopSize: boolean
}

export default function AddNewRestaurantModal({
  setShow,
}: //   isDesktopSize
Props) {
  const [newRestaurantName, setNewRestaurantName] = useState("");
  const [restaurantLink, setRestaurantLink] = useState("");
  const [showSelectLocation, setShowSelectLocation] = useState(false);
  const token = localStorage.getItem("token");
  const [inputLocationValue, setInputLocationValue] = useState("");
  const [selectedLocation, setSelectedLocation] = useState<{
    id: number;
    name: string;
    supportText?: string | undefined;
    lng?: string;
    lat?: string;
  } | null>(null);
  const [locations, setLocations] = useState<
    { name: string; lng: number; lat: number }[] | undefined
  >([]);
  let timer = setTimeout(() => {}, 300);

  useEffect(() => {
    window.scrollTo(0, 0);

    document.getElementById("modal")?.classList.add("modal-main-container");
    document.body.style.overflow = "hidden";

    return () => {
      document.body.style.overflow = "auto";
      document
        .getElementById("modal")
        ?.classList.remove("modal-main-container");
    };
  }, []);

  useEffect(() => {
    clearTimeout(timer);

    timer = setTimeout(async () => {
      const response = await fetchLocations({
        search: inputLocationValue,
        token,
      });
      setLocations(response);
    }, 400);

    return () => clearTimeout(timer);
  }, [inputLocationValue]);

  const onAddNewRestaurant = () => {
    if (
      selectedLocation?.name &&
      selectedLocation.lat &&
      selectedLocation.lng &&
      restaurantLink.length &&
      newRestaurantName.length
    ) {
      postNewRestaurant({
        token,
        body: {
          location: selectedLocation.name,
          locationLatitude: `${selectedLocation.lat}`,
          locationLongitude: `${selectedLocation.lng}`,
          url: restaurantLink,
          name: newRestaurantName,
        },
      }).then(() => setShow());
    }
  };

  return (
    <div className="modalNotification z10001">
      <div
        className="modalNotification-container overflowVisible"
        style={{ maxWidth: "640px" }}
      >
        <div className="modalNotification-header modalNotification-header-error">
          <div className="modalNotification-header-actions">
            <div className="modalNotification-header-info">
              <h3>Add new restaurant</h3>
              <p>Help us grow our community, add the restaurant and any information you would like to add about it, you will have to make sure to do the food order yourself until we add the restaurant to our system.</p>
            </div>
            <img
              src={closeIcon}
              alt="close icon"
              className="modalNotification-header-actions-close"
              onClick={setShow}
            />
          </div>
        </div>
        <div
          className="modalNotification-main overflowVisible"
          style={{ marginBottom: "0" }}
        >
          <div className="modalNotification-main">
            <FormInput
              label={
                newRestaurantName.length
                  ? "Restaurant name*"
                  : "Restaurant name"
              }
              type="Text"
              onChange={(
                event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
              ) => setNewRestaurantName(event.target.value)}
              value={newRestaurantName}
              design="new"
            />
            <div>
              {!showSelectLocation && selectedLocation ? (
                <div
                  className="formNew"
                  onClick={() => setShowSelectLocation(true)}
                >
                  <h3 className="eventDetails_content-location-title">
                    Location*
                  </h3>
                  <div className="inputNew inputNew-filled justifyStart overflowXScroll">
                    <img src={pinIcon} alt="" />
                    <p className="eventDetails_content-location-main whiteSpaceNoWrap">
                      {selectedLocation?.name}
                    </p>
                    <p className="eventDetails_content-location-support whiteSpaceNoWrap">
                      {selectedLocation?.supportText}
                    </p>
                  </div>
                </div>
              ) : (
                <FormInput
                  hasIconBefore={pinIcon}
                  label={
                    selectedLocation?.name
                      ? `${selectedLocation?.name}, ${selectedLocation?.supportText}`
                      : "Address"
                  }
                  design="new"
                  type="SearchGray"
                  onChange={(
                    event: React.ChangeEvent<
                      HTMLInputElement | HTMLTextAreaElement
                    >
                  ) => setInputLocationValue(event.target.value)}
                  value={inputLocationValue}
                  setShow={() => setShowSelectLocation(false)}
                  onFocus={() => {
                    setShowSelectLocation(true);
                  }}
                />
              )}

              {showSelectLocation && inputLocationValue.length && locations ? (
                <div>
                  <DropdownMenu
                    content={locations.map((location) => ({
                      name: location.name.slice(
                        0,
                        location.name.indexOf(",") + 1
                      ),
                      supportText: location.name.slice(
                        location.name.indexOf(",") + 1
                      ),
                      id: `${location.lat}${location.lng}`,
                      lat: location.lat,
                      lng: location.lng,
                    }))}
                    selectedItem={selectedLocation}
                    setSelectedItem={setSelectedLocation}
                    setShow={() => {
                      setShowSelectLocation(false);
                    }}
                    withSupportText
                    size="lg"
                    heightSize="xs"
                  />
                </div>
              ) : null}
            </div>
            {/* {showSelectLocation && !isDesktopSize
            ? createPortal(<LocationSearch
                selectedLocation={selectedLocation}
                setSelectedLocation={setSelectedLocation}
                setShowSelectLocation={setShowSelectLocation}
                locations={locations?.map(location => ({name: location.name.slice(0, location.name.indexOf(',') + 1), supportText: location.name.slice(location.name.indexOf(',') + 1), id: `${location.lat}${location.lng}` }))}
                inputValue={inputLocationValue}
                setInputValue={setInputLocationValue}
              />, document.getElementById('modal') as HTMLElement)
            : null
          } */}
            <FormInput
              label={"Menu link"}
              type="Text"
              onChange={(
                event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
              ) => setRestaurantLink(event.target.value)}
              value={restaurantLink}
              fullWidth
              design="new"
            />
          </div>
          <div
            className="modalNotification-actions modalNotification-actions-flex-end"
            style={{ marginLeft: "auto" }}
          >
            <Button
              buttonType="regular"
              hierarchy="primary"
              size="lg"
              contentCenter
              // fullWidth={!isDesktopSize}
              onClick={onAddNewRestaurant}
              disabled={
                !(
                  newRestaurantName.length &&
                  selectedLocation &&
                  restaurantLink.length
                )
              }
            >
              Add restaurant
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
