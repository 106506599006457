import React, { useState } from "react";
import arrowDown from "../../assets/images/chevron-down.svg";
import { DropdownMenu } from "./DropdownMenu";

interface Props {
  creatorIsOrder: boolean | null;
  title: string;
  content: {
    id: number;
    name: string;
    organizerOrder: boolean
  }[],
  onSelect: (state: boolean) => void
}

export default function OrderSelectionDropdown({
  creatorIsOrder,
  title,
  content,
  onSelect
}: Props) {
  const [isOpen, setIsOpen] = useState(false);
  const currentSelectedItem = content.find(it => it.organizerOrder === creatorIsOrder);

  return (
    <div className={`countryDropdown formNew justifyCenter`}>
      {
        creatorIsOrder !== null
          ? <h3 className="countryDropdown-label">{title}</h3>
          : null
      }
      <div
        className={`inputNew-filled fullWidth flex spaceBetween items-center`}
        onClick={() => setIsOpen((prevState) => !prevState)}
      >
        <p>{creatorIsOrder === null ? title : creatorIsOrder ? 'Attendee selection' : 'Organizer selection'}</p>
        <img src={arrowDown} alt="" />
      </div>

      {isOpen ? (
        <DropdownMenu
          content={content}
          selectedItem={currentSelectedItem ? currentSelectedItem : null}
          setSelectedItem={() => {}}
          onSelect={onSelect}
          setShow={() => setIsOpen((prevState) => !prevState)}
          size="base"
          positionTop={"80px"}
          positionRight={"0"}
          height="100px"
          fullWidth
        />
      ) : null}
    </div>
  );
}
